import React, { useContext, useEffect } from 'react';
import CmsPageSection from './CmsPageSection';
import { CmsPageContext } from '../definitions';
import { useSpinner } from '../../spinner/hooks/useSpinner';
import GrowlContainer from '../../growl/components/GrowlContainer';
import useWrapPageActions from '../hooks/useWrapPageActions';
import CmsSubItemListSectionToolbar from './CmsSubItemListSectionToolbar';
import { useSubItemsLoader } from '../../cms/hooks/useSubItemsLoader';
import useLoadSubListItems from '../hooks/useLoadSubListItems';

const CmsSubItemPublishSection = (props) => {
  const {
    parentPageTitle,
    parentItemId,
    parentPageId,
    parentPageName,
    parentPageArgs,
    toolbarActions,
    subItemApi,
    className,
    pageConstants,
    emptyListMessage,
    selectActions,
  } = props;
  const { pluginId, defaultPageId, typeName } = pageConstants;
  const { spinnerProvider } = useContext(CmsPageContext);
  const { loadRecords } = subItemApi;
  const { loadItems, loading, records } = useSubItemsLoader(loadRecords);
  const reload = useLoadSubListItems(defaultPageId, parentItemId, loadItems);
  const { selectAll, selectedItems, handleSelectAll, handleSelect, setItems } = selectActions;
  useEffect(() => {
    setItems(records.filter((r) => r.status !== 'published').map((r) => r.id));
  }, [records, setItems]);
  useSpinner(spinnerProvider, loading);
  const wrappedToolbarActions = useWrapPageActions(defaultPageId, toolbarActions, reload);

  const handleSelectAllClick = () => {
    handleSelectAll();
  };

  return (
    <CmsPageSection className={className}>
      <div className="row">
        <CmsSubItemListSectionToolbar
          pluginId={pluginId}
          parentPageTitle={parentPageTitle}
          parentPageId={parentPageId}
          parentPageName={parentPageName}
          parentPageArgs={parentPageArgs}
          actions={wrappedToolbarActions}
        />
        <div>
          <b>
            Note: Changes to the {parentPageTitle} settings, sort order, and selected {typeName}s
            below will be published.
          </b>
        </div>
      </div>
      <GrowlContainer groupId={defaultPageId} />
      <div className="row">
        <table className="table table-striped">
          <thead>
            <tr>
              <th className="selector-cell">
                <input type="checkbox" onChange={handleSelectAllClick} checked={selectAll} />
              </th>
              <th>Select All</th>
            </tr>
          </thead>
          {records.length > 0 ? (
            <tbody>
              {records.map((item) => (
                <tr key={item.id}>
                  <td className="selector-cell">
                    <input
                      type="checkbox"
                      id={item.id}
                      name={item.id}
                      onChange={handleSelect}
                      checked={selectedItems.includes(item.id) && item.status !== 'published'}
                      disabled={item.status === 'published'}
                    />
                  </td>
                  <td
                    style={
                      item.status === 'published'
                        ? {
                            opacity: '80%',
                          }
                        : {
                            opacity: '100%',
                          }
                    }
                  >
                    {item.label} ({item.status}){' '}
                    {item.objectReference && item.objectReference !== 'cms://default' ? (
                      <></>
                    ) : (
                      <span
                        style={{
                          color: '#DBA800',
                        }}
                      >
                        *Warning: Item is linked to default obj
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td />
                <td className="table-message">{emptyListMessage}</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </CmsPageSection>
  );
};

export default CmsSubItemPublishSection;
