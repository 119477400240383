import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CmsContext } from '../../context/definitions';
import { getCmsSurveyUrl, updateSurveyOptions } from '../api/surveysApi';
import isFunction from 'lodash/isFunction';
export default function useSurveyActions(pageConstants) {
  const [forceRefresh, setForceRefresh] = useState(null);
  const [includeArchived, setIncludeArchived] = useState(false);
  const onFocus = useCallback(async () => {
    if (isFunction(forceRefresh)) {
      await forceRefresh();
    }
  }, [forceRefresh, setForceRefresh]);
  useEffect(() => {
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, [onFocus]);
  const {
    navigator,
    growlProvider: { success, error },
    confirm,
  } = useContext(CmsContext);
  const archiveCheckbox = useMemo(
    () => ({
      icon: 'fa-solid fa-pencil',
      label: includeArchived ? 'Hide Archived' : 'Show Archived',
      onClick: async () => void setIncludeArchived((current) => !current),
    }),
    [includeArchived, setIncludeArchived]
  );
  const { typeName, pluginId, defaultPageId, editorPageName } = pageConstants;
  const add = useMemo(
    () => ({
      label: `+ Add Form`,
      onClick: async ({ reload }) => {
        try {
          setForceRefresh(() => reload);
          const url = await getCmsSurveyUrl();
          window.open(url);
        } catch (e) {
          console.error(e);
          error(defaultPageId, 'Failed to open form creator.');
        }
      },
    }),
    [typeName, navigator, pluginId, defaultPageId, editorPageName, setForceRefresh]
  );
  const refresh = useMemo(
    () => ({
      label: ``,
      icon: 'fa solid fa-arrow-rotate-right',
      onClick: async ({ reload }) => {
        try {
          await reload();
        } catch (e) {
          console.error(e);
          error(defaultPageId, 'Failed to refresh.');
        }
      },
    }),
    [typeName, navigator, pluginId, defaultPageId, editorPageName]
  );
  const archive = useMemo(
    () => ({
      label: (item) => (item.isArchived ? 'Unarchive' : 'Archive'),
      icon: 'fa-solid fa-box-archive',
      onClick: async (context, { reload }) => {
        try {
          let confirmResponse = true;

          if (!context.isArchived) {
            confirmResponse = await confirm({
              title: 'Achive Survey',
              message: `Are you sure you want to archive ${context.title}`,
            });
          }

          if (confirmResponse) {
            await updateSurveyOptions(context.id, {
              isArchived: !context.isArchived,
            });
            await reload();
            success(defaultPageId, 'Successfully updated survey.');
          }
        } catch (e) {
          console.error(e);
          error(defaultPageId, 'Failed to update survey.');
        }
      },
    }),
    [typeName, navigator, pluginId, defaultPageId, editorPageName, confirm]
  );
  const edit = useMemo(
    () => ({
      icon: 'fa-solid fa-pencil',
      label: 'Edit',
      disabled: (context) => context.status === 'removed',
      onClick: async (context, { reload }) => {
        try {
          setForceRefresh(() => reload);
          const url = await getCmsSurveyUrl(context.id, false);
          window.open(url);
        } catch (e) {
          console.error(e);
          error(defaultPageId, 'Failed to open survey editor.');
        }
      },
    }),
    [navigator, pluginId, defaultPageId, editorPageName, setForceRefresh]
  );
  const manageInstances = useMemo(
    () => ({
      icon: 'fa-solid fa-list-tree',
      label: 'Manage Instances',
      disabled: (context) => context.status === 'removed',
      onClick: (context) => {
        navigator.navigateToPluginPage(pluginId, 'surveyInstancesPage', context.id);
      },
    }),
    [navigator, pluginId, defaultPageId, editorPageName]
  );
  const duplicate = useMemo(
    () => ({
      icon: 'fa-solid fa-clone',
      label: 'Duplicate',
      disabled: (context) => context.status === 'removed',
      onClick: async (context) => {
        try {
          const url = await getCmsSurveyUrl(context.id, true);
          window.open(url);
        } catch (e) {
          console.error(e);
          error(defaultPageId, 'Failed to open survey creator.');
        }
      },
    }),
    [navigator, pluginId, defaultPageId, editorPageName]
  );
  return {
    add,
    archive,
    archiveCheckbox,
    duplicate,
    edit,
    includeArchived,
    manageInstances,
    refresh,
  };
}
