import { useEffect, useState } from 'react';
import axios from 'axios';
import { getServiceUrls } from '../api/achievementsApi';

/*
type template = {
    requiredFields: Array<string>,
    label: string,
    value: string
}
*/
const postProcessing = {
  none: (raw) => raw,
  achievementTemplates: (raw) => {
    return raw.map((t) => ({
      requiredFields: t.fields,
      label: t.displayName,
      value: t.id,
    }));
  },
};

const useServiceData = (postProcessor = 'none') => {
  const [templates, setTemplates] = useState([]);
  const [fields, setFields] = useState([]);
  const [serviceUrls, setUrls] = useState({
    site: '',
    api: '',
  });
  const [requestPending, setRequestPending] = useState(true);
  const certSuffix = '/certificates';
  const fieldSuffix = '/fields';
  useEffect(() => {
    (async () => {
      const urls = await getServiceUrls();
      return setUrls(urls);
    })();
  }, [getServiceUrls]);
  useEffect(() => {
    if (serviceUrls.api) {
      const urlCert = `${serviceUrls.api}${certSuffix}`;
      const urlField = `${serviceUrls.api}${fieldSuffix}`;

      try {
        (async () => {
          const serviceData = await Promise.all([
            axios.get(urlCert, {
              timeout: 10000,
            }),
            axios.get(urlField, {
              timeout: 10000,
            }),
          ]);
          const [{ data: templatesRaw }, { data: fieldsRaw }] = serviceData;

          try {
            const processed = postProcessing[postProcessor](templatesRaw);
            const fields = fieldsRaw;
            setTemplates(processed);
            setFields(fields);
            setRequestPending(false);
          } catch (e) {
            console.error(e);
            throw new Error('Failed to process service data');
          }
        })();
      } catch (e) {
        console.error(e);
        throw new Error('Failed to retreive service data');
      }
    }
  }, [serviceUrls, postProcessor]);
  const options = {
    templates,
    fields,
  };
  return {
    options,
    requestPending,
  };
};

export default useServiceData;
