import moment from 'moment';
export function getPageState(pageId) {
  const itemKey = getPageStateKey(pageId);
  const jsonState = sessionStorage.getItem(itemKey);
  let result;

  if (jsonState) {
    result = JSON.parse(jsonState);
  }

  return result;
}
export function savePageState(pageId, pageState) {
  const itemKey = getPageStateKey(pageId);
  const jsonState = JSON.stringify(pageState);
  sessionStorage.setItem(itemKey, jsonState);
}
export function clearPageState(pageId) {
  const itemKey = getPageStateKey(pageId);
  sessionStorage.removeItem(itemKey);
}
export function getRestoreOnTransition(pageId) {
  const itemKey = getRestoreStateKey(pageId);
  const expiresString = sessionStorage.getItem(itemKey);
  let result = false;

  if (expiresString) {
    const expires = moment.unix(parseInt(expiresString));
    result = expires.isAfter(moment());
  }

  return result;
}
export function setRestoreOnTransition(pageId) {
  const itemKey = getRestoreStateKey(pageId);
  const expires = moment().millisecond(500);
  sessionStorage.setItem(itemKey, expires.format('x'));
}

function getPageStateKey(pageId) {
  return `page-state-${pageId}`;
}

function getRestoreStateKey(pageId) {
  return 'restore-state-${pageId}';
}
