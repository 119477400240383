import { EMPTY_COLLECTION_REVISION } from '../definitions';
import axios from 'axios';
const baseUrl = '/plugins/collections/api';

const loadRecords = async (params) => {
  const { sort, ...otherParams } = params;
  const { sortField, order } = sort;
  const sortReverse = sortField ? order === 'DESC' : undefined;
  let result;

  try {
    const { data } = await axios.get(`${baseUrl}/getCollections`, {
      params: { ...otherParams, sortField, sortReverse },
      timeout: 10000,
    });
    const { collections, count, offset } = data;
    result = {
      records: collections,
      numRecords: count,
      offset,
    };
  } catch (e) {
    console.error(e);
    throw new Error('Failed to load collections');
  }

  return result;
};

const loadRecord = async (id) => {
  if (id === 'new') {
    return EMPTY_COLLECTION_REVISION;
  } else {
    try {
      const { data } = await axios.get(`${baseUrl}/getCollection`, {
        params: {
          id,
        },
        timeout: 10000,
      });
      return data.collection;
    } catch (e) {
      console.error(e);
      throw new Error('Failed to load collection.');
    }
  }
};

const saveRecord = async (item) => {
  try {
    //eslint-disable-next-line
    const { id, ...revisionProps } = item;
    const { data } = await axios.post(
      `${baseUrl}/saveCollection`,
      { ...revisionProps, id: item.collection_id },
      {
        timeout: 10000,
      }
    );
    return data.collection;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to save collection.');
  }
};

const removeRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(
      `${baseUrl}/removeCollection`,
      {
        id,
      },
      {
        timeout: 10000,
      }
    );
  } catch (e) {
    console.error(e);
    throw new Error('Failed to remove collection.');
  }
};

const restoreRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(
      `${baseUrl}/restoreCollection`,
      {
        id,
      },
      {
        timeout: 10000,
      }
    );
  } catch (e) {
    console.error(e);
    throw new Error('Failed to restore collection.');
  }
};

const reorderRecords = async (parentId, itemIds) => {
  try {
    await axios.post(
      `${baseUrl}/reorderCollectionItems`,
      {
        itemIds,
        id: parentId,
      },
      {
        timeout: 10000,
      }
    );
  } catch (e) {
    console.error(e);
    throw new Error('Failed to reorder collection.');
  }
};

const publishRecord = async (id, itemIds = []) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/publishCollection`,
      {
        id,
        itemIds,
      },
      {
        timeout: 10000,
      }
    );
    return data.collection;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to publish collection.');
  }
};

const collectionApi = {
  loadRecords,
  loadRecord,
  saveRecord,
  removeRecord,
  restoreRecord,
  reorderRecords,
  publishRecord,
};
export const loadCategories = async (collectionId) => {
  try {
    const { data } = await axios.get(`${baseUrl}/getCategorySuggestions`, {
      params: {
        collectionId,
      },
      timeout: 10000,
    });
    return data.categories;
  } catch (e) {
    console.log('Failed to get categories');
  }
};
export default collectionApi;
