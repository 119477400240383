import { useCallback, useState } from 'react';
export const useEditPageTitle = (id, typeName, labelField) => {
  const [pageTitle, setPageTitle] = useState('');
  const onItemChange = useCallback(
    (item) => {
      let newTitle; // Showing edit page for new item

      if (!item?.id && id === 'new') {
        newTitle = `Add ${typeName}`;
      } else {
        // Showing edit page for existing item which may not yet have been loaded.
        const itemName = item && item[labelField];
        newTitle = itemName ? `Edit '${itemName}'` : `Edit ${typeName}`;
      }

      setPageTitle(newTitle);
    },
    [id, typeName, labelField, setPageTitle]
  );
  return {
    pageTitle,
    onItemChange,
  };
};
