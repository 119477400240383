import React from 'react';
import classNames from 'classnames';
import FormFieldError from './FormFieldError';
// import { validationIncludesRequired } from '../formUtils';

const FormInput = (props) => {
  const {
    fieldName,
    placeholder,
    formFieldOptions,
    formContext,
    fieldWidth,
    readOnly,
    type: providedType,
    className: providedClassName,
    ...inputAttributes
  } = props;
  const { formState, register } = formContext;
  const { errors } = formState;
  const fieldError = errors[fieldName];
  const inputWidth = fieldWidth || 'normal';
  // const isRequired = validationIncludesRequired(formFieldOptions);
  return (
    <div
      className={classNames('form-group', providedClassName, `input-${inputWidth}`, {
        'has-error': !!fieldError,
      })}
    >
      <input
        type={providedType || 'text'}
        readOnly={readOnly}
        className="form-control-no-border"
        placeholder={placeholder}
        maxLength={255}
        {...inputAttributes}
        {...register(fieldName, formFieldOptions)}
      />
      <FormFieldError error={fieldError} />
    </div>
  );
};

export default FormInput;
