import { useContext, useMemo } from 'react';
import { CmsContext } from '../../context/definitions';
import useConfirmPublish from '../../shared/cmsPage/hooks/useConfirmPublish';
import { CollectionEditorConstants, CollectionPublishPageConstants } from '../definitions';
import useArray from '../../shared/util/hooks/useArray';
export default function useCollectionItemPublishActions(props) {
  const { selectedItems, item, publishItem, collectionId, returnPageName, successGrowlId } = props;
  const { navigator, growlProvider, confirm } = useContext(CmsContext);
  const { success, error } = growlProvider;
  const confirmPublish = useConfirmPublish('collection');
  const publish = useMemo(
    () => ({
      label: `Publish Now`,
      onClick: async () => {
        const confirmation = await confirmPublish();

        if (confirmation) {
          try {
            await publishItem(collectionId, selectedItems);
            navigator.navigateToPluginPage(
              CollectionEditorConstants.pluginId,
              returnPageName,
              collectionId
            );
            success(successGrowlId, `The collection has been successfully published!`);
          } catch (e) {
            error(CollectionEditorConstants.defaultPageId, e.message);
          }
        }
      },
    }),
    [
      item,
      selectedItems,
      publishItem,
      confirmPublish,
      success,
      error,
      confirm,
      navigator,
      CollectionEditorConstants,
      collectionId,
      successGrowlId,
      returnPageName,
    ]
  );
  const cancel = useMemo(
    () => ({
      label: `Cancel`,
      onClick: () => {
        navigator.navigateToPluginPage(
          CollectionPublishPageConstants.pluginId,
          returnPageName,
          collectionId
        );
      },
    }),
    [navigator, CollectionEditorConstants, collectionId, returnPageName]
  );
  const toolbarActions = useArray(publish, cancel);
  return {
    toolbarActions,
  };
}
