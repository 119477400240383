import React, { useMemo } from 'react';
import { CmsNavigator } from '../CmsNavigator';
import { CmsContext } from '../definitions';
import useGrowlProvider from '../../shared/growl/hooks/useGrowlProvider';
import ConfirmModal from '../../shared/modal/components/ConfirmModal';
import useConfirmModal from '../../shared/modal/hooks/useConfirmModal';

const CmsReactContext = (props) => {
  const {
    children,
    navigateToState,
    showLinkSearch,
    showAudienceModal,
    showImportDialog,
    notifyContentUpdated,
    cmsConfig,
    selectedSubtenant,
    selectedTenant,
    addReport,
    getReports,
  } = props;
  const { growlProvider, growlMap } = useGrowlProvider();
  const { confirm, confirmProps } = useConfirmModal();
  const cmsContext = useMemo(() => {
    return {
      cmsConfig,
      selectedSubtenant,
      selectedTenant,
      growlProvider,
      growlMap,
      confirm,
      navigator: new CmsNavigator(navigateToState),
      showLinkSearch,
      showAudienceModal,
      notifyContentUpdated,
      showImportDialog,
      addReport,
      getReports,
    };
  }, [
    growlProvider,
    growlMap,
    navigateToState,
    cmsConfig,
    selectedSubtenant,
    selectedTenant,
    showLinkSearch,
    showAudienceModal,
    showImportDialog,
    addReport,
    getReports,
  ]);
  return (
    <CmsContext.Provider value={cmsContext}>
      {children}

      <ConfirmModal {...confirmProps} />
    </CmsContext.Provider>
  );
};

export default CmsReactContext;
