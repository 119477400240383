import { useMemo } from 'react';
import { clearPageState, getPageState } from '../cmsPageUtils';

const useSavedPageState = (pageId) => {
  return useMemo(() => {
    const result = getPageState(pageId);
    clearPageState(pageId);
    return result;
  }, [pageId]);
};

export default useSavedPageState;
