import axios from 'axios';
const baseUrl = '/plugins/surveys/api';
export const updateSurveyOptions = async (id, surveyOptions) => {
  try {
    await axios.post(`${baseUrl}/updateSurveyOptions`, { ...surveyOptions, id, timeout: 10000 });
  } catch (e) {
    console.error(e);
    throw new Error('Failed to remove survey.');
  }
};

const loadRecordsWrapper =
  (includeArchived = false) =>
  async (params) => {
    const {
      sort: { sortField, order },
      ...otherParams
    } = params;
    let result;

    try {
      const {
        data: { surveys, count, offset },
      } = await axios.get(`${baseUrl}/getSurveys`, {
        params: {
          ...otherParams,
          includeArchived,
          sortField,
          sortReverse: sortField ? order === 'DESC' : undefined,
        },
        timeout: 10000,
      });
      result = {
        records: surveys,
        numRecords: count,
        offset,
      };
    } catch (e) {
      console.error(e);
      throw new Error('Failed to load surveys');
    }

    return result;
  };

const generateSurveysApi = (includeArchived) => {
  const surveysApi = {
    loadRecords: loadRecordsWrapper(includeArchived),
  };
  return surveysApi;
};

export const saveInstance = async (surveyId, name) => {
  try {
    const {
      data: { surveyInstance },
    } = await axios.post(`${baseUrl}/saveInstance`, {
      params: {
        surveyId,
        name,
      },
      timeout: 10000,
    });
    return surveyInstance;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to load survey.');
  }
};
export const getCmsSurveyUrl = async (id, duplicate) => {
  try {
    const {
      data: { url },
    } = await axios.post(`${baseUrl}/getCmsSurveyUrl`, {
      id,
      duplicate,
    });
    return url;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to get cms survey url');
  }
};
export default generateSurveysApi;
