import { EMPTY_MENU_REVISION } from '../definitions';
import axios from 'axios';
const baseUrl = '/plugins/menu-manager/api';

const loadRecords = async (params) => {
  const { sort, ...otherParams } = params;
  const { sortField, order } = sort;
  const sortReverse = sortField ? order === 'DESC' : undefined;
  let result;

  try {
    const { data } = await axios.get(`${baseUrl}/getMenus`, {
      params: { ...otherParams, sortField, sortReverse },
      timeout: 10000,
    });
    const { menus, count, offset } = data;
    result = {
      records: menus,
      numRecords: count,
      offset,
    };
  } catch (e) {
    console.error(e);
    throw new Error('Failed to load menus');
  }

  return result;
};

const loadRecord = async (id) => {
  if (id === 'new') {
    return EMPTY_MENU_REVISION;
  } else {
    try {
      const { data } = await axios.get(`${baseUrl}/getMenu`, {
        params: {
          id,
        },
        timeout: 10000,
      });
      return data.menu;
    } catch (e) {
      console.error(e);
      throw new Error('Failed to load menu.');
    }
  }
};

const saveRecord = async (item) => {
  try {
    // eslint-disable-next-line no-unused-vars
    const { id, ...revisionProps } = item;
    const { data } = await axios.post(
      `${baseUrl}/saveMenu`,
      {
        id: item.menu_id,
        ...revisionProps,
      },
      {
        timeout: 10000,
      }
    );
    return data.menu;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to save menu.');
  }
};

const removeRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(
      `${baseUrl}/removeMenu`,
      {
        id,
      },
      {
        timeout: 10000,
      }
    );
  } catch (e) {
    console.error(e);
    throw new Error('Failed to remove menu.');
  }
};

const restoreRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(
      `${baseUrl}/restoreMenu`,
      {
        id,
      },
      {
        timeout: 10000,
      }
    );
  } catch (e) {
    console.error(e);
    throw new Error('Failed to restore menu.');
  }
};

const reorderRecords = async (parentId, itemIds) => {
  try {
    await axios.post(
      `${baseUrl}/reorderMenuItems`,
      {
        id: parentId,
        itemIds,
      },
      {
        timeout: 10000,
      }
    );
  } catch (e) {
    console.error(e);
    throw new Error('Failed to reorder menu.');
  }
};

const publishRecord = async (id, itemIds = []) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/publishMenu`,
      {
        id,
        itemIds,
      },
      {
        timeout: 10000,
      }
    );
    return data.menu;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to publish menu.');
  }
};

const migrate = async () => {
  try {
    await axios.post(
      `${baseUrl}/migrateMenus`,
      {},
      {
        timeout: 10000,
      }
    );
  } catch (e) {
    console.error(e);
    throw new Error('Failed to migrate menus.');
  }
};

const checkCanMigrate = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/canMigrateMenus`, {
      timeout: 10000,
    });
    return data.canMigrate;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to check ability to migrate menus.');
  }
};

const menuApi = {
  loadRecords,
  loadRecord,
  saveRecord,
  removeRecord,
  restoreRecord,
  reorderRecords,
  publishRecord,
  checkCanMigrate,
  migrate,
};
export default menuApi;
