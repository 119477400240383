import { useContext, useEffect } from 'react';
import { CmsContext } from '../../../context/definitions';

const useLoadEditorItem = (id, loadItem, onItemLoaded, item, pageConstants) => {
  const { editorPageId } = pageConstants;
  const { growlProvider } = useContext(CmsContext);
  const { error } = growlProvider;
  useEffect(() => {
    if (id) {
      (async () => {
        try {
          await loadItem(id);
        } catch (e) {
          error(editorPageId, e.message);
        }
      })();
    }
  }, [editorPageId, loadItem, id, error]);
  useEffect(() => {
    if (onItemLoaded) {
      onItemLoaded(item);
    }
  }, [item]);
};

export default useLoadEditorItem;
