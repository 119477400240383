(function () {
  angular.module('confirm-dialog', []).service('confirmDialog', [
    '$rootScope',
    '$modal',
    function ($rootScope, $modal) {
      return function (options) {
        var modalScope = $rootScope.$new();

        for (var key in options) {
          if (options.hasOwnProperty(key)) {
            modalScope[key] = options[key];
          }
        }

        return $modal.open({
          templateUrl: 'shared/confirmModal.html',
          animation: false,
          scope: modalScope,
        });
      };
    },
  ]);
})();
