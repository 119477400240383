import React, { useEffect } from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import AchievementEditorForm from './AchievementEditorForm';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { AchievementConstants } from '../definitions';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import achievementApiWrapped from '../api/achievementsApi';

const AchievementsEditorPage = (props) => {
  const { achievementId } = props;
  const achievementApi = achievementApiWrapped(true);
  const { item, loadItem, saveItem } = useItemPersistence(achievementApi);
  useEffect(() => {
    if (achievementId) {
      (async () => await loadItem(achievementId))();
    }
  }, [achievementId, loadItem]);
  const form = useCmsForm(item);
  const actions = useItemEditActions(saveItem, form, AchievementConstants);
  return (
    <CmsPage title={`${achievementId !== 'new' ? 'Edit' : 'Add'} Achievement`}>
      <AchievementEditorForm
        form={form}
        growlId={AchievementConstants.editorPageName}
        actions={actions}
      />
    </CmsPage>
  );
};

export default AchievementsEditorPage;
