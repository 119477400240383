import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import CmsReactContext from '../context/components/CmsReactContext';

function ReduxStore(props) {
  const { children, ...restProps } = props;
  return (
    <Provider store={store}>
      <CmsReactContext {...restProps}>{children}</CmsReactContext>
    </Provider>
  );
}

export default ReduxStore;
