import { useContext, useMemo } from 'react';
import { CmsContext } from '../../context/definitions';
import { getConfigUrl } from '../api/meetsApi';
export default function useAddMeet(meetType, pageConstants) {
  const {
    navigator,
    growlProvider: { error },
  } = useContext(CmsContext);
  const { typeName, pluginId, defaultPageId, editorPageName } = pageConstants;
  const add = useMemo(
    () => ({
      label: `+ Add ${typeName}`,
      onClick: ({ loaderParams }) => {
        navigator.navigateToChildPage(
          pluginId,
          editorPageName,
          defaultPageId,
          loaderParams,
          'new',
          meetType
        );
      },
    }),
    [typeName, navigator, pluginId, defaultPageId, editorPageName]
  );
  const edit = useMemo(
    () => ({
      icon: 'fa-solid fa-pencil',
      label: 'Edit',
      disabled: (context) => context.status === 'removed',
      onClick: (context, { loaderParams }) => {
        navigator.navigateToChildPage(
          pluginId,
          editorPageName,
          defaultPageId,
          loaderParams,
          context.id,
          meetType
        );
      },
    }),
    [navigator, pluginId, defaultPageId, editorPageName]
  );
  const configure = useMemo(
    () => ({
      label: 'Configure',
      icon: 'fa-solid fa-gear',
      onClick: async (context) => {
        try {
          const url = await getConfigUrl(context.id);
          window.open(url);
        } catch (e) {
          console.error(e);
          error(defaultPageId, 'Failed to open meet configuration editor.');
        }
      },
    }),
    [pluginId, defaultPageId, editorPageName]
  );
  return {
    add,
    edit,
    configure,
  };
}
