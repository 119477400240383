import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import { getUserOptions } from '../../shared/form/components/AudienceSelector/store/thunks';
import createConditionColumn from '../../shared/generalComponents/createConditionColumn';
import ItemPublishStatus from '../../shared/itemTable/components/ItemPublishStatus';
import Timestamp from '../../shared/itemTable/components/Timestamp';
import useArray from '../../shared/util/hooks/useArray';
import targetAudienceApiWrapped from '../api/targetAudienceApi';
import { TargetAudienceConstants } from '../definitions';
import useTargetAudienceActions from '../hooks/useTargetAudienceActions';

const TargetAudiencesListPage = () => {
  const { defaultPageId, typeName } = TargetAudienceConstants;
  const { add, archive, archiveCheckbox, edit, includeArchived, publish, duplicate } =
    useTargetAudienceActions(TargetAudienceConstants);
  const targetAudienceApi = targetAudienceApiWrapped(includeArchived);
  const dispatch = useDispatch();
  const opts = useSelector((state) => _.get(state, 'audienceSelector.rootOptions', {}));
  useEffect(() => {
    if (!opts || _.isEmpty(opts)) {
      dispatch(getUserOptions());
    }
  }, [dispatch, opts]);
  const columns = [
    {
      label: 'Name',
      valueField: 'name',
      sortable: true,
      initialSort: 'ASC',
      renderer: (item) => <span>{item.name}</span>,
    },
    createConditionColumn('Target Condition', 'condition', 'fa-bullseye', opts),
    {
      label: 'Status',
      valueField: 'status',
      sortable: false,
      renderer: (item) => <ItemPublishStatus status={item.status} />,
    },
    {
      label: 'Last Modified',
      valueField: 'updated',
      sortable: true,
      renderer: (item, field) => <Timestamp date={item[field]} />,
    },
  ];
  if (includeArchived)
    columns.push({
      label: 'Archived',
      valueField: 'isArchived',
      sortable: false,
      renderer: (item) => (item.isArchived ? 'Yes' : 'No'),
    });
  const toolbarActions = useArray(archiveCheckbox, add);
  const itemActions = useArray(edit, archive, publish, duplicate);
  return (
    <CmsPage title={'Audiences'}>
      <CmsItemListSection
        pageId={defaultPageId}
        typeName={typeName}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={columns}
        itemApi={targetAudienceApi}
      />
    </CmsPage>
  );
};

export default TargetAudiencesListPage;
