import React, { useContext } from 'react';
import CmsPageSection from './CmsPageSection';
import { CmsPageContext } from '../definitions';
import { useSpinner } from '../../spinner/hooks/useSpinner';
import GrowlContainer from '../../growl/components/GrowlContainer';
import useWrapPageActions from '../hooks/useWrapPageActions';
import CmsSubItemListSectionToolbar from './CmsSubItemListSectionToolbar';
import OrderedItemTable from '../../itemTable/components/OrderedItemTable';
import useLoadSubListItems from '../hooks/useLoadSubListItems';
import { useSubItemsLoader } from '../../cms/hooks/useSubItemsLoader';
import ConidtionalAlert from '../../generalComponents/ConditionalAlert';

const CmsSubItemListSection = (props) => {
  const {
    alert,
    className,
    columns,
    emptyListMessage,
    itemActions,
    pageConstants,
    parentItemId,
    parentPageTitle,
    reorder,
    subItemApi,
    toolbarActions,
    showBreadCrumb,
    priorityFilter,
    prioritySort,
  } = props;
  const { pluginId, parentPageId, parentPageName, defaultPageId } = pageConstants;
  const { spinnerProvider } = useContext(CmsPageContext);
  const { loadRecords } = subItemApi;
  const { loadItems, loading, records } = useSubItemsLoader(loadRecords);
  useSpinner(spinnerProvider, loading);
  const reload = useLoadSubListItems(defaultPageId, parentItemId, loadItems);
  const wrappedToolbarActions = useWrapPageActions(defaultPageId, toolbarActions, reload);
  const wrappedItemActions = useWrapPageActions(defaultPageId, itemActions, reload);
  const { standardRecords, priorityRecordsUnsorted } = priorityFilter
    ? records.reduce(
        (acc, record) => {
          if (priorityFilter(record)) {
            acc.priorityRecordsUnsorted.push(record);
          } else {
            acc.standardRecords.push(record);
          }
          return acc;
        },
        { standardRecords: [], priorityRecordsUnsorted: [] }
      )
    : { standardRecords: records, priorityRecordsUnsorted: [] };
  const priorityRecords = prioritySort
    ? priorityRecordsUnsorted.sort(prioritySort)
    : priorityRecordsUnsorted;
  return (
    <>
      <CmsPageSection className={className}>
        <div className="row">
          <CmsSubItemListSectionToolbar
            showBreadCrumb={showBreadCrumb}
            pluginId={pluginId}
            parentPageTitle={parentPageTitle}
            parentPageId={parentPageId}
            parentPageName={parentPageName}
            actions={wrappedToolbarActions}
          />
        </div>
        <GrowlContainer groupId={defaultPageId} />
        {alert && <ConidtionalAlert {...alert} />}
        <div className="row">
          <OrderedItemTable
            columns={columns}
            items={[...priorityRecords, ...standardRecords]}
            itemActions={wrappedItemActions}
            emptyListMessage={emptyListMessage}
            reorder={reorder}
            parentItemId={parentItemId}
            growlId={defaultPageId}
            injectedRowCount={priorityRecords.length}
          />
        </div>
      </CmsPageSection>
    </>
  );
};

export default CmsSubItemListSection;
