import React, { useCallback, useContext, useEffect } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import { CmsPageContext } from '../../shared/cmsPage/definitions';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import useLoadEditorItem from '../../shared/cmsPage/hooks/useLoadEditorItem';
import FormInput from '../../shared/form/components/FormInput';
import Select from '../../shared/form/components/Select';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { useSpinner } from '../../shared/spinner/hooks/useSpinner';
import { loadGroups } from '../api/meetsApi';
import useMeetConfigurations from '../hooks/useMeetConfigurations';

const MeetEditor = (props) => {
  const { itemId, onItemChange, meetType } = props;
  const { spinnerProvider } = useContext(CmsPageContext);
  const { constants, api } = useMeetConfigurations(meetType);
  const { item, loadItem, saveItem, requestPending } = useItemPersistence(api);
  const form = useCmsForm(item);
  const actions = useItemEditActions(saveItem, form, constants);
  useSpinner(spinnerProvider, requestPending);
  useLoadEditorItem(itemId, loadItem, onItemChange, item, constants);
  useEffect(() => {
    if (meetType === 'vod') {
      form.setValue('groupId', null);
    }
  }, [meetType]);
  const getGroups = useCallback(async () => {
    const groupOptions = (await loadGroups()).map((grp) => ({
      label: grp.name,
      value: grp.id,
    }));
    return groupOptions;
  }, [loadGroups]);
  return (
    <>
      <div className="row no-padding-left">
        <form role="form" className="neg-left-margin-13">
          <FormInput
            label="Title"
            fieldName="title"
            formContext={form}
            formFieldOptions={{
              required: 'This field is required.',
            }}
          />

          {meetType !== 'vod' && (
            <Select
              label="Presenter Group ID"
              fieldName="groupId"
              formContext={form}
              formFieldOptions={{
                required: 'This field is required',
              }}
              optionsLoader={getGroups}
            />
          )}
        </form>
      </div>
      <div className="row module-footer">
        <CmsPageSectionFooter actions={actions} />
      </div>
    </>
  );
};

export default MeetEditor;
