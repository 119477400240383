import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
export const useCmsForm = (defaultValues) => {
  const form = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues,
  });
  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);
  return form;
};
