import { useContext, useMemo } from 'react';
import { CmsContext } from '../../../context/definitions';
import useConfirmPublish from './useConfirmPublish';
import { useItemPersistence } from '../../cms/hooks/useItemPersistence';

const usePublishActionHandler = (
  pluginId,
  typeName,
  publishPageName,
  returnPageId,
  returnPageName,
  returnPageLabel,
  growlId,
  itemApi
) => {
  const { navigator, growlProvider } = useContext(CmsContext);
  const { success, error } = growlProvider;
  const confirmPublish = useConfirmPublish(typeName);
  const { publishItem } = useItemPersistence(itemApi);
  return useMemo(() => {
    let publishFn;

    if (publishItem) {
      publishFn = async (itemId, hasItemsToPublish, reload) => {
        if (hasItemsToPublish) {
          navigator.navigateToPluginPage(
            pluginId,
            publishPageName,
            itemId,
            returnPageId,
            returnPageName,
            returnPageLabel,
            growlId
          );
        } else {
          const confirmation = await confirmPublish();

          if (confirmation) {
            try {
              await publishItem(itemId);
              success(growlId, `The ${typeName} has been successfully published!`);
              await reload();
            } catch (e) {
              error(growlId, e.message);
            }
          }
        }
      };
    }

    return publishFn;
  }, [
    pluginId,
    publishItem,
    publishPageName,
    returnPageId,
    returnPageName,
    returnPageLabel,
    growlId,
    success,
    error,
  ]);
};

export default usePublishActionHandler;
