import { useContext, useMemo, useState } from 'react';
import { CmsContext } from '../../context/definitions';
import useItemListActions from '../../shared/cmsPage/hooks/useItemListActions';
import { PushNotificationConstants } from '../definitions';
import pushNotificationApi from '../api/pushNotificationApi';
export default function usePushNotificationActions(pageConstants) {
  const [autoRefresh, setAutoRefresh] = useState(true);
  const {
    navigator,
    growlProvider: { error },
  } = useContext(CmsContext);
  const { requestPending } = useItemListActions(pushNotificationApi, PushNotificationConstants);
  const { saveRecord } = pushNotificationApi;
  const refresh = useMemo(
    () => ({
      visible: !autoRefresh,
      // disabled:true,
      label: autoRefresh ? 'Hide me' : 'Refresh',
      onClick: async ({ reload }) => {
        try {
          await reload();
        } catch (e) {
          console.error(e);
          error(defaultPageId, 'Failed to refresh.');
        }
      },
    }),
    [autoRefresh, setAutoRefresh]
  );
  const autoRefreshButton = useMemo(
    () => ({
      label: autoRefresh ? 'Auto Refresh is ON' : 'Auto Refresh is OFF',
      onClick: async () => void setAutoRefresh((current) => !current),
    }),
    [autoRefresh, setAutoRefresh]
  );
  const { typeName, pluginId, defaultPageId, editorPageName } = pageConstants;
  const addOverride = useMemo(
    () => ({
      label: `+ Schedule Notification`,
      onClick: async (loaderParams) => {
        try {
          const newPushNotification = await pushNotificationApi.loadRecord('new');
          navigator.navigateToPluginPage(
            PushNotificationConstants.pluginId,
            PushNotificationConstants.editorPageName,
            newPushNotification.id || 'new'
          );
        } catch (e) {
          console.error(e);
          error(defaultPageId, 'Failed to add.');
        }
      },
    }),
    [typeName, navigator, pluginId, defaultPageId, editorPageName]
  );
  const view = useMemo(
    () => ({
      label: `View`,
      onClick: async (item) => {
        try {
          const newPushNotification = await pushNotificationApi.loadRecord(item.id);
          navigator.navigateToPluginPage(
            PushNotificationConstants.pluginId,
            PushNotificationConstants.editorPageName,
            newPushNotification.id || 'new'
          );
        } catch (e) {
          console.error(e);
          error(defaultPageId, 'Failed to view.');
        }
      },
    }),
    [typeName, navigator, pluginId, defaultPageId, editorPageName]
  );
  const copy = useMemo(
    () => ({
      label: `Copy`,
      onClick: async (item) => {
        try {
          const basePushNotification = await pushNotificationApi.loadRecord(item.id);
          const { notificationId } = await saveRecord({ ...basePushNotification, id: 'new' });
          navigator.navigateToPluginPage(
            PushNotificationConstants.pluginId,
            PushNotificationConstants.editorPageName,
            notificationId,
            true
          );
        } catch (e) {
          console.error(e);
          error(defaultPageId, 'Failed to copy.');
        }
      },
    }),
    [typeName, navigator, pluginId, defaultPageId, editorPageName]
  );
  return {
    add: addOverride,
    refresh,
    view,
    copy,
    autoRefreshButton,
    requestPending,
    autoRefresh,
  };
}
