import React, { useCallback, useContext } from 'react';
import { CmsContext } from '../../context/definitions';

const CollectionItemTitle = (props) => {
  const { collectionItem, collectionId } = props;
  const { navigator } = useContext(CmsContext);
  const navigateToChild = useCallback(() => {
    navigator.navigateToPluginPage(
      'collections',
      'collectionItemEditor',
      collectionId,
      collectionItem.id
    );
  }, [navigator]);
  return (
    <>
      <a onClick={navigateToChild}>{collectionItem?.label}</a>
    </>
  );
};

export default CollectionItemTitle;
