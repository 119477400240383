angular
  .module('content-api-service', [])
  .service('contentApiService', [
    '$q',
    '$log',
    'Upload',
    '$http',
    '$rootScope',
    'CMS_INSTANCES',
    'instanceService',
    '$cookies',
    function ($q, $log, Upload, $http, $rootScope, CMS_INSTANCES, instanceService, $cookies) {
      var VALID_NAME_CHARS = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.()_-';
      var assetMap = {};

      function getAllAssets() {
        return $http
          .get('/content-api/getAllAssets')
          .success(function (data, status, headers, config) {
            rebuildAssetMap(data.assets);

            $log.debug('Successfully got all assets.');
          })
          .error(function (data, status, headers, config) {
            $log.debug('Failed to get all assets. Response: ', data);
          });
      }

      function getPreviewConfig(scheme) {
        var deferred = $q.defer();

        var params = {};
        if (scheme) {
          params.previewScheme = scheme;
        }

        $http
          .get('/content-api/getPreviewUrl', { params })
          .success(function (data, status, headers, config) {
            deferred.resolve({
              url: data.previewUrl + '?previewInstance=' + instanceService.getActiveInstance().id,
              checkSnapshot: data.checkSnapshot,
            });
            $log.debug('Successfully got previewUrl.');
          })
          .error(function (data, status, headers, config) {
            deferred.resolve('/preview?previewInstance=' + instanceService.getActiveInstance().id);
            $log.debug('Failed to get previewUrl. Response: ', data);
          });

        return deferred.promise;
      }

      function getMetadataForAsset(assetId) {
        var deferred = $q.defer();

        $http
          .get('/content-api/getAssetMetadata?assetId=' + encodeURIComponent(assetId))
          .success(function (data, status, headers, config) {
            deferred.resolve(data.asset);
            $log.debug('Successfully got asset.');
          })
          .error(function (data, status, headers, config) {
            $log.debug('Failed to get asset. Response: ', data);
            deferred.reject(data.error || 'Failed to get asset.');
          });

        return deferred.promise;
      }

      function rebuildAssetMap(assets) {
        assetMap = {};
        for (var i = 0; i < assets.length; i++) {
          var asset = assets[i];
          assetMap[asset.id] = asset;
        }
      }

      function isEmpty(object) {
        for (var key in object) {
          if (object.hasOwnProperty(key)) {
            return false;
          }
        }
        return true;
      }

      function getAssetMetadata(assetId) {
        var deferred = $q.defer();

        if (!isEmpty(assetMap)) {
          deferred.resolve(assetMap[assetId]);
        } else {
          getMetadataForAsset(assetId).then(deferred.resolve).catch(deferred.reject);
        }

        return deferred.promise;
      }

      function getAsset(assetId, revisionId, forceText) {
        revisionId = revisionId || -1;

        // If forceText is true, prevent default angular parsing of asset contents (JSON, for example).
        var promise = $http
          .get(
            '/preview/asset/' +
              assetId +
              '?revisionId=' +
              revisionId +
              '&tenantId=' +
              $cookies.get('tenantId'),
            {
              transformResponse: forceText
                ? function (value) {
                    return value;
                  }
                : undefined,
            }
          )
          .success(function (data, status, headers, config) {
            //$log.debug('Successfully got asset. Response: ', data);
          })
          .error(function (data, status, headers, config) {
            $log.debug('Failed to get asset. Response: ', data);
          });

        return promise;
      }

      function saveAsset(contents, asset, overwrite) {
        var data = {
          asset: asset,
          overwrite: !!overwrite,
          fileContents: contents,
        };

        var promise = $http.post('/content-api/saveAsset', data);

        console.log(data);

        promise.then(function (result) {
          assetMap[asset.id] = result.data.asset;
          broadcastAssetUpdated(asset.id);
        });

        return promise;
      }
      /*
      function schedulePushNotification(
        title,
        contents,
        ttl,
        linkText,
        targetUrl,
        logic,
        sendTimestamp
      ) {
        return $http.post('/push/schedulePushNotification', {
          audienceExpr: logic,
          ttlMinutes: ttl,
          title: title,
          message: contents,
          url: targetUrl,
          linkText: linkText,
          sendTimestamp: sendTimestamp,
        });
      }
*/
      function cancelPushNotification(notificationId) {
        return $http.post('/push/cancelPushNotification', {
          notificationId: notificationId,
        });
      }

      function getPushNotifications(offset, limit) {
        return $http.get('/push/getPushNotifications', {
          params: {
            limit: limit,
            offset: offset,
          },
        });
      }

      function checkSnapshotIsComputedForInstance(instance) {
        const deferred = $q.defer();
        $http
          .get(`/content-api/checkSnapshotIsComputed?instanceId=${instance}`)
          .success(function (data, status, headers, config) {
            deferred.resolve(data.isComputed);
          })
          .error(function (data, status, headers, config) {
            $log.error(`Failed to check computed status of ${instance} snapshot.`, data);
            deferred.resolve(false);
          });

        return deferred.promise;
      }

      function upsertFile(file, asset, overwrite) {
        var promise = Upload.upload({
          url: '/content-api/uploadAsset',
          method: 'POST',
          data: {
            assetData: Upload.rename(file, asset.id),
            data: Upload.json({
              asset: asset,
              overwrite: !!overwrite,
            }),
          },
        });

        promise
          .progress(function (evt) {
            $log.debug('progress: ' + parseInt((100.0 * evt.loaded) / evt.total) + '%');
          })
          .success(function (data, status, headers, config) {
            $log.debug('Asset was added successfully.  Response: ', data);

            assetMap[asset.id] = data.asset;
            broadcastAssetUpdated(asset.id);
          })
          .error(function (err, status, headers, config) {
            $log.debug('error : status = ' + status, err);
          });

        return promise;
      }

      function removeOverride(asset) {
        var data = {
          assetId: asset.id,
        };

        var promise = $http.post('/content-api/removeOverride', data);

        promise.then(function (result) {
          if (result.data.asset) {
            assetMap[result.data.asset.id] = result.data.asset;
          } else {
            delete assetMap[result.data.asset.id];
          }

          broadcastAssetUpdated(asset.id);
        });

        return promise;
      }

      function restoreRevision(asset, revisionId) {
        var data = {
          assetId: asset.id,
          revisionId: revisionId,
        };

        var promise = $http.post('/content-api/restoreRevision', data);

        promise.then(function (result) {
          assetMap[asset.id] = result.data.asset;
          broadcastAssetUpdated(asset.id);
        });

        return promise;
      }

      function revertRevision(assetId) {
        var data = {
          assetId: assetId,
        };

        var promise = $http.post('/content-api/revertRevision', data);

        promise.then(function (result) {
          assetMap[assetId] = result.data.asset;
          broadcastAssetUpdated(assetId);
        });

        return promise;
      }

      function publishContent(assetIdsToPublish) {
        var data = {
          fromInstance: CMS_INSTANCES.sandbox.id,
          toInstance: CMS_INSTANCES.live.id,
          assetIds: assetIdsToPublish,
        };

        var promise = $http.post('/content-api/publishContent', data);

        promise.then(function () {
          $rootScope.$broadcast('contentUpdated');
        });

        return promise;
      }

      function installDefaultContent(file) {
        var promise = Upload.upload({
          url: '/content-api/installDefaultContent',
          method: 'POST',
          data: {
            fileData: file,
          },
        });

        promise
          .progress(function (evt) {
            $log.debug('progress: ' + parseInt((100.0 * evt.loaded) / evt.total) + '%');
          })
          .success(function (data, status, headers, config) {
            $log.debug('Content file  was installed successfully.  Response: ', data);

            $rootScope.$broadcast('defaultContentUpdated');
          })
          .error(function (err, status, headers, config) {
            $log.debug('Failed to upload . Error: ', err);
          });

        return promise;
      }

      function exportContent() {
        var promise = $http.get('/content-api/exportContent');

        promise
          .success(function (data, status, headers, config) {
            $log.debug('Successfully exported content. Response: ', data);
          })
          .error(function (data, status, headers, config) {
            $log.debug('Failed to export content. Response: ', data);
          });

        return promise;
      }

      function scrubAssetId(id) {
        var newId = '';
        for (var i = 0; i < id.length; i++) {
          var ch = id.charAt(i);
          var found = VALID_NAME_CHARS.indexOf(ch);
          if (found != -1) {
            newId += ch;
          } else {
            newId += '_';
          }
        }

        return newId;
      }

      function broadcastAssetUpdated(assetId) {
        $rootScope.$broadcast('assetUpdated', assetId);
      }

      var types = {
        html: {
          id: 'html',
          label: 'Html',
          extension: '.html',
          editor: 'main.edit-html',
        },
        css: {
          id: 'css',
          label: 'CSS',
          extension: '.css',
          editor: 'main.edit-css',
        },
        js: {
          id: 'js',
          label: 'Javascript',
          extension: '.js',
          editor: 'main.edit-js',
        },
        json: {
          id: 'json',
          label: 'JSON',
          extension: '.json',
          editor: 'main.edit-json',
        },
        wysiwyg: {
          id: 'wysiwyg',
          label: 'WYSIWYG',
          extension: '.html',
          editor: 'main.edit-wysiwyg',
        },
        file: {
          id: 'file',
          label: 'File',
          extension: '.*',
          editor: undefined,
        },
      };

      var groups = {
        default: {
          id: 'default',
          label: 'Default',
          selectable: true,
        },
        interactiveScanner: {
          id: 'interactiveScanner',
          label: 'Interactive Scanner',
          selectable: true,
        },
        menuIcon: {
          id: 'menuIcon',
          label: 'Menu Icon',
          selectable: true,
        },
        theme: {
          id: 'theme',
          label: 'Theme',
          selectable: true,
        },
        plugin: {
          id: 'plugin',
          label: 'Plugin',
          selectable: false,
        },
      };

      function getEditor(type) {
        type = type || 'file';

        return lookupType(type).editor;
      }

      function lookupType(typeName) {
        var result = types[typeName];
        if (!result) {
          throw Error("Type '" + typeName + "' not found");
        }
        return result;
      }

      function getTypeFromExtension(fileName) {
        var parts = fileName.split('.');
        var extension = parts.length > 1 ? '.' + parts[parts.length - 1] : '.file';
        for (var typeId in types) {
          var type = types[typeId];
          if (type.extension == extension) {
            return type;
          }
        }

        return types.file;
      }

      function getTypes() {
        return types;
      }

      function getGroups() {
        return groups;
      }

      function getRevisionForInstance(record, instance, useLatestAsDefault) {
        var active = record.instanceMappings ? record.instanceMappings[instance] : undefined;
        if (!active && useLatestAsDefault) {
          active = record.latestRevision;
        }
        return active;
      }

      function getUpdatesFrozen() {
        return $http.get('/content-api/getUpdatesFrozen').then(
          function (data, status, headers, config) {
            if (data.data && data.data.status == 'success') {
              return data.data.updatesFrozen;
            } else {
              console.log(data);
              throw new Error('Unexpected result from getUpdatesFrozen');
            }
          },
          function (data, status, headers, config) {
            $log.debug('Failed to get updatesFrozen', data);
          }
        );
      }

      function setUpdatesFrozen(updatesFrozen) {
        var data = {
          updatesFrozen: updatesFrozen,
        };

        return $http.post('/content-api/setUpdatesFrozen', data);
      }

      return {
        getEditor: getEditor,
        getAsset: getAsset,
        getAssetMetadata: getAssetMetadata,
        getPreviewConfig: getPreviewConfig,
        getTypes: getTypes,
        getGroups: getGroups,
        lookupType: lookupType,
        getRevisionForInstance: getRevisionForInstance,
        getTypeFromExtension: getTypeFromExtension,
        saveAsset: saveAsset,
        upsertFile: upsertFile,
        removeOverride: removeOverride,
        restoreRevision: restoreRevision,
        revertRevision: revertRevision,
        scrubAssetId: scrubAssetId,
        getAllAssets: getAllAssets,
        publishContent: publishContent,
        installDefaultContent: installDefaultContent,
        exportContent: exportContent,
        broadcastAssetUpdated: broadcastAssetUpdated,
        getUpdatesFrozen: getUpdatesFrozen,
        setUpdatesFrozen: setUpdatesFrozen,
        //schedulePushNotification: schedulePushNotification,
        cancelPushNotification: cancelPushNotification,
        getPushNotifications: getPushNotifications,
        checkSnapshotIsComputedForInstance: checkSnapshotIsComputedForInstance,
      };
    },
  ])
  .filter('selectableGroups', function () {
    return function (groups) {
      var result = {};
      for (var key in groups) {
        var group = groups[key];
        if (group.selectable) {
          result[key] = group;
        }
      }

      return result;
    };
  });
