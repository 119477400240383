import React, { useContext, useEffect } from 'react';
import GrowlItem from './GrowlItem';
import useGrowls from '../hooks/useGrowls';
import { CmsContext } from '../../../context/definitions';

const GrowlContainer = (props) => {
  const { groupId } = props;
  const { growlMap, growlProvider } = useContext(CmsContext);
  const { dismiss, dismissAll } = growlProvider;
  useEffect(() => {
    return () => dismissAll(groupId);
  }, [groupId, dismissAll]);
  const growls = useGrowls(groupId, growlMap);
  return (
    <div className="growl-container top-right">
      {growls.map((growl, index) => (
        <GrowlItem key={index} growl={growl} onDismiss={() => dismiss(growl)} />
      ))}
    </div>
  );
};

export default GrowlContainer;
