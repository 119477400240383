import { useCallback, useContext, useEffect } from 'react';
import { CmsContext } from '../../../context/definitions';

const useLoadListItems = (pageId, loadItems, loaderParams) => {
  const { growlProvider } = useContext(CmsContext);
  const { error } = growlProvider;
  const reload = useCallback(
    async (loaderParams) => {
      try {
        await loadItems(loaderParams);
      } catch (e) {
        error(pageId, e.message);
      }
    },
    [loadItems, pageId, error]
  );
  useEffect(() => {
    (async () => reload(loaderParams))();
  }, [reload, loaderParams]);
  return reload;
};

export default useLoadListItems;
