import React from 'react';
import FormInput from '../../shared/form/components/FormInput';

const MenuEditorForm = (props) => {
  const { form } = props;
  return (
    <div className="">
      <form role="form">
        <FormInput
          label="Name"
          fieldName="name"
          formContext={form}
          formFieldOptions={{
            required: 'This field is required.',
          }}
        />
        <FormInput label="Menu Display Screen Title" fieldName="label" formContext={form} />
      </form>
    </div>
  );
};

export default MenuEditorForm;
