import axios from 'axios';
export default async function snapshotStable(instance = 'sandbox') {
  try {
    const { data } = await axios.get(
      `/content-api/checkSnapshotIsComputed?instanceId=${instance}`,
      {
        timeout: 10000,
      }
    );
    return data.isComputed;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to check snapshot state.');
  }
}
