angular.module('navigation-service', []).service('navigationService', [
  '$http',
  '$q',
  '$state',
  '$modal',
  '$rootScope',
  'growl',
  'configService',
  'contentApiService',
  'previewService',
  function (
    $http,
    $q,
    $state,
    $modal,
    $rootScope,
    growl,
    configService,
    contentApiService,
    previewService
  ) {
    function navigateToFirstMenuItem() {
      configService.getConfig().then(function (config) {
        for (var i = 0; i < config.sideMenu.length; i++) {
          var menuItem = config.sideMenu[i];
          if (menuItem.navigation) {
            navigateToMenuItem(menuItem);
            break;
          }
        }
      });
    }

    function navigateToMain() {
      $state.go('main.root');
    }

    function navigateToMenuItem(menuItem) {
      var navigation = menuItem.navigation;
      if (navigation) {
        if (navigation.state) {
          $state.go(navigation.state, navigation.stateParams);
          window.scrollTo(0, 0);
        } else if (navigation.clickHandler) {
          var clickHandler = this[navigation.clickHandler];
          if (clickHandler) {
            clickHandler();
          } else {
            console.log('Click handler ' + navigation.clickHandler + ' not found.');
          }
        } else if (navigation.href) {
          window.location = navigation.href;
        }
      }
    }

    function installDefaultContent() {
      var options = {
        templateUrl: 'modules/manageContent/installContentModal.html',
        controller: 'InstallContentCtrl',
        animation: false,
      };

      var modalInstance = $modal.open(options);
      modalInstance.result.then(
        function (result) {
          growl.success('Default content installed successfully.');
        },
        function () {}
      );
    }

    function exportContent() {
      contentApiService
        .exportContent()
        .success(function (data, status, headers, config) {
          var modalScope = $rootScope.$new();
          modalScope.title = 'Content exported';
          modalScope.message =
            'Content has been exported successfully.  Click the button below to download.';
          var modalInstance = $modal.open({
            templateUrl: 'modules/manageContent/fileDownloadModal.html',
            scope: modalScope,
            animation: false,
          });

          modalInstance.result.then(function (result) {
            window.open(data.packageDownloadUrl, '_self');
          });
        })
        .error(function (err, status, headers, config) {
          growl.error('Content export failed.  Please try again later.');
        });
    }

    function previewApp() {
      previewService.launchPreview();
    }

    function previewAppLegacy() {
      previewService.launchPreview(null, 'legacy');
    }

    return {
      navigateToMain: navigateToMain,
      navigateToFirstMenuItem: navigateToFirstMenuItem,
      navigateToMenuItem: navigateToMenuItem,
      installDefaultContent: installDefaultContent,
      exportContent: exportContent,
      previewApp: previewApp,
      previewAppLegacy: previewAppLegacy,
    };
  },
]);
