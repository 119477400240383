import useItemListActions from '../../shared/cmsPage/hooks/useItemListActions';
import cohortApi from '../api/cohortApi';
import { CohortConstants } from '../definitions';
import { useContext, useMemo } from 'react';
import { CmsContext } from '../../context/definitions';

const useCohortListActions = (showCohortEditorModal) => {
  const { navigator } = useContext(CmsContext);
  const { add, edit, remove, requestPending } = useItemListActions(cohortApi, CohortConstants);
  const addOverride = useMemo(() => {
    return {
      ...add,
      onClick: async ({ loaderParams }) => {
        const newCohort = await cohortApi.loadRecord('new');
        const savedItem = await showCohortEditorModal(newCohort);

        if (savedItem) {
          navigator.navigateToChildPage(
            CohortConstants.pluginId,
            CohortConstants.editorPageName,
            CohortConstants.defaultPageId,
            loaderParams,
            savedItem.id
          );
        }
      },
    };
  }, [add, cohortApi, navigator, showCohortEditorModal]);
  return useMemo(() => {
    return {
      add: addOverride,
      edit,
      remove,
      requestPending,
    };
  }, [addOverride, edit, remove, requestPending]);
};

export default useCohortListActions;
