import React, { useCallback, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { setShowModal } from '../store/index';
import { selectModalProps } from '../store/selectors';
import { getUserOptions } from '../store/thunks';
import AudienceName from './components/AudienceInput';
import Preview from './components/preview';
import QueryBuilderContainer from './components/query-builder-container';
import PreviewFooter from './components/query-builder-modal-footer/PreviewFooter';
import QueryBuilderFooter from './components/query-builder-modal-footer/QueryBuilderFooter';
import SaveFooter from './components/query-builder-modal-footer/SaveFooter';
import SaveOptionFooter from './components/query-builder-modal-footer/SaveOptionFooter';

const QueryBuilder = ({ oldLogic, onSave: onSaveCallback, fieldName }) => {
  const dispatch = useDispatch();
  useEffect(() => void dispatch(getUserOptions()), [dispatch]);
  const onSave = useCallback(
    (audience = false) => {
      onSaveCallback(audience);
      dispatch(
        setShowModal({
          showModal: false,
          savingLogic: !audience,
          fieldName,
        })
      );
    },
    [onSaveCallback, dispatch, fieldName]
  );
  const { showModal, showPreview, showQueryBuilder, showNewAudience, showSaveNewAudience } =
    selectModalProps(fieldName);
  const handleCancel = useCallback(() => {
    dispatch(
      setShowModal({
        showModal: false,
        fieldName,
      })
    );
  }, [dispatch, fieldName]);
  return (
    <Modal
      fullscreen={'sm-down'}
      animation={false}
      backdrop="static"
      backdropClassName="qb-modal"
      contentClassName="qb-modal"
      dialogClassName="qb-modal"
      show={showModal}
      onHide={handleCancel}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {showSaveNewAudience && 'Save Audience?'}
          {showNewAudience && 'Create New Audience'}
          {showPreview && 'Query Preview'}
          {showQueryBuilder && 'Query Builder'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showSaveNewAudience && <div>Would you like to save the Audience you just created?</div>}
        {showNewAudience && <AudienceName fieldName={fieldName} />}
        {showPreview && <Preview fieldName={fieldName} />}
        {showQueryBuilder && <QueryBuilderContainer fieldName={fieldName} />}
      </Modal.Body>
      <Modal.Footer>
        {showSaveNewAudience && <SaveOptionFooter onSave={onSave} fieldName={fieldName} />}
        {showNewAudience && <SaveFooter onSave={onSave} fieldName={fieldName} />}
        {showPreview && <PreviewFooter fieldName={fieldName} />}
        {showQueryBuilder && <QueryBuilderFooter onSave={onSave} fieldName={fieldName} />}
      </Modal.Footer>
    </Modal>
  );
};

export default QueryBuilder;
