import React, { useContext, useEffect, useCallback, useState } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import { CmsPageContext } from '../../shared/cmsPage/definitions';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import useLoadEditorItem from '../../shared/cmsPage/hooks/useLoadEditorItem';
import AudienceSelector from '../../shared/form/components/AudienceSelector';
import CreateableSelect from '../../shared/form/components/CreatableSelect';
import FormImage from '../../shared/form/components/FormImage';
import FormInput from '../../shared/form/components/FormInput';
import FormInputTextArea from '../../shared/form/components/FormInputTextArea';
import FormWysiwyg from '../../shared/form/components/FormWysiwyg';
import LinkFinderInline from '../../shared/form/components/LinkFinderInline';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { useSpinner } from '../../shared/spinner/hooks/useSpinner';
import collectionApi, { loadCategories } from '../api/collectionApi';
import collectionItemApi from '../api/collectionItemApi';
import { CollectionEditorConstants } from '../definitions';
import FormSwitch from '../../shared/form/components/FormSwitch';

const CollectionItemEditor = (props) => {
  const { itemId, collectionId, growlId, onItemChange, changeTitle } = props;
  const { spinnerProvider } = useContext(CmsPageContext);
  const { item, loadItem, saveItem, requestPending } = useItemPersistence(
    collectionItemApi,
    collectionId
  );

  const form = useCmsForm(item);
  const { setValue, getValues, watch } = form;
  const actions = useItemEditActions(saveItem, form, CollectionEditorConstants, collectionId);
  const defaultPage = 'cms://default';

  const [imageBlock, setImageBlock] = useState(true);
  const [showTimeInputs, setShowTimeInputs] = useState(true);
  const [linkUsed, setLinkUsed] = useState(false);
  const [detailsUsed, setDetailsUsed] = useState(false);

  const {
    item: collection,
    loadItem: loadCollection,
    requestPending: collectionRequestPending,
  } = useItemPersistence(collectionApi); //Load Collection

  const watchTimed = watch('timed');
  const watchLink = watch('useLink');
  const watchDetails = watch('useDetails');

  useEffect(() => {
    if (collectionId) {
      (async () => await loadCollection(collectionId))();
    }
  }, [collectionId, loadCollection]); //Change Collection Name and set Image

  useEffect(() => {
    if (collection) {
      changeTitle(collection.name);
      setImageBlock(collection.useImage);
      const timedLoadValue = getValues('timed');
      if (timedLoadValue == null) {
        setValue('timed', 'timed');
      }
    }
  }, [collection]);

  useEffect(() => {
    if (item) {
      setShowTimeInputs(watchTimed === 'timed');
    }
  }, [watchTimed, item]);

  useEffect(() => {
    if (item) {
      if (watchLink) {
        setLinkUsed(true);
        setValue('actionButtonText', item.actionButtonText || 'View');
      } else {
        setLinkUsed(false);
        setValue('actionButtonText', '');
      }
    }
  }, [watchLink, item]);

  useEffect(() => {
    if (item) {
      if (watchDetails) {
        setDetailsUsed(true);
        setValue('infoButtonText', item.infoButtonText || 'Details');
      } else {
        setDetailsUsed(false);
        setValue('infoButtonText', '');
      }
    }
  }, [watchDetails, item]);

  useSpinner(spinnerProvider, requestPending || collectionRequestPending);
  useLoadEditorItem(itemId, loadItem, onItemChange, item, CollectionEditorConstants);
  const getCategories = useCallback(async () => {
    return loadCategories(collectionId);
  }, [collectionId]);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <>
      <div>
        <form role="form" className="form-control-margin-1rem">
          <div className="col-12">
            <FormInput
              label="Name"
              fieldName="label"
              formContext={form}
              formFieldOptions={{
                required: 'This field is required.',
              }}
            />
          </div>

          <div className="col-sm-12">
            <div className="col col-lg-2 col-md-12 col-sm-12 mw24m no-padding-left">
              <CreateableSelect
                label="Timed/Untimed *"
                fieldName="timed"
                fieldWidth="long"
                formContext={form}
                isClearable={false}
                optionsLoader={() =>
                  Promise.resolve([
                    {
                      label: 'Untimed',
                      value: 'untimed',
                    },
                    {
                      label: 'Timed',
                      value: 'timed',
                    },
                  ])
                }
              />
            </div>

            <div
              className={
                showTimeInputs
                  ? 'col col-lg-3 col-md-12 col-sm-12 mw24m no-padding-left'
                  : 'undisplayed'
              }
            >
              <FormInput
                label={`Start (${timezone})`}
                fieldName="startTime"
                fieldWidth="long"
                type="datetime-local"
                formContext={form}
                formFieldOptions={{
                  required: showTimeInputs && 'This field is required.',
                }}
              />
            </div>

            <div
              className={
                showTimeInputs
                  ? 'col col-lg-3 col-md-12 col-sm-12 mw24m no-padding-left'
                  : 'undisplayed'
              }
            >
              <FormInput
                label={`End (${timezone})`}
                fieldName="endTime"
                fieldWidth="long"
                type="datetime-local"
                formContext={form}
                formFieldOptions={{
                  required: showTimeInputs && 'This field is required.',
                }}
              />
            </div>

            <div
              className={
                showTimeInputs
                  ? 'col col-lg-3 col-md-12 col-sm-12 mw24m top-margin-28 no-padding-left'
                  : 'undisplayed'
              }
            >
              <FormSwitch
                switchLabel="Display Time"
                fieldName="displayTime"
                selectedName="displayTime"
                formContext={form}
              />
            </div>
          </div>

          <div className="col col-lg-5"></div>

          <div
            className="col col-sm-12 no-padding-left"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              minHeight: 62,
            }}
          >
            <div style={{ display: 'inline-block' }}>
              <FormSwitch switchLabel="Link" fieldName="useLink" formContext={form} />
            </div>

            <div style={{ display: 'inline-block', marginLeft: 20 }}>
              <FormInput
                fieldName="actionButtonText"
                formContext={form}
                placeholder="View"
                fieldWidth="tiny"
                className="neg-top-margin"
                disabled={!linkUsed}
              />
            </div>

            {linkUsed && (
              <div className="left-margin-12">
                <LinkFinderInline
                  formContext={form}
                  label="Link Item To:"
                  fieldName="objectReference"
                  fieldWidth="short"
                  defaultValue={defaultPage}
                />
              </div>
            )}
          </div>

          <div
            className="col col-lg-12 col-md-12 col-sm-12 no-padding-left"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              minHeight: 62,
            }}
          >
            <div style={{ display: 'inline-block' }}>
              <FormSwitch switchLabel="Details Page" fieldName="useDetails" formContext={form} />
            </div>

            <div style={{ display: 'inline-block', marginLeft: 20 }}>
              <FormInput
                fieldName="infoButtonText"
                formContext={form}
                placeholder="Details"
                fieldWidth="tiny"
                className="neg-top-margin"
                disabled={!detailsUsed}
              />
            </div>
          </div>

          {watchDetails && (
            <div className="col col-lg-6 col-md-6 col-sm-6 no-padding-left">
              <FormWysiwyg
                tag="textarea"
                label=""
                formContext={form}
                fieldName="infoText"
                growlId={growlId}
              />
            </div>
          )}

          <div className="col col-lg-12 col-md-12 col-sm-12 mh320px no-padding-left">
            {imageBlock && (
              <FormImage
                label="Display Image"
                uploadButtonText="Upload"
                existingAssetIdField="tileAssetId"
                fieldName="tileAssetUpload"
                formContext={form}
                defaultAssetId="collection-item-default.png"
                useCropper={true}
                croppedRatio={325 / 200}
              />
            )}
          </div>

          <div className="col col-lg-12 col-md-12 col-sm-12 no-padding-left">
            <FormInputTextArea
              className="col col-sm-5 no-padding-left"
              placeholder="Description of collection"
              label="Description"
              fieldWidth="long"
              fieldName="description"
              formContext={form}
            />

            <div className="col col-sm-12 col-md-6  col-lg-6 no-padding-left">
              <CreateableSelect
                label="Category"
                fieldName="category"
                fieldWidth="long"
                formContext={form}
                optionsLoader={getCategories}
              />
            </div>

            <div className="col col-sm-3 mb-4">
              <AudienceSelector
                selectTextOnNewLine={true}
                className="pa-2"
                formContext={form}
                label="Available To "
                fieldName="condition"
                growlId={growlId}
                selectFieldText="Select Target Condition"
              />
            </div>
          </div>
        </form>
      </div>
      <div className="col col-lg-12 col-md-12 col-sm-12">
        <div className="row module-footer">
          <CmsPageSectionFooter actions={actions} />
        </div>
      </div>
    </>
  );
};

export default CollectionItemEditor;
