import React, { useContext, useState } from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import { CmsContext } from '../../context/definitions';

const ReportingPage = () => {
  const [selectedReport, setSelectedReport] = useState(null);
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59, 999));
  const { cmsConfig, growlProvider, confirm, getReports } = useContext(CmsContext);
  const { error } = growlProvider;
  const growlReference = 'reportingGrowl';
  const pageTitle = 'Reports';
  const iframeConfig = {};
  const reports = getReports().filter(
    (report) => !report.enabledCheck || report.enabledCheck(cmsConfig)
  );

  const generateUserReport = async () => {
    if (!selectedReport) {
      error(growlReference, 'Please select a report to generate.');
      return;
    }

    try {
      const result = await selectedReport.reportGenerator({ startDate, endDate });

      if (result) {
        if (selectedReport.isExport) {
          const confirmed = await confirm({
            title: `Export Report`,
            message: `Are you sure you want to download report: ${selectedReport.reportName}?`,
          });

          if (confirmed) {
            window.open(result.reportDownloadUrl, '_self');
          }
        } else if (selectedReport.isIframe) {
          iframeConfig.iframeUrl = result;
        }
      }
    } catch (e) {
      console.log(e);
      error(growlReference, 'Unexpected error when generating report.');
    }
  };
  return (
    <CmsPage title={pageTitle}>
      <form role="form" className="form-inline no-left-padding neg-left-margin-13">
        <select
          className="form-control margin-right"
          onChange={(e) => {
            setSelectedReport(reports.find((r) => r.reportId === e.target.value));
          }}
        >
          <option>Select</option>
          {reports
            .sort((a, b) => a.reportName.localeCompare(b.reportName))
            .map((report) => (
              <option key={report.reportId} value={report.reportId}>
                {report.reportName}
              </option>
            ))}
        </select>

        <button
          className="btn btn-primary btn-xs edit-btn"
          type="button"
          onClick={generateUserReport}
          disabled={!selectedReport}
        >
          Generate Report
        </button>
        {selectedReport && selectedReport.dateRange && (
          <div className="top-margin">
            <label className="margin-right">Start Date</label>
            <input
              className="form-control margin-right"
              type="date"
              onChange={(e) => {
                setStartDate(new Date(new Date(e.target.value).setHours(0, 0, 0, 0) + 86400000));
                console.log('start date', e.target.value, startDate);
              }}
            />
            <label className="margin-right">End Date</label>
            <input
              className="form-control margin-right"
              type="date"
              onChange={(e) => {
                setEndDate(new Date(new Date(e.target.value).setHours(23, 59, 59, 999) + 86400000));
                console.log('end date', e.target.value, endDate);
              }}
            />
          </div>
        )}
      </form>
    </CmsPage>
  );
};

export default ReportingPage;
