import { useContext, useMemo, useState } from 'react';
import { CmsContext } from '../../context/definitions';
import useItemListActions from '../../shared/cmsPage/hooks/useItemListActions';
import { TargetAudienceConstants } from '../definitions';
import targetAudienceApiWrapped from '../api/targetAudienceApi';
export default function useTargetAudienceActions(pageConstants) {
  const [includeArchived, setIncludeArchived] = useState(false);
  const targetAudienceApi = targetAudienceApiWrapped(includeArchived);
  const {
    navigator,
    growlProvider: { success, error },
    confirm,
  } = useContext(CmsContext);
  const { edit, remove, restore, publish, requestPending } = useItemListActions(
    targetAudienceApi,
    TargetAudienceConstants
  );
  const archiveCheckbox = useMemo(
    () => ({
      useSwitch: true,
      label: 'Show Archived',
      onClick: async () => void setIncludeArchived((current) => !current),
    }),
    [includeArchived, setIncludeArchived]
  );
  const { typeName, pluginId, defaultPageId, editorPageName } = pageConstants;
  const addOverride = useMemo(
    () => ({
      label: `+ Add Audience`,
      onClick: async (loaderParams) => {
        try {
          const newTargetAudience = await targetAudienceApi.loadRecord('new');
          navigator.navigateToPluginPage(
            TargetAudienceConstants.pluginId,
            TargetAudienceConstants.editorPageName,
            newTargetAudience.id || 'new'
          );
        } catch (e) {
          console.error(e);
          error(defaultPageId, 'Failed to add.');
        }
      },
    }),
    [typeName, navigator, pluginId, defaultPageId, editorPageName]
  );
  const archive = useMemo(
    () => ({
      label: (item) => (item.isArchived ? 'Unarchive' : 'Archive'),
      icon: 'fa-solid fa-box-archive',
      onClick: async (context, { reload }) => {
        try {
          if (!context.isArchived) {
            const confirmResponse = await confirm({
              title: 'Archive Target Audience',
              message: `Are you sure you want to archive ${context.name}`,
            });

            if (confirmResponse) {
              await targetAudienceApi.archiveRecord(context.id);
            }
          } else {
            const confirmResponse = await confirm({
              title: 'Unarchive Target Audience',
              message: `Are you sure you want to unarchive ${context.name}`,
            });

            if (confirmResponse) {
              await targetAudienceApi.unarchiveRecord(context.id);
            }
          }

          await reload();
          success(defaultPageId, 'Successfully updated targetAudience.');
        } catch (e) {
          console.error(e);
          error(defaultPageId, 'Failed to update targetAudience.');
        }
      },
    }),
    [typeName, navigator, pluginId, defaultPageId, editorPageName, confirm]
  );
  const duplicate = useMemo(() => {
    return {
      ...addOverride,
      label: 'Duplicate',
      icon: 'fa-solid fa-clone',
      onClick: async (context) => {
        //eslint-disable-next-line
        const { id, ...duplicateAudience } = context;
        duplicateAudience.name = `${duplicateAudience.name} (copy)`;
        duplicateAudience.condition = JSON.parse(duplicateAudience.condition);
        const newTargetAudience = await targetAudienceApi.saveRecord(duplicateAudience);
        console.log('newTargetAudience', newTargetAudience);

        try {
          const duplicatedTargetAudience = await targetAudienceApi.loadRecord(
            newTargetAudience.target_audience_id
          );
          console.log('duplicatedTargetAudience', duplicatedTargetAudience);
          navigator.navigateToPluginPage(
            TargetAudienceConstants.pluginId,
            TargetAudienceConstants.editorPageName,
            duplicatedTargetAudience.target_audience_id || 'new'
          );
        } catch (e) {
          console.error(e);
          error(defaultPageId, 'Failed to add.');
        }
      },
    };
  }, [addOverride, typeName, navigator, pluginId, defaultPageId, editorPageName]);
  return {
    add: addOverride,
    duplicate,
    archive,
    archiveCheckbox,
    edit,
    remove,
    restore,
    publish,
    requestPending,
    includeArchived,
  };
}
