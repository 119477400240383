import { useContext, useMemo } from 'react';
import { CmsContext } from '../../context/definitions';
import { CohortConstants } from '../definitions';
export default function useCohortEditorActions(props) {
  const { pluginId, item, loadItem, cohortId, parentPageTitle, showItemEditorModal } = props;
  const {
    growlProvider: { success },
    navigator,
    showImportDialog,
  } = useContext(CmsContext);
  const editSettings = useMemo(
    () => ({
      label: 'Cohort Settings',
      onClick: async () => {
        const saved = await showItemEditorModal(item);

        if (saved) {
          success(CohortConstants.editorPageId, 'Cohort settings saved successfully');
          await loadItem(cohortId);
        }
      },
      visible: true,
    }),
    [success, showItemEditorModal, item]
  );
  const back = useMemo(
    () => ({
      pageName: 'Cohorts',
      onClick: () => {
        navigator.navigateToChildPage(pluginId, 'default', 'surveys-list', {
          search: '',
          limit: 25,
          offset: 0,
          sort: {
            sortField: 'title',
            order: 'ASC',
          },
        });
      },
    }),
    [navigator, parentPageTitle, pluginId]
  );
  const importMembers = useMemo(
    () => ({
      label: 'Import Members',
      onClick: async ({ reload }) => {
        await showImportDialog({
          title: 'Import Cohort Members',
          importType: 'cohortMemberImport',
          importParams: {
            cohortId,
          },
        });
        reload();
      },
    }),
    [cohortId, showImportDialog, success]
  );
  return {
    back,
    editSettings,
    importMembers,
  };
}
