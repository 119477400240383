import React, { useMemo } from 'react';

const FormFieldError = (props) => {
  const { error } = props;
  const allMessages = useMemo(() => {
    return !!error ? Object.values(error.types) : [];
  }, [error]);
  return (
    <>
      {allMessages.map((error, index) => (
        <div key={index} className="form-error-message">
          {error}
        </div>
      ))}
    </>
  );
};

export default FormFieldError;
