import React, { useCallback, useContext } from 'react';
import { CmsContext } from '../../context/definitions';

const CollectionTitle = (props) => {
  const { collection } = props;
  const { navigator } = useContext(CmsContext);
  const navigateToChild = useCallback(() => {
    navigator.navigateToPluginPage('collections', 'collectionEditorPage', collection.id);
  }, [collection, navigator]);
  return (
    <>
      <a onClick={navigateToChild}>{collection?.name}</a>
    </>
  );
};

export default CollectionTitle;
