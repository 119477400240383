import React, { useCallback, useContext } from 'react';
import { CmsContext } from '../../../context/definitions';

const BreadcrumbLink = (props) => {
  const { pluginId, parentPageId, parentPageName, parentPageTitle, parentPageArgs, parentElement } =
    props;
  const { navigator } = useContext(CmsContext);
  const navigateToParent = useCallback(() => {
    if (parentPageArgs)
      navigator.navigateToParentPage(pluginId, parentPageName, parentPageId, ...parentPageArgs);
    else navigator.navigateToParentPage(pluginId, parentPageName, parentPageId);
  }, [navigator, parentPageId, parentPageName]);
  return (
    <div
      style={{
        marginTop: 25,
        marginLeft: -15,
        marginBottom: -15,
        cursor: 'pointer',
      }}
      className="header-link"
    >
      <a onClick={navigateToParent}>
        &lt; Back to {parentElement}
        {parentPageTitle}
      </a>
    </div>
  );
};

export default BreadcrumbLink;
