import React, { useEffect } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { CollectionConstants } from '../definitions';
import collectionApi from '../api/collectionApi';
import CollectionEditorForm from './CollectionEditorForm';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';

const CollectionSettingsPage = (props) => {
  const { collectionId } = props;
  const { item, loadItem, saveItem } = useItemPersistence(collectionApi);
  useEffect(() => {
    if (collectionId) {
      (async () => await loadItem(collectionId))();
    }
  }, [collectionId, loadItem]);
  const form = useCmsForm(item);
  const actions = useItemEditActions(saveItem, form, CollectionConstants);
  return (
    <CmsPage title={`${collectionId !== 'new' ? 'Edit' : 'Add'} Collection`} requestPending={!item}>
      {item && (
        <CollectionEditorForm
          form={form}
          growlId={CollectionConstants.editorPageName}
          actions={actions}
          collection={item}
        />
      )}
    </CmsPage>
  );
};

export default CollectionSettingsPage;
