import React, { useCallback, useEffect } from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import createConditionColumn from '../../shared/generalComponents/createConditionColumn';
import useArray from '../../shared/util/hooks/useArray';
import pushNotificationApi from '../api/pushNotificationApi';
import { PushNotificationConstants } from '../definitions';
import usePushNotificationActions from '../hooks/usePushNotificationActions';
import useRefreshActionCallback from '../../shared/cmsPage/hooks/useRefreshActionCallback';
import { getUserOptions } from '../../shared/form/components/AudienceSelector/store/thunks';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

const PushNotificationsListPage = () => {
  const { defaultPageId, typeName } = PushNotificationConstants;
  const { hiddenButton, refreshCallback } = useRefreshActionCallback(defaultPageId);
  const { add, refresh, view, copy, autoRefreshButton, autoRefresh } =
    usePushNotificationActions(PushNotificationConstants);
  const refreshFunction = useCallback(() => {
    refreshCallback();
  }, [refreshCallback]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (autoRefresh) refreshFunction();
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, [autoRefresh, refreshFunction]);
  const dispatch = useDispatch();
  const opts = useSelector((state) => _.get(state, 'audienceSelector.rootOptions', {}));
  useEffect(() => {
    if (!opts || _.isEmpty(opts)) {
      dispatch(getUserOptions());
    }
  }, [dispatch, opts]);
  const columns = [
    {
      label: 'Date/Time',
      valueField: 'sendTimestamp',
      renderer: (item) => <span>{moment(item.sendTimestamp).format('MMM DD, yyyy h:mm A')}</span>,
    },
    {
      label: 'Subject',
      valueField: 'title',
    },
    {
      label: 'Sender',
      valueField: 'sentByEmail',
      renderer: (item) => <span>{item.sentByEmail}</span>,
    },
    createConditionColumn('Target Audience', 'audienceExpr', 'fa-bullseye', opts),
    {
      label: 'Status',
      valueField: 'status',
      renderer: (item) => <span>{_.capitalize(item.status)}</span>,
    },
  ];
  const toolbarActions = useArray(add, refresh, autoRefreshButton, hiddenButton);
  const itemActions = useArray(view, copy);
  return (
    <CmsPage title={'Push Notifications'}>
      <CmsItemListSection
        pageId={defaultPageId}
        typeName={typeName}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={columns}
        itemApi={pushNotificationApi}
        showSearchBar={false}
      />
    </CmsPage>
  );
};

export default PushNotificationsListPage;
