import { useEffect, useMemo } from 'react';
import generateAchievementInstanceApi from '../api/achievementInstanceApi';
import { useDispatch, useSelector } from 'react-redux';
import { getUserOptions } from '../../shared/form/components/AudienceSelector/store/thunks';
import _ from 'lodash';
import createConditionColumn from '../../shared/generalComponents/createConditionColumn';

const AchievementInstanceColumns = (opts) => [
  {
    label: 'Name',
    valueField: 'name',
    sortable: true,
    initialSort: 'ASC',
  },
  createConditionColumn('Selected Recipients', 'recipients', 'fa-bullseye', opts),
  createConditionColumn('Selected Issuers', 'issuers', 'fa-bullseye', opts),
];

export default function useAchievementInstanceConfigurations(achievementId) {
  const dispatch = useDispatch();
  const opts = useSelector((state) => _.get(state, 'audienceSelector.rootOptions', {}));
  useEffect(() => {
    if (!opts || _.isEmpty(opts)) {
      dispatch(getUserOptions());
    }
  }, [dispatch, opts]);
  const columns = useMemo(
    () => AchievementInstanceColumns(opts),
    [AchievementInstanceColumns, opts]
  );
  const api = useMemo(
    () => generateAchievementInstanceApi(achievementId),
    [achievementId, generateAchievementInstanceApi]
  );
  return {
    columns,
    api,
  };
}
