import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CmsContext } from '../../context/definitions';
import { EMPTY_SORT } from '../../shared/itemTable/definitions';

const useMigrateMenusAction = (pageId, menuApi) => {
  const [canMigrateCheckPending, setCanMigrateCheckPending] = useState(false);
  const [migrationPending, setMigrationPending] = useState(false);
  const [canMigrate, setCanMigrate] = useState(false);
  const { loadRecords, checkCanMigrate, migrate } = menuApi;
  const { selectedSubtenant, confirm, growlProvider } = useContext(CmsContext);
  const { success, error } = growlProvider;
  useEffect(() => {
    (async () => {
      setCanMigrateCheckPending(true);
      let canMigrate = false;

      try {
        canMigrate = await checkCanMigrate();
      } catch (e) {
        console.error(e);
      } finally {
        setCanMigrateCheckPending(false);
      }

      setCanMigrate(canMigrate);
    })();
  }, [selectedSubtenant, checkCanMigrate, setCanMigrate]);
  const confirmMigrate = useCallback(async () => {
    const { numRecords } = await loadRecords({
      limit: 1,
      offset: 0,
      sort: EMPTY_SORT,
    });
    let warning;

    if (numRecords > 0) {
      warning =
        'This action will remove all menus previously created in this module and replace them with those defined in the legacy navigation manager.  This operation cannot be undone.';
    }

    return await confirm({
      title: 'Migrate Menus',
      message: 'Are you sure you want to migrate menus from the legacy menu manager?',
      warning,
    });
  }, [loadRecords]);
  const migrateMenus = useMemo(() => {
    return {
      label: 'Migrate Menus',
      visible: canMigrate,
      onClick: async ({ reload }) => {
        const confirmation = await confirmMigrate();

        if (confirmation) {
          try {
            setMigrationPending(true);
            await migrate();
            success(pageId, 'Menus have been successfully migrated.');
            reload();
          } catch (e) {
            console.error(e);
            error(pageId, 'Failed to migrate menus');
          } finally {
            setMigrationPending(false);
          }
        }
      },
    };
  }, [confirmMigrate, migrate, canMigrate, pageId, success, error, confirm]);
  return {
    migrateMenus,
    requestPending: migrationPending || canMigrateCheckPending,
  };
};

export default useMigrateMenusAction;
