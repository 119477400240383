import { useContext, useMemo } from 'react';
import { CmsContext } from '../../context/definitions';
import useLoadSubListItems from '../../shared/cmsPage/hooks/useLoadSubListItems';
import usePublishActionHandler from '../../shared/cmsPage/hooks/usePublishActionHandler';
import menuApi from '../api/menuApi';
import { MenuConstants, MenuEditorConstants } from '../definitions';
export default function useMenuEditorActions(props) {
  const {
    hasItemsPendingPublish,
    item,
    loadItem,
    loadItems,
    menuId,
    pageTitle,
    publishDisabled,
    setHasReordered,
    showItemEditorModal,
  } = props;
  const {
    growlProvider: { success },
  } = useContext(CmsContext);
  const editSettings = useMemo(
    () => ({
      label: 'Menu Settings',
      onClick: async () => {
        const saved = await showItemEditorModal(item);

        if (saved) {
          success(MenuEditorConstants.defaultPageId, 'Menu settings saved successfully');
          await loadItem(menuId);
        }
      },
      visible: true,
    }),
    [success, showItemEditorModal, item]
  );
  const reload = useLoadSubListItems(MenuEditorConstants.defaultPageId, menuId, loadItems);
  const publishHandler = usePublishActionHandler(
    MenuConstants.pluginId,
    MenuConstants.typeName,
    MenuConstants.publishPageName,
    MenuEditorConstants.defaultPageId,
    MenuEditorConstants.defaultPageName,
    pageTitle,
    MenuEditorConstants.defaultPageId,
    menuApi
  );
  const publish = useMemo(
    () => ({
      label: 'Publish',
      disabled: publishDisabled,
      onClick: async () => {
        await publishHandler(menuId, hasItemsPendingPublish, async () => {
          setHasReordered(false);
          await Promise.all([loadItem(menuId), reload()]);
        });
      },
    }),
    [
      hasItemsPendingPublish,
      publishDisabled,
      publishHandler,
      reload,
      loadItem,
      menuId,
      setHasReordered,
    ]
  );
  return {
    publish,
    editSettings,
  };
}
