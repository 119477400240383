import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import rfc from 'react-fast-compare';
import { useSelector as us } from 'react-redux';
import { defaultLogic } from '../query-builder/definitions';
import { reducerName } from './index';
import { parseRule } from './helpers';
import isPlainObject from 'lodash/isPlainObject';

const getters = (st, fieldName = undefined) => ({
  //get field state
  ...(fieldName && {
    gs: (path = '', def = undefined) =>
      get(st, `${reducerName}.${fieldName}${path ? `.${path}` : path}`, def),
    //get field logic
    gl: (path = '', def = undefined) =>
      get(st, `${reducerName}.${fieldName}.logic${path ? `.${path}` : path}`, def),
    getRuleData: (ruleLocation, defaultRule = undefined) => {
      const path = ruleLocation ? `.${ruleLocation}` : '';
      const rule = get(st, `${reducerName}.${fieldName}.logic${path}`, defaultRule);

      if (isPlainObject(rule)) {
        const rootOptions = get(st, `${reducerName}.rootOptions`, {});
        return parseRule(rule, rootOptions);
      } else {
        return undefined;
      }
    },
  }),
  //get root state
  grs: (path = '', def = undefined) => get(st, `${reducerName}${path ? `.${path}` : path}`, def),
  //get options
  go: (path = '', def = undefined) =>
    get(st, `${reducerName}.rootOptions${path ? `.${path}` : path}`, def),
});

export const selectRootOptions = () =>
  us((st) => {
    const { go } = getters(st);
    return go('', {});
  }, rfc);
export const selectPrimaryFieldProps = (fieldName, location) =>
  us((st) => {
    const { gl, go } = getters(st, fieldName);
    const field = gl(`${location}.field`);
    return {
      options: Object.keys(go('', {}))
        .sort((a, b) => a.localeCompare(b))
        .map((o) => ({
          label: o,
          value: o,
        })),
      value: field
        ? [
            {
              label: field,
              value: field,
            },
          ]
        : [],
    };
  }, rfc);
export const selectRootOptionOptionsData = (fieldName, location) =>
  us((st) => {
    const { gl, go } = getters(st, fieldName);
    const { field, data: value = [] } = gl(location, {});
    const { opts = {}, optionLabel } = go(field, {
      options: [],
      label: '',
    });
    return {
      options: sortBy(Object.values(opts), 'label'),
      label: optionLabel,
      value,
    };
  }, rfc);
export const selectAdditionalFieldsProps = (fieldName, location) =>
  us((st) => {
    const { getRuleData } = getters(st, fieldName);
    const {
      additionalFields,
      condition,
      fieldLogicType,
      hasSubOptions,
      logicParams,
      optionId,
      subOptionId,
    } = getRuleData(location, {});
    return {
      additionalFields: additionalFields.map((f) => ({
        ...f,
        ruleValue: get(logicParams, f.fieldKey, ''),
      })),
      isDisabled: !optionId || (hasSubOptions && !subOptionId) || !fieldLogicType || !condition,
    };
  }, rfc);
export const selectSubOptionOptionsData = (fieldName, location) =>
  us((st) => {
    const { getRuleData } = getters(st, fieldName);
    const {
      subOptionId,
      allowSelectAllSubOptions,
      selectAllSubOptionsLabel,
      subOptionLabel,
      subOptionData,
      subOptionsMap,
    } = getRuleData(location, {});
    if (!subOptionId)
      return {
        options: [],
        label: '',
        isDisabled: true,
        value: [],
      };
    return {
      options: [
        ...(allowSelectAllSubOptions
          ? [
              {
                label: `*${selectAllSubOptionsLabel}`,
                id: 'SELECT_ALL_SUB_OPTIONS',
                queryParams: {
                  selectAllSubOptions: true,
                },
              },
            ]
          : []),
        ...sortBy(Object.values(subOptionsMap), 'label'),
      ],
      label: subOptionLabel,
      isDisabled: !subOptionId,
      value: subOptionData || [],
    };
  }, rfc);
export const selectRuleFieldLogicData = (fieldName, location) =>
  us((st) => {
    const { getRuleData } = getters(st, fieldName);
    const ruleData = getRuleData(location);
    if (!ruleData) return {};
    const { optionId, fieldMap, subOptionId, hasSubOptions, fields } = ruleData;
    const isDisabled = !optionId || (hasSubOptions && !subOptionId);
    return {
      isDisabled,
      options: sortBy(fields, 'label'),
      value: fieldMap,
    };
  }, rfc);
export const selectRuleConditionData = (fieldName, location) =>
  us((st) => {
    const { getRuleData } = getters(st, fieldName);
    const ruleData = getRuleData(location);
    if (!ruleData)
      return {
        options: [],
        isDisabled: true,
      };
    const {
      conditions,
      selectedConditionData,
      optionId,
      hasSubOptions,
      subOptionId,
      fieldLogicType,
    } = ruleData;
    const isDisabled = !optionId || (hasSubOptions && !subOptionId) || !fieldLogicType;
    return {
      options: conditions,
      isDisabled,
      value: selectedConditionData,
    };
  }, rfc);
export const selectNewAudienceName = (fieldName) =>
  us((st) => {
    const { gs } = getters(st, fieldName);
    return gs('newAudienceName');
  }, rfc);
export const selectLogic = (fieldName) =>
  us((st) => {
    const { gl } = getters(st, fieldName);
    return gl('', {});
  }, rfc);
export const selectFromLogic = (fieldName, selectorString, getDefault = undefined) =>
  us((st) => {
    const { gl } = getters(st, fieldName);
    return gl(selectorString, getDefault);
  }, rfc);
export const selectCompoundSelectProps = (fieldName, location) =>
  us((st) => {
    const { gl } = getters(st, fieldName);
    const operator = gl(`${location ? `${location}.operator` : 'operator'}`, '');
    const rulesLength = gl(`${location ? `${location}.rules` : 'rules'}.length`, 0);
    const canSelectCompoundType = rulesLength > 1;
    return {
      operator,
      canSelectCompoundType,
    };
  }, rfc);
export const selectModalProps = (fieldName) =>
  us((st) => {
    const { gs } = getters(st, fieldName);
    const { showSave, showSaveOption, showPreview, showModal } = gs('modal', {});
    return {
      showModal,
      showNewAudience: showSave,
      showSaveNewAudience: !showSave && showSaveOption,
      showPreview: !showSave && !showSaveOption && showPreview,
      showQueryBuilder: !showSave && !showSaveOption && !showPreview,
    };
  }, rfc);
export const selectQueryBuilderFooterProps = (fieldName) =>
  us((st) => {
    const { gs } = getters(st, fieldName);
    const { logic, modal = {} } = gs('', {});
    const { buildQuery, requireNew, existingAudienceValid } = modal;
    const hasChangedLogic = rfc(logic, defaultLogic);
    return {
      buildQuery,
      existingAudienceValid,
      hasChangedLogic,
      requireNew,
    };
  }, rfc);
export const selectLogicValidationProps = (fieldName) =>
  us((st) => {
    const { gs } = getters(st, fieldName);
    const { logic, rootOptions } = gs('', {});
    return {
      logic,
      rootOptions,
    };
  }, rfc);
export const selectAudienceSelectorProps = (fieldName) =>
  us((st) => {
    const { gs, grs } = getters(st, fieldName);
    const audiences = grs('audiences', []);
    const { audienceId, modal = {} } = gs('', {});
    const { buildQuery } = modal;
    return {
      audiences,
      audienceId,
      buildQuery,
    };
  }, rfc);
export const selectUsersTableProps = (fieldName) =>
  us((st) => {
    const { gs } = getters(st, fieldName);
    const { logic, preview = {} } = gs('', {});
    const { displayedUsers, userTypeMap, isLoading, rowsToShow, pageIndex, count, searchText } =
      preview;
    return {
      count,
      displayedUsers,
      isLoading,
      logic,
      pageIndex,
      rowsToShow,
      searchText,
      userTypeMap,
    };
  }, rfc);
export const selectQueryBuilderContainerProps = (fieldName) =>
  us((st) => {
    const { gs } = getters(st, fieldName);
    const { buildQuery, requireNew } = gs('modal', {});
    return {
      buildQuery,
      requireNew,
    };
  }, rfc);
export const selectSimpleConditionProps = (fieldName, location) =>
  us((st) => {
    const { getRuleData } = getters(st, fieldName);
    const {
      field = '',
      hasQueriedPrimaryOptions,
      hasQueriedSubOptions,
      hasSubOptions,
      optionId,
      primaryOptionName,
      primaryOptionsEndpoint,
      primaryOptionsNotFound,
      primaryOptionsQueryParams,
      subOptionName,
      subOptionsEndpoint,
      subOptionsMap,
      subOptionsNotFound,
      subOptionsQueryParams,
    } = getRuleData(location, {});
    return {
      field,
      hasQueriedPrimaryOptions,
      hasQueriedSubOptions,
      hasSubOptions,
      optionId,
      primaryOptionName,
      primaryOptionsEndpoint,
      primaryOptionsNotFound,
      primaryOptionsQueryParams,
      subOptionName,
      subOptionsEndpoint,
      subOptionsMap,
      subOptionsNotFound,
      subOptionsQueryParams,
    };
  }, rfc);
