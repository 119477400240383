import React, { useCallback, useContext, useMemo } from 'react';
import { CmsContext } from '../../context/definitions';
import { isSystemMenu } from '../menuUtils';

const MenuName = (props) => {
  const { menu } = props;
  const { name } = menu;
  const isSystem = useMemo(() => isSystemMenu(menu), [menu]);
  const { navigator } = useContext(CmsContext);
  const navigateToChild = useCallback(() => {
    navigator.navigateToChildPage(
      'menu-manager',
      'menuEditorPage',
      'menu-list-page',
      null,
      menu.id
    );
  }, [navigator]);
  return (
    <>
      <a onClick={navigateToChild}>{name}</a>
      {isSystem && <span className="value-detail">(System Menu)</span>}
    </>
  );
};

export default MenuName;
