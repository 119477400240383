import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useDebounce } from 'use-debounce';
import ActionList from '../../itemTable/components/ActionList';

const CmsListSectionToolbar = (props) => {
  const { onSearchChange, actions, searchExpr, backPageAction, showSearchBar } = props;
  const [search, setSearch] = useState(searchExpr || '');
  const [debouncedSearch, { cancel }] = useDebounce(search, 500);
  useEffect(() => {
    onSearchChange(debouncedSearch);
    return cancel;
  }, [onSearchChange, debouncedSearch]);
  return (
    <>
      <div className="section-toolbar neg-left-margin-13">
        {showSearchBar !== false && (
          <span className="header-input">
            <input
              className="form-control"
              placeholder="Search"
              value={search}
              autoFocus
              onChange={(e) => setSearch(e.target.value)}
            />
          </span>
        )}
        <ActionList className={showSearchBar === false ? 'alignRight' : ''} actions={actions} />
      </div>

      {backPageAction && (
        <div
          style={{
            marginTop: '1rem',
          }}
        >
          <Button
            bsPrefix="btn-link"
            variant="link"
            onClick={backPageAction.onClick}
          >{`< Back to ${backPageAction.pageName}`}</Button>
        </div>
      )}
    </>
  );
};

export default CmsListSectionToolbar;
