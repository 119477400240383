import angular from 'angular';
import get from 'lodash/get';
import { react2angular } from 'react2angular';
import createSharedContext from 'react2angular-shared-context';
import AchievementEditorPage from './achievement/components/AchievementEditorPage';
import AchievementInstanceEditorPage from './achievement/components/AchievementInstanceEditorPage';
import AchievementInstancesPage from './achievement/components/AchievementInstancesPage';
import AchievementsListPage from './achievement/components/AchievementsListPage';
import CohortEditorPage from './cohort/components/CohortEditorPage';
import CohortListPage from './cohort/components/CohortListPage';
import CohortMatchesPage from './cohort/components/CohortMatchesPage';
import CollectionEditorPage from './collection/components/CollectionEditorPage';
import CollectionItemEditorPage from './collection/components/CollectionItemEditorPage';
import CollectionItemPublishPage from './collection/components/CollectionItemPublishPage';
import CollectionListPage from './collection/components/CollectionListPage';
import CollectionSettingsPage from './collection/components/CollectionSettingsPage';
import MeetsEditorPage from './meets/components/MeetsEditorPage';
import MeetsListPage from './meets/components/MeetsListPage';
import NavBarEditorPage from './navbar/components/NavbarEditorPage';
import NavBarItemEditorPage from './navbar/components/NavbarItemEditorPage';
import NavBarItemPublishPage from './navbar/components/NavbarItemPublishPage';
import MenuEditorPage from './menu/components/MenuEditorPage';
import MenuItemEditorPage from './menu/components/MenuItemEditorPage';
import MenuItemPublishPage from './menu/components/MenuItemPublishPage';
import MenuListPage from './menu/components/MenuListPage';
import PrivacyPage from './privacy/components/PrivacyPage';
import PushNotificationEditorPage from './push/components/PushNotificationEditorPage';
import PushNotificationListPage from './push/components/PushNotificationListPage';
import QuizInstanceEditorPage from './quizzes/components/QuizInstanceEditorPage';
import QuizInstancesPage from './quizzes/components/QuizInstancesPage';
import QuizzesListPage from './quizzes/components/QuizzesListPage';
import ReportingPage from './reporting/components/ReportingPage';
import SampleComponent from './sample/components/SampleComponent';
import ReduxStore from './store/index';
import SurveyInstanceEditorPage from './surveys/components/SurveyInstanceEditorPage';
import SurveyInstancesPage from './surveys/components/SurveyInstancesPage';
import SurveysListPage from './surveys/components/SurveysListPage';
import TargetAudienceEditorPage from './target-audience/components/TargetAudienceEditorPage';
import TargetAudienceListPage from './target-audience/components/TargetAudienceListPage';

const cmsReactContext = createSharedContext(ReduxStore);
const module = angular
  .module('react-components', [])
  .component(
    'cmsReactContext',
    react2angular(cmsReactContext.component, [
      'cmsConfig',
      'selectedTenant',
      'selectedSubtenant',
      'navigateToState',
      'showLinkSearch',
      'showAudienceModal',
      'addReport',
      'getReports',
      'showImportDialog',
      'notifyContentUpdated',
      'children',
    ])
  );

const components = [
  [
    'achievementInstanceEditorPage',
    AchievementInstanceEditorPage,
    ['achievementId', 'achievementInstanceId'],
  ],
  ['achievementEditorPage', AchievementEditorPage, ['achievementId']],
  ['achievementInstancesPage', AchievementInstancesPage, ['achievementId']],
  ['achievementsListPage', AchievementsListPage],
  ['collectionEditorPage', CollectionEditorPage, ['collectionId']],
  ['collectionItemEditorPage', CollectionItemEditorPage, ['collectionId', 'itemId']],
  ['collectionListPage', CollectionListPage],
  ['collectionSettingsPage', CollectionSettingsPage, ['collectionId']],
  [
    'collectionItemPublishPage',
    CollectionItemPublishPage,
    ['collectionId', 'successGrowlId', 'returnPageId', 'returnPageName', 'returnPageLabel'],
  ],
  ['cohortListPage', CohortListPage],
  ['cohortEditorPage', CohortEditorPage, ['cohortId']],
  ['cohortMatchesPage', CohortMatchesPage, ['cohortId', 'credentialCode']],
  ['meetsEditorPage', MeetsEditorPage, ['meetId', 'meetType']],
  ['meetsListPage', MeetsListPage, ['meetType']],
  ['menuEditorPage', MenuEditorPage, ['menuId']],
  ['menuItemEditorPage', MenuItemEditorPage, ['menuId', 'itemId']],
  [
    'menuItemPublishPage',
    MenuItemPublishPage,
    ['menuId', 'successGrowlId', 'returnPageId', 'returnPageName', 'returnPageLabel'],
  ],
  ['menuListPage', MenuListPage],
  ['navbarEditorPage', NavBarEditorPage, ['menuId']],
  ['navbarItemEditorPage', NavBarItemEditorPage, ['menuId', 'itemId']],
  [
    'navbarItemPublishPage',
    NavBarItemPublishPage,
    ['menuId', 'successGrowlId', 'returnPageId', 'returnPageName', 'returnPageLabel'],
  ],
  ['pushNotificationListPage', PushNotificationListPage],
  ['pushNotificationEditorPage', PushNotificationEditorPage, ['pushNotificationId', 'allowEdit']],
  ['quizInstanceEditorPage', QuizInstanceEditorPage, ['quizId', 'quizInstanceId']],
  ['quizInstancesPage', QuizInstancesPage, ['quizId']],
  ['quizzesListPage', QuizzesListPage],
  ['reportingPage', ReportingPage],
  ['sampleComponent', SampleComponent],
  ['surveyInstanceEditorPage', SurveyInstanceEditorPage, ['surveyId', 'surveyInstanceId']],
  ['surveyInstancesPage', SurveyInstancesPage, ['surveyId']],
  ['surveysListPage', SurveysListPage],
  ['targetAudienceListPage', TargetAudienceListPage],
  ['targetAudienceEditorPage', TargetAudienceEditorPage, ['targetAudienceId']],
  ['privacyPage', PrivacyPage, []],
];

components.forEach((componentConfig) => {
  const pageName = get(componentConfig, '[0]');
  const PageComponent = get(componentConfig, '[1]', componentConfig);
  const pageParams = get(componentConfig, '[2]', []);
  module.component(pageName, react2angular(cmsReactContext.use(PageComponent), pageParams));
});
