import React, { useContext, useCallback } from 'react';
import { CmsContext } from '../../../context/definitions';

const ItemPageLink = (props) => {
  const { linkText, pluginId, defaultPageName, params } = props;
  const { navigator } = useContext(CmsContext);
  const navigateToPage = useCallback(() => {
    navigator.navigateToPluginPage(pluginId, defaultPageName, params);
  }, [navigator, pluginId, defaultPageName, params]);
  return <a onClick={navigateToPage}>{linkText}</a>;
};

export default ItemPageLink;
