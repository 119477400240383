import React, { useState, useEffect } from 'react';
import CmsItemEditSection from '../../shared/cmsPage/components/CmsItemEditSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import { useEditPageTitle } from '../../shared/cmsPage/hooks/useEditPageTitle';
import { AchievementInstanceConstants, EMPTY_ACHIEVEMENT } from '../definitions';
import AchievementInstanceEditor from './AchievementInstanceEditorForm';
import mainApi from '../api/achievementsApi';

const AchievementInstanceEditorPage = (props) => {
  const { achievementId, achievementInstanceId } = props;
  const [main, setMain] = useState(EMPTY_ACHIEVEMENT);
  const { editorPageId, typeName } = AchievementInstanceConstants;
  const { pageTitle, onItemChange } = useEditPageTitle(achievementInstanceId, typeName, 'name');
  useEffect(() => {
    if (achievementId) {
      (async () => {
        setMain(await mainApi(true).loadRecord(achievementId));
      })();
    }
  }, [achievementId]);
  return (
    <CmsPage title={`${pageTitle}${main ? ` of '${main.name}'` : ''}`}>
      <CmsItemEditSection pageId={editorPageId}>
        <AchievementInstanceEditor
          itemId={achievementInstanceId}
          achievement={main}
          onItemChange={onItemChange}
          growlId={AchievementInstanceConstants.editorPageName}
        />
      </CmsItemEditSection>
    </CmsPage>
  );
};

export default AchievementInstanceEditorPage;
