import React, { useCallback, useContext } from 'react';
import { CmsContext } from '../../context/definitions';

const MenuItemTitle = (props) => {
  const { menuItem, menuId } = props;
  const { label } = menuItem;
  const { navigator } = useContext(CmsContext);
  const navigateToChild = useCallback(() => {
    navigator.navigateToPluginPage('menu-manager', 'menuItemEditorPage', menuId, menuItem.id);
  }, [navigator]);
  return (
    <>
      <a onClick={navigateToChild}>{label}</a>
    </>
  );
};

export default MenuItemTitle;
