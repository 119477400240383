import axios from 'axios';
const baseUrl = '/plugins/quizzes/api';

const loadRecordsWrapper = (quizId) => async (params) => {
  const {
    sort: { sortField, order },
    ...otherParams
  } = params;
  let result;

  try {
    const { data } = await axios.get(`${baseUrl}/getQuizInstances`, {
      params: {
        ...otherParams,
        quizId,
        sortField,
        sortReverse: sortField ? order === 'DESC' : undefined,
      },
      timeout: 10000,
    });
    const { quizInstances, count, offset } = data;
    result = {
      records: quizInstances,
      numRecords: count,
      offset,
    };
  } catch (e) {
    console.error(e);
    throw new Error('Failed to load quizs');
  }

  return result;
};

const loadRecord = async (id) => {
  if (id === 'new') {
    return {
      name: '',
    };
  } else {
    try {
      const {
        data: { quizInstance },
      } = await axios.get(`${baseUrl}/getQuizInstance`, {
        params: {
          id,
        },
        timeout: 10000,
      });
      return quizInstance;
    } catch (e) {
      console.error(e);
      throw new Error('Failed to load quizInstance.');
    }
  }
};

const removeRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(`${baseUrl}/removeQuizInstance`, {
      id,
      timeout: 10000,
    });
  } catch (e) {
    console.error(e);
    throw new Error('Failed to remove quiz.');
  }
};

const saveRecordWrapper = (quizId) => async (item) => {
  try {
    const {
      data: { quizInstance },
    } = await axios.post(`${baseUrl}/saveQuizInstance`, { ...item, quizId, timeout: 10000 });
    return quizInstance;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to save quiz.');
  }
};

const generateQuizInstanceApi = (quizId) => {
  const quizInstanceApi = {
    loadRecords: loadRecordsWrapper(quizId),
    loadRecord,
    removeRecord,
    saveRecord: saveRecordWrapper(quizId),
  };
  return quizInstanceApi;
};

export default generateQuizInstanceApi;
