import { EMPTY_PUSH_NOTIFICATION } from '../definitions';
import axios from 'axios';
const baseUrl = '/push';
import moment from 'moment';

const loadRecords = async (params) => {
  const { sort, ...otherParams } = params;
  const { sortField, order } = sort;
  const sortReverse = sortField ? order === 'DESC' : undefined;
  let result;

  try {
    const { data } = await axios.get(`${baseUrl}/getPushNotifications`, {
      params: { ...otherParams, sortField, sortReverse },
      timeout: 10000,
    });
    const { notifications, count, offset } = data;
    result = {
      records: notifications,
      numRecords: count,
      offset,
    };
  } catch (e) {
    console.error(e);
    throw new Error('Failed to load pushNotifications');
  }

  return result;
};

const loadRecord = async (id) => {
  if (id === 'new') {
    return EMPTY_PUSH_NOTIFICATION;
  } else {
    try {
      const { data } = await axios.get(`${baseUrl}/getPushNotification`, {
        params: {
          id,
        },
        timeout: 10000,
      });
      let parsedCondition;

      try {
        parsedCondition = data && data.notification && JSON.parse(data.notification.audienceExpr);
        if (parsedCondition) data.notification.audienceExpr = parsedCondition;
      } catch (e) {
        parsedCondition = '';
      }

      try {
        const localTime = moment(data.notification.sendTimestamp).format(
          moment.HTML5_FMT.DATETIME_LOCAL
        );
        data.notification.sendTimestamp = localTime;
      } catch (e) {
        console.log('could not parse sendTimestamp');
      }

      return data.notification;
    } catch (e) {
      console.error(e);
      throw new Error('Failed to load pushNotification.');
    }
  }
};

const saveRecord = async (item) => {
  try {
    const {
      audienceExpr: condition,
      ttl: ttlMinutes,
      title,
      message,
      url,
      linkText,
      sendDate: sendTimestamp,
      scheduleType,
    } = item;
    const audienceExpr = condition || {};
    const httpParams = {
      audienceExpr,
      ttlMinutes,
      title,
      message,
      url,
      linkText,
      sendTimestamp,
      scheduleType,
    };

    const { data } = await axios.post(`${baseUrl}/schedulePushNotification`, httpParams, {
      timeout: 10000,
    });
    return data.results;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to save pushNotification.');
  }
};

const archiveRecord = async (id) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/updatePushNotificationOptions`,
      {
        isArchived: true,
        id,
      },
      {
        timeout: 10000,
      }
    );
    return data.pushNotification;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to save pushNotification.');
  }
};

const unarchiveRecord = async (id) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/updatePushNotificationOptions`,
      {
        isArchived: false,
        id,
      },
      {
        timeout: 10000,
      }
    );
    return data.pushNotification;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to save pushNotification.');
  }
};

const removeRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(
      `${baseUrl}/removePushNotification`,
      {
        id,
      },
      {
        timeout: 10000,
      }
    );
  } catch (e) {
    console.error(e);
    throw new Error('Failed to remove pushNotification.');
  }
};

const restoreRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(
      `${baseUrl}/restorePushNotification`,
      {
        id,
      },
      {
        timeout: 10000,
      }
    );
  } catch (e) {
    console.error(e);
    throw new Error('Failed to restore pushNotification.');
  }
};

const publishRecord = async (id) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/publishPushNotification`,
      {
        id,
      },
      {
        timeout: 10000,
      }
    );
    return data.pushNotification;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to publish pushNotification.');
  }
};

const pushNotificationApi = {
  loadRecords,
  loadRecord,
  saveRecord,
  removeRecord,
  restoreRecord,
  publishRecord,
  archiveRecord,
  unarchiveRecord,
};
export default pushNotificationApi;
