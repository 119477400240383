import { savePageState, setRestoreOnTransition } from '../shared/cmsPage/cmsPageUtils';
export class CmsNavigator {
  constructor(navigateToState) {
    this.navigateToState = navigateToState;
  }

  navigateToParentPage(pluginName, pageName, parentPageId, ...args) {
    setRestoreOnTransition(parentPageId);
    this.navigateToPluginPage(pluginName, pageName, ...args);
  }

  navigateToChildPage(pluginName, pageName, parentPageId, savedParams, ...args) {
    savePageState(parentPageId, savedParams);
    this.navigateToPluginPage(pluginName, pageName, ...args);
  }

  navigateToPluginPage(pluginName, pageName, ...args) {
    this.navigateToState('main.plugin', {
      pluginName,
      pageName,
      params: args && args.join(','),
    });
  }
}
