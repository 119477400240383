import React, { useState } from 'react';
import Switch from 'react-switch';

const ActionSwitch = (props) => {
  const { label, disabled, onClick } = props;
  const [checked, setChecked] = useState(true);

  const handleChange = (e) => {
    setChecked(e);
    if (typeof onClick === 'function') onClick(e);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginRight: '10px',
      }}
    >
      <Switch
        disabled={disabled}
        onChange={handleChange}
        checked={checked}
        onColor="#ACC0D0"
        onHandleColor="#245885"
        height={14}
        width={32}
        handleDiameter={20}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      />
      <label>
        <div
          style={{
            display: 'flex',
          }}
        >
          <span
            style={{
              marginLeft: '8px',
              marginTop: '4px',
            }}
          >
            {label}
          </span>
        </div>
      </label>
    </div>
  );
};

export default ActionSwitch;
