import { useContext, useMemo, useState } from 'react';
import { CmsContext } from '../../context/definitions';
import useItemListActions from '../../shared/cmsPage/hooks/useItemListActions';
import snapshotStable from '../../shared/util/snapshotStable';
import collectionApi from '../api/collectionApi';
import collectionItemApi from '../api/collectionItemApi';
import omit from 'lodash/omit';
import { CollectionConstants } from '../definitions';

const useCollectionListActions = (pageConstants, showCollectionEditorModal) => {
  const [isDuplicating, setIsDuplicating] = useState(false);
  const { typeName, pluginId, defaultPageId } = pageConstants;
  const { navigator, growlProvider } = useContext(CmsContext);
  const { error } = growlProvider;
  const { add, edit, remove, restore, publish, requestPending } = useItemListActions(
    collectionApi,
    CollectionConstants
  );
  const addOverride = useMemo(() => {
    return {
      ...add,
      onClick: async ({ loaderParams }) => {
        const newCollection = await collectionApi.loadRecord('new');
        navigator.navigateToPluginPage(
          CollectionConstants.pluginId,
          CollectionConstants.settingsPageName,
          // CollectionConstants.defaultPageId,
          // loaderParams,
          newCollection.id || 'new'
        );
      },
    };
  }, [typeName, navigator, pluginId, defaultPageId]);

  const duplicate = useMemo(() => {
    return {
      ...add,
      label: 'Duplicate',
      icon: 'fa-solid fa-clone',
      onClick: async (context) => {
        setIsDuplicating(true); //eslint-disable-next-line
        const baseCollection = await collectionApi.loadRecord(context.id);
        const duplicateCollection = omit(baseCollection, [
          'id',
          'Collection',
          'SnapshotCollectionRevisions',
          'collection_id',
          'status',
        ]);
        duplicateCollection.name = `${duplicateCollection.name} (copy)`;
        const newCollection = await collectionApi.loadRecord('new');
        const savedItem = await collectionApi.saveRecord({
          ...duplicateCollection,
          id: newCollection.id,
        });

        try {
          if (savedItem) {
            //duplicate the collection items
            const baseCollectionItems = await collectionItemApi.loadRecords(context.id);
            baseCollectionItems.forEach(async (item) => {
              //eslint-disable-next-line
              const { id, ...duplicateItem } = item;
              duplicateItem.collection_id = savedItem.collection_id;
              await collectionItemApi.saveRecord(duplicateItem);
            }); //delay to allow the collection items to save

            let stable = await snapshotStable();
            const maxTries = 60;
            let tries = 0;

            while (!stable && tries < maxTries) {
              await new Promise((r) => setTimeout(r, 500));
              stable = await snapshotStable();
              tries++;
            }
          }
        } catch (e) {
          error(CollectionConstants.defaultPageId, e.message);
        } finally {
          savedItem &&
            navigator.navigateToChildPage(
              CollectionConstants.pluginId,
              CollectionConstants.editorPageName,
              CollectionConstants.defaultPageId,
              {
                search: '',
                limit: 25,
                offset: 0,
                sort: {
                  sortField: 'title',
                  order: 'ASC',
                },
              },
              savedItem.collection_id
            );
        }

        setIsDuplicating(false);
      },
    };
  }, [add]);
  return useMemo(
    () => ({
      add: addOverride,
      edit,
      remove,
      restore,
      publish,
      requestPending,
      duplicate,
      isDuplicating,
    }),
    [addOverride, edit, remove, restore, publish, requestPending, isDuplicating]
  );
};

export default useCollectionListActions;
