import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { resetLogic, setLocation, setSavedAudience } from '../../../store';
import { selectAudienceSelectorProps } from '../../../store/selectors';

const SetAudienceSelector = ({ fieldName }) => {
  const dispatch = useDispatch();
  const { audiences, audienceId, buildQuery } = selectAudienceSelectorProps(fieldName);
  const selectAudience = useCallback(
    (e) => {
      dispatch(
        setSavedAudience({
          fieldName,
          audienceId: e.target.value,
          audienceIndex: e.target.selectedIndex ? e.target.selectedIndex - 1 : undefined,
        })
      );
    },
    [dispatch, fieldName]
  );
  const selectExistingAudience = useCallback(
    (value) => {
      dispatch(
        setLocation({
          location: 'modal.buildQuery',
          value: false,
          fieldName,
        })
      );
    },
    [dispatch, fieldName]
  );
  const createNewAudience = useCallback(
    (value) => {
      dispatch(
        resetLogic({
          fieldName,
        })
      );
      dispatch(
        setLocation({
          location: 'modal.buildQuery',
          value: true,
          fieldName,
        })
      );
    },
    [dispatch, fieldName]
  );
  return (
    <div>
      <form role="form" className="form-group">
        <div className="row flex-wrapper">
          <label className="flex-shrink-0 p-0">
            <input
              checked={!buildQuery}
              name="audienceType"
              type={'radio'}
              onChange={selectExistingAudience}
            />
            Select an <strong>Existing</strong> Audience
          </label>
          <select
            disabled={buildQuery}
            className="form-control flex-shrink-1 input-short"
            value={audienceId || ''}
            onChange={selectAudience}
          >
            <option key="0" value="0">
              Select
            </option>
            {audiences.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </select>
        </div>
        <label>
          <input
            checked={buildQuery}
            name="audienceType"
            type={'radio'}
            onChange={createNewAudience}
          />
          Create a <strong>New</strong> Audience
        </label>
      </form>
    </div>
  );
};

export default SetAudienceSelector;
