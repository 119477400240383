import React from 'react';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import AudienceSelector from '../../shared/form/components/AudienceSelector';
import FormInput from '../../shared/form/components/FormInput';
import FormRadioButton from '../../shared/form/components/FormRadioButton';
import FormTextarea from '../../shared/form/components/FormTextarea';
import LinkFinder from '../../shared/form/components/LinkFinder';

const PushNotificationEditorForm = (props) => {
  const { form, growlId, actions, viewOnly } = props;
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  //
  var hh = today.getHours();
  var m = today.getMinutes();

  if (dd < 10) {
    dd = '0' + dd;
  }

  if (mm < 10) {
    mm = '0' + mm;
  }

  today = yyyy + '-' + mm + '-' + dd + 'T' + hh + ':' + m;
  return (
    <div className="row">
      <form role="form" className="no-left-padding">
        <FormInput
          disabled={viewOnly}
          label="Time (in minutes) the notification is available to be received"
          fieldName="ttl"
          formContext={form}
          formFieldOptions={{
            required: 'This field is required.',
          }}
        />

        <AudienceSelector
          disabled={viewOnly}
          formContext={form}
          label="Send Notification To"
          fieldName="audienceExpr"
          growlId={growlId}
          isRequired={false}
        />

        <label>Send Notification *</label>
        <div className="radios">
          <FormRadioButton
            disabled={viewOnly}
            id="immediate"
            key="immediate"
            label={
              <span>
                {'Immediately'} <span className="form-input-description"></span>
              </span>
            }
            fieldName={`scheduleType`}
            formContext={form}
            type="radio"
            forceCheck={false}
            preChecked={!viewOnly}
            value="now"
          />
          <FormRadioButton
            disabled={viewOnly}
            forceCheck={viewOnly}
            id="later"
            key="later"
            label={
              <div className="form-checkbox-row">
                <label style={{ marginRight: 15 }}>Later</label>
                <FormInput
                  disabled={viewOnly}
                  label=""
                  fieldWidth="short"
                  type="datetime-local"
                  fieldName="sendTimestamp"
                  formContext={form}
                  min={today}
                />
              </div>
            }
            fieldName={`scheduleType`}
            formContext={form}
            type="radio"
            preChecked={viewOnly}
            value="later"
          />
        </div>

        <FormInput
          disabled={viewOnly}
          label="Notification Subject"
          fieldName="title"
          formContext={form}
          formFieldOptions={{
            required: 'This field is required.',
          }}
        />
        <FormTextarea
          disabled={viewOnly}
          label="Text for Notification"
          maxLength={1024}
          fieldName="message"
          formContext={form}
          formFieldOptions={{
            required: 'This field is required.',
          }}
        />
        <FormInput
          disabled={viewOnly}
          label="Notification Target Url Link Text"
          fieldName="linkText"
          formContext={form}
        />

        <LinkFinder
          disabled={viewOnly}
          defaultValue=""
          formContext={form}
          label="Notification Target Url"
          fieldName="url"
          allowEmbeddedMenus={true}
          required={false}
        />
      </form>
      <div className="row module-footer">
        <CmsPageSectionFooter actions={actions} />
      </div>
    </div>
  );
};

export default PushNotificationEditorForm;
