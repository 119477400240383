import { useCallback, useMemo, useState } from 'react';
import useSavedPageState from '../../cmsPage/hooks/useSavedPageState';
import { getRestoreOnTransition } from '../../cmsPage/cmsPageUtils';
import { findDefaultSort } from '../../itemTable/itemTableUtils';
export const useListLoaderParams = (pageId, pageSize = 25, columns) => {
  const savedLoaderParams = useSavedPageState(pageId);
  const restoreState = useMemo(() => {
    return getRestoreOnTransition(pageId);
  }, []);
  const defaultSort = useMemo(() => findDefaultSort(columns), [columns, findDefaultSort]);
  const defaultLoaderParams = useMemo(() => {
    let result;

    if (restoreState) {
      result = savedLoaderParams;
    }

    return result;
  }, [restoreState, savedLoaderParams]);
  const [loaderParams, setLoaderParams] = useState(
    defaultLoaderParams || {
      limit: pageSize,
      offset: 0,
      search: '',
      sort: defaultSort,
    }
  );
  const onSearchChange = useCallback(
    (search) => {
      if (search !== loaderParams.search) {
        setLoaderParams({ ...loaderParams, search, offset: 0 });
      }
    },
    [loaderParams, setLoaderParams]
  );
  const onPageSelected = useCallback(
    (page) => {
      const currentPage = loaderParams.offset / loaderParams.limit;

      if (page !== currentPage + 1) {
        const offset = (page - 1) * loaderParams.limit;
        setLoaderParams({ ...loaderParams, offset });
      }
    },
    [loaderParams, setLoaderParams]
  );
  const onSortChange = useCallback(
    (sort) => {
      if (sort !== loaderParams.sort) {
        setLoaderParams({ ...loaderParams, offset: 0, sort });
      }
    },
    [loaderParams, setLoaderParams]
  );
  return useMemo(() => {
    return {
      loaderParams,
      onSearchChange,
      onPageSelected,
      onSortChange,
    };
  }, [loaderParams, onSearchChange, onPageSelected, onSortChange]);
};
