import React from 'react';
import classNames from 'classnames';

const defaultColumnRenderer = (item, field) => {
  const value = item[field];
  return <>{value}</>;
};

const ItemTableCell = (props) => {
  const { item, column } = props;
  const { renderer, valueField, className: columnClassName } = column;
  const columnRenderer = renderer || defaultColumnRenderer;
  const align = column.align || 'left';
  return (
    <td className={classNames('table-cell', align, columnClassName)}>
      {columnRenderer(item, valueField)}
    </td>
  );
};

export default ItemTableCell;
