import createPageConstants from '../shared/util/createPageConstants';
const pluginId = 'collections';
export const CollectionConstants = createPageConstants(pluginId, 'Collection', {
  defaultDefault: 'collectionListPage',
  editor: 'collectionEditorPage',
  publish: 'collectionItemPublishPage',
  settings: 'collectionSettingsPage',
});
export const CollectionEditorConstants = createPageConstants(pluginId, 'Collection Item', {
  default: 'collectionEditorPage',
  editor: 'collectionItemEditorPage',
  parentDefault: 'collectionListPage',
  publish: 'collectionItemPublishPage',
});
export const CollectionPublishPageConstants = createPageConstants(pluginId, 'Collection', {
  default: 'collectionItemPublishPage',
});
export const EMPTY_COLLECTION_REVISION = {
  name: '',
  label: '',
  defaultView: 'grid',
  useImage: true,
  type: 'standard',
  untimedHeader: 'On Demand',
  displayToolbar: false,
  toolSearch: false,
  toolFavorite: false,
  toolViewSelect: true,
  filterLive: false,
  filterCategory: false,
  filterDate: false,
  filterPast: false,
};
export const EMPTY_COLLECTION_ITEM_REVISION = {
  label: '',
  objectReference: 'cms://default',
};
