import { useContext, useMemo } from 'react';
import { CmsContext } from '../../context/definitions';
import useSubItemListActions from '../../shared/cmsPage/hooks/useSubItemListActions';
import collectionItemApi from '../api/collectionItemApi';
import { CollectionEditorConstants } from '../definitions'; //eslint-disable-next-line
//@ts-ignore

export default function useCollectionEditorItemActions(collectionId) {
  const { navigator } = useContext(CmsContext);
  const { typeName, pluginId, editorPageName } = CollectionEditorConstants;
  const add = useMemo(
    () => ({
      label: `+ Add ${typeName}`,
      onClick: () => {
        navigator.navigateToPluginPage(pluginId, editorPageName, collectionId, 'new');
      },
    }),
    [typeName, navigator, pluginId, editorPageName, collectionId]
  );
  const { edit, remove, restore, requestPending } = useSubItemListActions(
    collectionItemApi,
    collectionId,
    CollectionEditorConstants
  );
  return {
    add,
    edit,
    remove,
    restore,
    requestPending,
  };
}
