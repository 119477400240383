import createPageConstants from '../shared/util/createPageConstants';
const pluginId = 'menu-navbar';
export const MenuConstants = createPageConstants(
  pluginId,
  'NavBar',
  {
    defaultDefault: 'navbarListPage',
    editor: 'navbarEditorPage',
    publish: 'navbarItemPublishPage',
  },
  'Menus'
);
export const MenuEditorConstants = createPageConstants(pluginId, 'Menu Item', {
  default: 'navbarEditorPage',
  editor: 'navbarItemEditorPage',
  parentDefault: 'navbarListPage',
  publish: 'navbarItemPublishPage',
});
export const MenuPublishPageConstants = createPageConstants(pluginId, 'Menu', {
  default: 'navbarItemPublishPage',
});
export const EMPTY_MENU_REVISION = {
  name: '',
  label: '',
};
export const EMPTY_MENU_ITEM_REVISION = {
  label: '',
};
