import axios from 'axios';
import { EMPTY_ACHIEVEMENT } from '../definitions';
const baseUrl = '/plugins/achievements/api';
export const updateAchievementOptions = async (id, achievementOptions) => {
  try {
    await axios.post(`${baseUrl}/updateAchievementOptions`, {
      ...achievementOptions,
      id,
      timeout: 10000,
    });
  } catch (e) {
    console.error(e);
    throw new Error('Failed to remove achievement.');
  }
};

const loadRecordsWrapper =
  (includeArchived = false) =>
  async (params) => {
    const {
      sort: { sortField, order },
      ...otherParams
    } = params;
    let result;

    try {
      const {
        data: { achievements, count, offset },
      } = await axios.get(`${baseUrl}/getAchievements`, {
        params: {
          ...otherParams,
          includeArchived,
          sortField,
          sortReverse: sortField ? order === 'DESC' : undefined,
        },
        timeout: 10000,
      });
      result = {
        records: achievements,
        numRecords: count,
        offset,
      };
    } catch (e) {
      console.error(e);
      throw new Error('Failed to load achievements');
    }

    return result;
  };

export const saveInstance = async (achievementId, name) => {
  try {
    const {
      data: { achievementInstance },
    } = await axios.post(`${baseUrl}/saveInstance`, {
      params: {
        achievementId,
        name,
      },
      timeout: 10000,
    });
    return achievementInstance;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to load achievement.');
  }
};
export const getServiceUrls = async () => {
  try {
    const {
      data: { url },
    } = await axios.get(`${baseUrl}/getServiceUrls`, {});
    return url;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to get cms achievement url');
  }
};

const loadRecord = async (id) => {
  if (id === 'new') {
    return EMPTY_ACHIEVEMENT;
  } else {
    try {
      const { data } = await axios.get(`${baseUrl}/getAchievement`, {
        params: {
          id,
        },
        timeout: 10000,
      });
      let parsedInclude;

      try {
        parsedInclude =
          data &&
          data.achievement &&
          data.achievement.included &&
          data.achievement.included.split(',').reduce((acc, v) => {
            acc[v] = true;
            return acc;
          }, {});
        if (parsedInclude) data.achievement.included = parsedInclude;
        else data.achievement.included = {};
      } catch (e) {
        parsedInclude = {};
      }

      return data.achievement;
    } catch (e) {
      console.error(e);
      throw new Error('Failed to load achievement.');
    }
  }
};

const saveRecord = async (item) => {
  try {
    //eslint-disable-next-line
    const { id, template, ...revisionProps } = item;
    const servicePayload = {
      certificateId: item.template,
      displayName: item.name,
      fields: Object.keys(item.included),
    };
    const { api: serviceUrl } = await getServiceUrls();
    const serviceAchievement = await axios.post(`${serviceUrl}/achievements`, servicePayload, {
      timeout: 10000,
    });
    revisionProps.template = serviceAchievement.data.achievement.id;
    const { data } = await axios.post(
      `${baseUrl}/saveAchievement`,
      { ...revisionProps, id },
      {
        timeout: 10000,
      }
    );
    return data.achievement;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to save achievement.');
  }
};

const archiveRecord = async (id) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/updateAchievementOptions`,
      {
        isArchived: true,
        id,
      },
      {
        timeout: 10000,
      }
    );
    return data.achievement;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to save achievement.');
  }
};

const unarchiveRecord = async (id) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/updateAchievementOptions`,
      {
        isArchived: false,
        id,
      },
      {
        timeout: 10000,
      }
    );
    return data.achievement;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to save achievement.');
  }
};

const achievementsApiWrapped = (includeArchived) => {
  const achievementsApi = {
    loadRecords: loadRecordsWrapper(includeArchived),
    saveInstance,
    loadRecord,
    saveRecord,
    archiveRecord,
    unarchiveRecord,
    updateAchievementOptions,
  };
  return achievementsApi;
};

export default achievementsApiWrapped;
