import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { selectQueryBuilderContainerProps } from '../../../store/selectors';
import { getAudienceRecords } from '../../../store/thunks';
import QueryBuilderSection from '../query-builder-section';
import SetAudienceSelector from './SetAudienceSelector';

const QueryBuilderContainer = ({ fieldName }) => {
  const dispatch = useDispatch();
  const { requireNew, buildQuery } = selectQueryBuilderContainerProps(fieldName);
  useEffect(() => {
    if (!buildQuery && !requireNew) dispatch(getAudienceRecords(fieldName));
  }, [buildQuery, requireNew, fieldName]);
  return (
    <div className="query-builder-container">
      {!requireNew && <SetAudienceSelector fieldName={fieldName} />}
      {(buildQuery || requireNew) && (
        <>
          <hr />
          <QueryBuilderSection fieldName={fieldName} location={''} />
        </>
      )}
    </div>
  );
};

export default QueryBuilderContainer;
