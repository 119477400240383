import { useMemo } from 'react';
export const useVisiblePages = (pageCount, currentPage, maxVisible) => {
  return useMemo(() => {
    const result = [];

    if (pageCount > 1) {
      const visiblePages = Math.min(pageCount, maxVisible);
      let start = 1;
      let end = visiblePages;

      if (currentPage > visiblePages) {
        start = currentPage - (visiblePages - 1);
        end = currentPage;
      }

      for (let i = start; i <= end; i++) {
        result.push(i);
      }
    }

    return result;
  }, [pageCount, currentPage, maxVisible]);
};
