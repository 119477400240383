import React, { useEffect } from 'react';
import Switch from 'react-switch'; // import { load } from 'mime';

const FormSwitchControlled = (props) => {
  const { fieldName, formContext, switchLabel, updateParent, fontWeight, checked, disabled } =
    props;

  useEffect(() => {
    formContext.setValue(fieldName, checked);
  }, [checked, fieldName]);

  const handleChange = (e) => {
    formContext.setValue(fieldName, e, {
      shouldDirty: true,
    });
    if (updateParent) updateParent(e);
  };
  formContext.register(fieldName, { value: checked });

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
      }}
    >
      <Switch
        onChange={handleChange}
        checked={Boolean(checked)}
        onColor="#ACC0D0"
        onHandleColor="#245885"
        height={14}
        width={32}
        handleDiameter={20}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        disabled={disabled}
      />
      <label>
        <div style={{ display: 'flex' }}>
          <span
            style={{
              marginLeft: '8px',
              marginTop: '4px',
              fontWeight: fontWeight,
            }}
          >
            {switchLabel}
          </span>
        </div>
      </label>
    </div>
  );
};

export default FormSwitchControlled;
