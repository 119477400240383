import React, { useState, useEffect } from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsItemEditSection from '../../shared/cmsPage/components/CmsItemEditSection';
import { useEditPageTitle } from '../../shared/cmsPage/hooks/useEditPageTitle';
import { CollectionEditorConstants } from '../definitions';
import CollectionItemEditor from './CollectionItemEditor';
import BreadcrumbLink from '../../shared/actionButton/components/BreadcrumbLink';
import collectionItemApi from '../api/collectionItemApi';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';

const CollectionItemEditorPage = (props) => {
  const { editorPageId, pluginId } = CollectionEditorConstants;
  const { collectionId, itemId } = props;
  const { pageTitle, onItemChange } = useEditPageTitle(itemId, 'Collection Element', 'label');
  const [parentPageTitle, setParentPageTitle] = useState('');
  const { item, loadItem } = useItemPersistence(collectionItemApi);

  useEffect(() => {
    if (itemId) {
      (async () => await loadItem(itemId))();
    }
  }, [itemId, loadItem]);

  return (
    <>
      <BreadcrumbLink
        pluginId={pluginId}
        parentPageTitle={parentPageTitle}
        parentPageName={'collectionEditorPage'}
        parentPageId={'collection-editor-page'}
        parentPageArgs={[collectionId]}
      />
      <CmsPage title={`${pageTitle} for ${parentPageTitle}`}>
        <CmsItemEditSection pageId={editorPageId}>
          {item && (
            <CollectionItemEditor
              collectionId={collectionId}
              itemId={itemId}
              onItemChange={onItemChange}
              growlId={editorPageId}
              changeTitle={setParentPageTitle}
            />
          )}
        </CmsItemEditSection>
      </CmsPage>
    </>
  );
};

export default CollectionItemEditorPage;
