import React, { useContext, useEffect } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import useWysiwygOptions from '../hooks/useWysiwygOptions';
import { Controller } from 'react-hook-form';
import { validationIncludesRequired } from '../formUtils';
import FormFieldError from './FormFieldError';
import classNames from 'classnames';
import { CmsContext } from '../../../context/definitions';

const FormWysiwyg = (props) => {
  const { label, tag, fieldName, formContext, formFieldOptions, growlId } = props;
  const isRequired = validationIncludesRequired(formFieldOptions);
  const { growlProvider } = useContext(CmsContext);
  const { error } = growlProvider;
  const { formState, control } = formContext;
  const { errors } = formState;
  const fieldError = errors[fieldName];
  const { wysiwygOptions, error: loadOptionsError } = useWysiwygOptions();
  useEffect(() => {
    if (loadOptionsError) {
      error(growlId, loadOptionsError);
    }
  }, [loadOptionsError, error]);
  return (
    <div
      className={classNames('form-group', {
        'has-error': !!fieldError,
      })}
    >
      <label>
        {label}
        {isRequired && <span className="required-annotation">*</span>}
      </label>

      {wysiwygOptions && (
        <div className="reset-this">
          <Controller
            name={fieldName}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FroalaEditor
                  tag={tag}
                  config={wysiwygOptions}
                  model={value}
                  onModelChange={onChange}
                />
              );
            }}
            {...formFieldOptions}
          />
        </div>
      )}
      <FormFieldError error={fieldError} />
    </div>
  );
};

export default FormWysiwyg;
