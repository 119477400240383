import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

const FormRadioButton = (props) => {
  const {
    label,
    fieldName,
    formFieldOptions,
    formContext,
    fieldWidth,
    className: providedClassName,
    forceCheck,
    preChecked,
    value,
    ...inputAttributes
  } = props;
  const { formState, register, setValue } = formContext;
  const { errors } = formState;
  const fieldError = errors[fieldName];
  const inputWidth = fieldWidth || 'normal';
  const [checked, setChecked] = useState(!!preChecked);
  const registered = register(fieldName, formFieldOptions); //eslint-disable-next-line
  //@ts-ignore

  if (forceCheck) setValue(fieldName, value);
  useEffect(() => {
    if (preChecked) setChecked(preChecked);
  }, [preChecked]);
  return (
    <div
      className={classNames(
        `form-radio-row${forceCheck ? ' form-radio-row-disabled' : ''}`,
        providedClassName,
        `input-${inputWidth}`,
        {
          'has-error': !!fieldError,
        }
      )}
    >
      <input
        type={'radio'}
        className="form-radio-row-input"
        checked={forceCheck || checked}
        value={value}
        {...inputAttributes}
        {...registered}
        onChange={(e) => {
          if (forceCheck) return;
          setChecked(!checked); //eslint-disable-next-line
          //@ts-ignore

          if (checked)
            setValue(fieldName, value, {
              shouldDirty: true,
            });
        }}
        disabled={forceCheck}
      />
      <label className="form-radio-row-label margin-left" htmlFor={inputAttributes.id}>
        {label}
      </label>
    </div>
  );
};

export default FormRadioButton;
