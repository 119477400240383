import { useContext, useMemo } from 'react';
import { CmsContext } from '../../context/definitions';
export default function useCohortMatchesActions(props) {
  const { pluginId, parentPageTitle, parentPageName, parentPageId, parentPageArgs } = props;
  const { navigator } = useContext(CmsContext);
  const back = useMemo(
    () => ({
      pageName: parentPageTitle,
      onClick: () => {
        navigator.navigateToParentPage(
          pluginId,
          parentPageName,
          parentPageId,
          ...(parentPageArgs || [])
        );
      },
    }),
    [navigator, parentPageTitle, pluginId, parentPageName, parentPageId, parentPageArgs]
  );
  return {
    back,
  };
}
