(function () {
  angular
    .module('manage-user-content', ['smart-table', 'user-api-service', 'confirm-dialog'])
    .controller('ManageUsersCtrl', [
      '$scope',
      '$modal',
      '$log',
      '$state',
      '$location',
      '$timeout',
      'growl',
      'CMS_INSTANCES',
      'ASSET_FILTER_OPTIONS',
      'userApiService',
      'confirmDialog',
      'configService',
      function (
        $scope,
        $modal,
        $log,
        $state,
        $location,
        $timeout,
        growl,
        CMS_INSTANCES,
        ASSET_FILTER_OPTIONS,
        userApiService,
        confirmDialog,
        configService
      ) {
        $scope.callServer = function (tableState) {
          $scope.isLoading = true;
          $timeout(function () {
            $scope.users = [];
            $scope.displayedUsers = [];
          });

          $scope.tableState = tableState;

          var pagination = tableState.pagination;

          var start = pagination.start || 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
          var number = pagination.number || 10; // Number of entries showed per page.

          getUsers(start, number, tableState);
        };

        /************* Initialization ************/
        const baseLength = 5; //lastName, firstName, email, groups, actions
        $scope.users = [];
        $scope.displayedUsers = [];
        $scope.configLoaded = false;
        $scope.hasCustomFields = false;
        $scope.totalColumns = baseLength;

        configService.getConfig().then(function (config) {
          var userConfig = config.userConfig;
          $scope.userTypeMap = createUserTypeMap(userConfig);
          $scope.showEmail = !!userConfig.captureUserEmail;
          $scope.configLoaded = true;
          createCsv();
        });

        function getUsers(start, number, tableState) {
          userApiService
            .getUsers(start, number, tableState)
            .success(function (data) {
              $scope.users = data.appUsers;
              $scope.displayedUsers = [].concat($scope.users);
              setNumberOfPages(data.count);
              $scope.isLoading = false;
              $scope.fieldHeaders = $scope.displayedUsers
                .flatMap((u) => (u.customFields && Object.keys(u.customFields)) || [])
                .filter((v, i, a) => v && a.indexOf(v) === i);
              $scope.hasCustomFields = $scope.fieldHeaders.length > 0;
              $scope.totalColumns = $scope.fieldHeaders.length + baseLength;
            })
            .error(function (data, status, headers, config) {
              growl.error('Failed to load users.');
              $log.debug('Failed to get all users. Response: ', data);
            });
        }

        function reload(returnToFirstPage) {
          if (returnToFirstPage) {
            $scope.tableState.pagination.start = 0;
          }

          $scope.callServer($scope.tableState);
        }

        function setNumberOfPages(count) {
          var numberOfPages = Math.ceil(count / 10);
          if ($scope.tableState) {
            $scope.tableState.pagination.numberOfPages = numberOfPages || 1; //set the number of pages so the pagination can update
          }
        }

        function createUserTypeMap(userConfig) {
          var userTypeMap = {};

          for (var i = 0; i < userConfig.userTypes.length; i++) {
            var userType = userConfig.userTypes[i];
            userTypeMap[userType.value] = userType;
          }

          return userTypeMap;
        }

        $scope.getTypeLabel = function (user) {
          var userType = $scope.userTypeMap[user.userType];
          return userType ? userType.label : 'Undefined';
        };

        /************ Upsert Asset ************/
        $scope.addOrEditUser = function (appUserToReplace) {
          var options = {
            templateUrl: 'modules/manageUsers/editUserModal.html',
            controller: 'EditUserCtrl',
            animation: false,
            scope: $scope.$new(),
          };

          if (appUserToReplace) {
            options.scope.user = appUserToReplace;
            options.scope.replaceMode = true;
          }

          var modalInstance = $modal.open(options);
          modalInstance.result.then(
            function (result) {
              growl.success('User uploaded successfully');
              reload(result.appUser.subtenantId !== $scope.selectedSubtenant.id);
            },
            function () {}
          );
        };

        /*********** Record lookup/management ***********/
        function getAppUserIndex(appUser) {
          if (!appUser.id) {
            return -1;
          }
          var index = -1;
          angular.forEach($scope.users, function (user, key) {
            if (user.id == appUser.id) {
              index = key;
            }
          });
          return index;
        }

        /*********** Remove override ***********/
        $scope.removeUser = function (user) {
          confirmDialog({
            title: 'Confirm remove',
            body: 'Are you sure you want to remove this user?',
            confirmText: 'Remove',
            cancelText: 'Cancel',
          }).result.then(function (result) {
            userApiService.removeUser(user).then(
              function (result) {
                var assetIndex = getAppUserIndex(user);
                if (assetIndex !== -1) {
                  $scope.users.splice(assetIndex, 1);
                  $scope.displayedUsers = [].concat($scope.users);
                  setNumberOfPages($scope.users.length);
                }
                reload(true);
              },
              function () {
                growl.error('Failed to remove user');
              }
            );
          });
        };

        /********** User Groups *************/
        $scope.getGroupsList = function (user) {
          var result = '';
          for (var i = 0; i < user.userGroups.length; i++) {
            var group = user.userGroups[i];

            if (i > 0) {
              result += '\n';
            }

            result += group.name;
          }

          return result;
        };

        /********** Custom Fields *************/
        function createCsv() {
          userApiService
            .getAllUsers()
            .success(function (data) {
              const users = data.appUsers;
              const customHeaders = users
                .flatMap((u) => (u.customFields && Object.keys(u.customFields)) || [])
                .filter((v, i, a) => v && a.indexOf(v) === i);
              let csv = '';
              const headers = [
                'User ID',
                'User First Name',
                'User Last Name',
                'User Type',
                'Email',
                'Last Login',
                'Registered On',
              ].concat(customHeaders);
              csv += headers.join(',') + '\n';

              angular.forEach(users, function (user) {
                var row = [
                  user.id,
                  user.firstName,
                  user.lastName,
                  $scope.getTypeLabel(user),
                  user.email,
                  user.lastLogin,
                  user.createdOn,
                  ...customHeaders.map(
                    (field) => (user.customFields && user.customFields[field]) || ''
                  ),
                ];
                csv += row.join(',') + '\n';
              });
              var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
              $scope.csvUrl = URL.createObjectURL(blob);
            })
            .error(function (data, status, headers, config) {
              growl.error('Failed to load users for csv export.');
              $log.debug('Failed to get all users for csv repsonse. Response: ', data);
            });
        }
      },
    ]);
})();
