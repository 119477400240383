import cloneDeep from 'lodash/cloneDeep';
export const conditions = [
  {
    name: 'IN',
    value: '=',
  },
  {
    name: 'NOT IN',
    value: '!=',
  },
];
export const operators = [
  {
    name: 'AND',
    label: 'Users Meet ALL Criteria',
  },
  {
    name: 'OR',
    label: 'Users Meet ANY Criteria',
  },
];
export const defaultLogic = {
  operator: operators[0].name,
  rules: [],
};
export const copyLogic = (logic) => cloneDeep(logic);
