import { useCallback, useContext, useMemo, useRef } from 'react';
import { CmsContext } from '../../../context/definitions';
export default function useRefreshActionCallback(defaultPageId) {
  const {
    growlProvider: { error },
  } = useContext(CmsContext);
  const hiddenButtonRef = useRef();
  const hiddenButton = useMemo(
    () => ({
      label: 'hidden',
      hiddenButtonRef,
      onClick: async ({ reload }) => {
        try {
          await reload();
        } catch (e) {
          error(defaultPageId, e);
        }
      },
    }),
    [defaultPageId, error]
  );
  const refreshCallback = useCallback(() => {
    if (hiddenButtonRef.current) {
      //eslint-disable-next-line
      //@ts-ignore
      hiddenButtonRef.current.click();
    }
  }, []);
  return {
    hiddenButton,
    refreshCallback,
  };
}
