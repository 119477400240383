import { useCallback, useMemo, useState } from 'react';

const useGrowlProvider = () => {
  const [growlMap, setGrowlMap] = useState({});
  const dismiss = useCallback(
    (growl) => {
      if (!growl.dismissed) {
        growl.dismissed = true;
        setGrowlMap((prevState) => {
          const { groupId } = growl;
          const prevGrowls = prevState[groupId] || [];
          return { ...prevState, [groupId]: prevGrowls.filter((g) => g !== growl) };
        });

        if (growl.onDismiss) {
          growl.onDismiss();
        }
      }
    },
    [setGrowlMap]
  );
  const dismissAll = useCallback(
    (growlId) => {
      setGrowlMap((prevState) => ({ ...prevState, [growlId]: undefined }));
    },
    [setGrowlMap]
  );
  const add = useCallback(
    (groupId, type, props) => {
      const { ttlSeconds } = props;
      const now = Date.now();
      const id = `${now}`;
      const growl = {
        groupId,
        id,
        type,
        ...props,
      };

      if (ttlSeconds > 0) {
        const ttlMillis = ttlSeconds * 1000;
        growl.expiration = Date.now() + ttlMillis;
        setTimeout(() => dismiss(growl), ttlMillis);
      }

      setGrowlMap((prevState) => {
        const prevGrowls = prevState[groupId] || [];
        return { ...prevState, [groupId]: prevGrowls.concat(growl) };
      });
    },
    [dismiss, setGrowlMap]
  );
  const success = useCallback(
    (groupId, props) => {
      if (typeof props === 'string') {
        props = {
          message: props,
          ttlSeconds: 3,
        };
      }

      add(groupId, 'success', props);
    },
    [add]
  );
  const error = useCallback(
    (groupId, props) => {
      if (typeof props === 'string') {
        props = {
          message: props,
        };
      }

      add(groupId, 'error', props);
    },
    [add]
  );
  const info = useCallback(
    (groupId, props) => {
      if (typeof props === 'string') {
        props = {
          message: props,
        };
      }

      add(groupId, 'info', props);
    },
    [add]
  );
  const growlProvider = useMemo(
    () => ({
      success,
      error,
      info,
      dismiss,
      dismissAll,
    }),
    [success, error, info, dismiss, dismissAll]
  );
  return {
    growlProvider,
    growlMap,
  };
};

export default useGrowlProvider;
