import { useContext, useMemo, useState } from 'react';
import { CmsContext } from '../../context/definitions';
import { updateAchievementOptions, getServiceUrls } from '../api/achievementsApi';
import { AchievementConstants } from '../definitions';
import achievementApiWrapped from '../api/achievementsApi';
export default function useAchievementActions(pageConstants, pageInstanceConstance) {
  const { typeName, pluginId, defaultPageId } = pageConstants;
  const { defaultPageName } = pageInstanceConstance;
  const [includeArchived, setIncludeArchived] = useState(false);
  const achievementApi = achievementApiWrapped(includeArchived);
  const {
    navigator,
    growlProvider: { success, error },
    confirm,
  } = useContext(CmsContext);
  const archiveCheckbox = useMemo(
    () => ({
      icon: 'fa-solid fa-pencil',
      label: includeArchived ? 'Hide Archived' : 'Show Archived',
      onClick: async () => void setIncludeArchived((current) => !current),
    }),
    [includeArchived, setIncludeArchived]
  );
  const add = useMemo(
    () => ({
      label: `+ Add Achievement`,
      onClick: async ({ reload }) => {
        try {
          const newAchievement = await achievementApi.loadRecord('new');
          navigator.navigateToPluginPage(
            AchievementConstants.pluginId,
            AchievementConstants.editorPageName,
            newAchievement.id || 'new'
          );
        } catch (e) {
          console.error(e);
          error(defaultPageId, 'Failed to add.');
        }
      },
    }),
    [typeName, navigator, pluginId, defaultPageId]
  );
  const manageTemplates = useMemo(
    () => ({
      label: `Manage Templates`,
      icon: 'fa solid fa-solid-pdf',
      onClick: async () => {
        try {
          const urls = await getServiceUrls();
          window.open(urls.site);
        } catch (e) {
          console.error(e);
          error(defaultPageId, 'Failed to open template manager.');
        }
      },
    }),
    [defaultPageId]
  );
  const archive = useMemo(
    () => ({
      label: (item) => (item.isArchived ? 'Unarchive' : 'Archive'),
      icon: 'fa-solid fa-box-archive',
      onClick: async (context, { reload }) => {
        try {
          let confirmResponse = true;

          if (!context.isArchived) {
            confirmResponse = await confirm({
              title: 'Achive Achievement',
              message: `Are you sure you want to archive '${context.name}'?`,
            });
          }

          if (confirmResponse) {
            await updateAchievementOptions(context.id, {
              isArchived: !context.isArchived,
            });
            await reload();
            success(defaultPageId, 'Successfully updated achievement.');
          }
        } catch (e) {
          console.error(e);
          error(defaultPageId, 'Failed to update achievement.');
        }
      },
    }),
    [typeName, navigator, pluginId, defaultPageId, confirm]
  );
  const edit = useMemo(
    () => ({
      icon: 'fa-solid fa-pencil',
      label: 'Edit',
      disabled: (context) => context.status === 'removed',
      onClick: async (context, { reload }) => {
        try {
          const newAchievement = await achievementApi.loadRecord(context.id);
          navigator.navigateToPluginPage(
            AchievementConstants.pluginId,
            AchievementConstants.editorPageName,
            newAchievement.id
          );
        } catch (e) {
          console.error(e);
          error(defaultPageId, 'Failed to add.');
        }
      },
    }),
    [navigator, pluginId, defaultPageId]
  );
  const manageInstances = useMemo(
    () => ({
      icon: 'fa-solid fa-list-tree',
      label: 'Manage Instances',
      disabled: (context) => context.status === 'removed',
      onClick: (context) => {
        navigator.navigateToPluginPage(pluginId, defaultPageName, context.id);
      },
    }),
    [navigator, pluginId, defaultPageId, defaultPageName]
  );
  const duplicate = useMemo(
    () => ({
      icon: 'fa-solid fa-clone',
      label: 'Duplicate',
      disabled: (context) => context.status === 'removed',
      onClick: async (context, { reload }) => {
        try {
          const newAchievement = context;
          delete newAchievement.id;
          newAchievement.name += ' copy';
          await achievementApi.saveRecord(newAchievement);
          await reload();
          success(defaultPageId, 'Successfully duplicated achievement.');
        } catch (e) {
          console.error(e);
          error(defaultPageId, 'Failed to open achievement creator.');
        }
      },
    }),
    [navigator, pluginId, defaultPageId]
  );
  return {
    add,
    archive,
    archiveCheckbox,
    duplicate,
    edit,
    includeArchived,
    manageInstances,
    manageTemplates,
  };
}
