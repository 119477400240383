import { useContext, useMemo } from 'react';
import { CmsContext } from '../../context/definitions';
import useLoadSubListItems from '../../shared/cmsPage/hooks/useLoadSubListItems';
import usePublishActionHandler from '../../shared/cmsPage/hooks/usePublishActionHandler';
import collectionApi from '../api/collectionApi';
import { CollectionConstants, CollectionEditorConstants } from '../definitions';
export default function useCollectionEditorActions(props) {
  const {
    hasItemsPendingPublish,
    item,
    loadItem,
    loadItems,
    collectionId,
    pageTitle,
    publishDisabled,
    setHasReordered,
  } = props;
  const {
    growlProvider: { success },
    navigator,
  } = useContext(CmsContext);
  const editSettings = useMemo(
    () => ({
      label: 'Collection Settings',
      onClick: async () => {
        navigator.navigateToPluginPage(
          CollectionConstants.pluginId,
          CollectionConstants.settingsPageName,
          item.collection_id
        );
      },
      visible: true,
    }),
    [success, item, navigator]
  );
  const reload = useLoadSubListItems(
    CollectionEditorConstants.defaultPageId,
    collectionId,
    loadItems
  );
  const publishHandler = usePublishActionHandler(
    CollectionConstants.pluginId,
    CollectionConstants.typeName,
    CollectionConstants.publishPageName,
    CollectionEditorConstants.defaultPageId,
    CollectionEditorConstants.defaultPageName,
    pageTitle,
    CollectionEditorConstants.defaultPageId,
    collectionApi
  );
  const publish = useMemo(
    () => ({
      label: 'Publish',
      disabled: publishDisabled,
      onClick: async () => {
        await publishHandler(collectionId, hasItemsPendingPublish, async () => {
          setHasReordered(false);
          await Promise.all([loadItem(collectionId), reload()]);
        });
      },
    }),
    [
      hasItemsPendingPublish,
      publishDisabled,
      publishHandler,
      reload,
      loadItem,
      collectionId,
      setHasReordered,
    ]
  );
  return {
    publish,
    editSettings,
  };
}
