import classNames from 'classnames';
import React from 'react';
import { validationIncludesRequired } from '../formUtils';
import FormFieldError from './FormFieldError';

const SelectSwitch = (props) => {
  const {
    label,
    fieldName,
    placeholder,
    formFieldOptions,
    formContext,
    fieldWidth,
    className: providedClassName,
    options,
    updateView,
    fontWeight,
    // optionsLoader,
    ...selectAttributes
  } = props;
  const { formState, register, setValue } = formContext;
  const { errors } = formState;
  const fieldError = errors[fieldName];
  const inputWidth = fieldWidth || 'normal';
  const value = formContext.getValues()[fieldName];
  const isRequired = validationIncludesRequired(formFieldOptions);

  const handleChange = (e) => {
    const view = e.target.value; //eslint-disable-next-line
    //@ts-ignore

    setValue('defaultView', view, {
      shouldDirty: true,
    });
    updateView(view);
  };

  return (
    <div
      style={{
        marginTop: '15px',
      }}
      className={classNames('form-group', providedClassName, `input-${inputWidth}`, {
        'has-error': !!fieldError,
      })}
    >
      <label style={{ fontWeight: fontWeight }}>
        {label}
        {isRequired && <span className="required-annotation">*</span>}
      </label>

      <select
        className="form-control"
        {...register(fieldName, formFieldOptions)}
        {...selectAttributes}
        defaultValue={value || ''}
        onChange={handleChange}
      >
        {placeholder && (
          <option key="placeholder" value="" disabled>
            {placeholder}
          </option>
        )}
        {options &&
          options.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
      </select>

      <FormFieldError error={fieldError} />
    </div>
  );
};

export default SelectSwitch;
