angular.module('group-api-service', []).service('groupApiService', [
  '$q',
  '$log',
  'Upload',
  '$http',
  '$rootScope',
  'configService',
  function ($q, $log, Upload, $http, $rootScope, configService) {
    var groupMap = {};
    var groupFilter = 'Show All';

    function getGroups(start, number, params, includeSubtenantGroups) {
      var sortPredicate;
      var sortReverse;
      var searchPredicate;
      if (params) {
        sortPredicate = params.sort.predicate;
        sortReverse = params.sort.reverse;
        searchPredicate = params.search.predicateObject;
        if (searchPredicate) {
          searchPredicate = searchPredicate.name;
        }
      }

      var httpParams = {
        params: {
          start: start,
          number: number,
          sortPredicate: sortPredicate,
          sortReverse: sortReverse,
          searchPredicate: searchPredicate,
          includeSubtenantGroups: includeSubtenantGroups,
          groupType: groupFilter,
        },
      };

      var promise = $http
        .get('/user-api/getGroups', httpParams)
        .success(function (data, status, headers, config) {
          rebuildGroupMap(data.groups);
        })
        .error(function (data, status, headers, config) {
          $log.debug('Failed to get all groups. Response: ', data);
        });

      return promise;
    }

    function rebuildGroupMap(groups) {
      groupMap = {};
      for (var i = 0; i < groups.length; i++) {
        var appGroup = groups[i];
        groupMap[appGroup.id] = appGroup;
      }
    }

    function getGroupMetadata(id) {
      return groupMap[id];
    }

    function saveGroup(group, isNew) {
      var data = {
        id: group.id,
        key: group.key,
        name: group.name,
        defaultAccessible: group.defaultAccessible,
        isNew: !!isNew,
      };

      var promise = $http.post('/user-api/upsertGroup', data);

      promise.then(function (result) {
        groupMap[group.id] = result.data.group;
        broadcastGroupUpdated(result.data.group);
      });

      return promise;
    }

    function removeGroup(group) {
      var data = {
        id: group.id,
      };

      var promise = $http.post('/user-api/deleteGroup', data);

      promise.then(function (result) {
        delete groupMap[group.id];

        broadcastGroupUpdated(group);
      });

      return promise;
    }

    function broadcastGroupUpdated(group) {
      $rootScope.$broadcast('groupUpdated', group.id);
    }

    function getUsersFromGroup(groupId) {
      var data = {
        groupId: groupId,
      };
      var promise = $http
        .post('/user-api/getUsersFromGroup', data)
        .success(function (data, status, headers, config) {
          return data;
        })
        .error(function (data, status, headers, config) {
          $log.debug('Failed to get all appUsers from group. Response: ', data);
        });

      return promise;
    }

    function updateAppUserGroups(groupId, groupKey, userId, action) {
      var data = {
        groupId: groupId,
        groupKey,
        userId,
        action,
      };
      var promise = $http
        .post('/user-api/updateAppUserGroups', data)
        .success(function (data, status, headers, config) {
          return data;
        })
        .error(function (data, status, headers, config) {
          $log.debug('Failed to update app Users group. Response: ', data);
        });

      return promise;
    }

    function getUsers(params) {
      var sortPredicate;
      var sortReverse;
      var searchPredicate;
      if (params) {
        sortPredicate = params.sort.predicate;
        sortReverse = params.sort.reverse;
        searchPredicate = params.search.predicateObject;
        if (searchPredicate) {
          searchPredicate = searchPredicate.name;
        }
      }

      var httpParams = {
        params: {
          start: 0,
          number: 10000,
          sortPredicate: sortPredicate,
          sortReverse: sortReverse,
          searchPredicate: searchPredicate,
        },
      };

      var promise = $http
        .get('/user-api/getUsers', httpParams)
        .success(function (data, status, headers, config) {
          return data;
          // rebuildUserMap(data.appUsers);
        })
        .error(function (data, status, headers, config) {
          $log.debug('Failed to get all appUsers. Response: ', data);
        });

      return promise;
    }

    function saveUser(user) {
      var data = {
        id: user.id,
        accessCode: user.accessCode,
        userType: user.userType,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        userGroups: user.userGroups,
        subtenantId: user.subtenantId,
      };

      var promise = $http.post('/user-api/upsertUser', data);

      promise.then(function (result) {
        return result;
      });

      return promise;
    }

    function setGroupFilter(newFilter) {
      groupFilter = newFilter;
    }

    function getGroupFilter() {
      return groupFilter;
    }

    return {
      removeGroup: removeGroup,
      saveGroup: saveGroup,
      getGroupMetadata: getGroupMetadata,
      getGroups: getGroups,
      getUsersFromGroup: getUsersFromGroup,
      getUsers: getUsers,
      saveUser: saveUser,
      setGroupFilter: setGroupFilter,
      getGroupFilter: getGroupFilter,
      updateAppUserGroups: updateAppUserGroups,
    };
  },
]);
