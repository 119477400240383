import { useCallback, useContext, useEffect } from 'react';
import { CmsContext } from '../../../context/definitions';

const useLoadSubListItems = (pageId, parentItemId, loadSubItems) => {
  const { growlProvider } = useContext(CmsContext);
  const { error } = growlProvider;
  const reload = useCallback(async () => {
    try {
      await loadSubItems(parentItemId);
    } catch (e) {
      error(pageId, e.message);
    }
  }, [loadSubItems, pageId, parentItemId, error]);
  useEffect(() => {
    (async () => reload())();
  }, [reload]);
  return reload;
};

export default useLoadSubListItems;
