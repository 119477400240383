import React from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import ItemPublishStatus from '../../shared/itemTable/components/ItemPublishStatus';
import Timestamp from '../../shared/itemTable/components/Timestamp';
import useArray from '../../shared/util/hooks/useArray';
import collectionApi from '../api/collectionApi';
import { CollectionConstants } from '../definitions';
import useCollectionListActions from '../hooks/useCollectionListActions';
import CollectionTitle from './CollectionTitle';
const CollectionColumns = [
  {
    label: 'Name',
    valueField: 'name',
    sortable: true,
    initialSort: 'ASC',
    renderer: (collection) => <CollectionTitle collection={collection} />,
  },
  {
    label: 'Display Label',
    valueField: 'label',
    sortable: true,
  },
  {
    label: 'Status',
    valueField: 'status',
    sortable: false,
    renderer: (collection) => <ItemPublishStatus status={collection.status} />,
  },
  {
    label: 'Last Modified',
    valueField: 'lastUpdated',
    sortable: true,
    renderer: (item, field) => <Timestamp date={item[field]} />,
  },
];

const CollectionListPage = () => {
  const { defaultPageId, typeName } = CollectionConstants;
  const { add, edit, remove, restore, publish, requestPending, duplicate, isDuplicating } =
    useCollectionListActions(CollectionConstants);
  const toolbarActions = useArray(add);
  const itemActions = useArray(edit, publish, remove, restore, duplicate);
  return (
    <CmsPage title="Collections" requestPending={requestPending || isDuplicating}>
      <CmsItemListSection
        pageId={defaultPageId}
        className="nav-manager"
        typeName={typeName}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={CollectionColumns}
        itemApi={collectionApi}
      />
    </CmsPage>
  );
};

export default CollectionListPage;
