import React, { useMemo } from 'react';

const ItemPublishStatus = (props) => {
  const { status } = props;
  const statusLabel = useMemo(() => {
    switch (status) {
      case 'pending':
        return <div className="publish-status-yellow"></div>;

      case 'published':
        return <div className="publish-status-green"></div>;

      case 'removed':
        return <div className="publish-status-yellow"></div>;

      case 'unpublished':
        return <div className="publish-status-red"></div>;

      default:
        return status;
    }
  }, [status]);
  return <>{statusLabel}</>;
};

export default ItemPublishStatus;
