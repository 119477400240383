import React, { useMemo, useCallback, useContext } from 'react';
import { CmsContext } from '../../../context/definitions';

const ItemLinkDisplay = (props) => {
  const { objectDetail, objectReference } = props;
  const { navigator } = useContext(CmsContext);
  const maxLinkLength = 30;
  const hasLink = objectDetail?.editLink;
  const navigateToLink = useCallback(() => {
    if (hasLink) {
      const pluginLink = objectDetail.editLink;
      navigator.navigateToPluginPage(
        pluginLink.pluginName,
        pluginLink.pluginPage,
        ...pluginLink.args
      );
    }
  }, [navigator]);
  const link = useMemo(() => {
    return objectDetail?.displayName || 'NA';
  }, [objectReference]);
  return (
    <>
      {hasLink ? (
        <a onClick={navigateToLink}>{link}</a>
      ) : link.length > maxLinkLength ? (
        `${link.substring(0, maxLinkLength)}...`
      ) : (
        link
      )}
    </>
  );
};

export default ItemLinkDisplay;
