import { useContext, useMemo } from 'react';
import { CmsContext } from '../../context/definitions';
export default function useAchievementInsanceActions(achievementId, pageConstants) {
  const { navigator } = useContext(CmsContext);
  const { pluginId, editorPageName, defaultPageId, typeName } = pageConstants;
  const add = useMemo(
    () => ({
      label: `+ Add ${typeName}`,
      onClick: ({ loaderParams }) => {
        navigator.navigateToChildPage(
          pluginId,
          editorPageName,
          defaultPageId,
          loaderParams,
          achievementId,
          'new'
        );
      },
    }),
    [typeName, navigator, pluginId, defaultPageId, editorPageName, achievementId]
  );
  const edit = useMemo(
    () => ({
      icon: 'fa-solid fa-pencil',
      label: 'Edit',
      disabled: (context) => context.status === 'removed',
      onClick: (context, { loaderParams }) => {
        navigator.navigateToChildPage(
          pluginId,
          editorPageName,
          defaultPageId,
          loaderParams,
          achievementId,
          context.id
        );
      },
    }),
    [navigator, pluginId, defaultPageId, editorPageName, achievementId]
  );
  const backToAchievements = useMemo(
    () => ({
      pageName: 'Achievements',
      onClick: () => {
        navigator.navigateToChildPage(pluginId, 'default', 'achievements-list', {
          search: '',
          limit: 25,
          offset: 0,
          sort: {
            sortField: 'title',
            order: 'ASC',
          },
        });
      },
    }),
    [navigator, pluginId]
  );
  return {
    backToAchievements,
    add,
    edit,
  };
}
