import React, { useCallback, useMemo } from 'react';
import PaginationControl from './PaginationControl';
import ItemTableHeaderCell from './ItemTableHeaderCell';
import ItemTableRow from './ItemTableRow';
import { getColumnSortOrder } from '../itemTableUtils';

const ItemTable = (props) => {
  const { items, columns, itemActions, pagination, sort, onPageSelected, onSortChange } = props;
  const columnCount = useMemo(() => {
    return columns.length + (itemActions ? 1 : 0);
  }, [columns, itemActions]);
  const onColumnClick = useCallback(
    (column, sort) => {
      const sortField = column.valueField;
      const order = getColumnSortOrder(column, sort);
      const newOrder = order === 'ASC' ? 'DESC' : 'ASC';
      onSortChange({
        sortField: sortField,
        order: newOrder,
      });
    },
    [getColumnSortOrder, onSortChange]
  );
  return (
    <table className="table table-striped">
      <thead>
        <tr>
          {columns.map((column, index) => (
            <ItemTableHeaderCell
              key={index}
              column={column}
              onClick={() => onColumnClick(column, sort)}
              sortOrder={getColumnSortOrder(column, sort)}
            />
          ))}
          {itemActions?.length > 0 && <th />}
        </tr>
      </thead>
      {items.length > 0 ? (
        <>
          <tbody>
            {items.map((item, itemIndex) => (
              <tr key={itemIndex}>
                <ItemTableRow
                  key={itemIndex}
                  item={item}
                  columns={columns}
                  itemActions={itemActions}
                />
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={columnCount} className="text-center small-footer">
                <PaginationControl {...pagination} onPageSelected={onPageSelected} />
              </td>
            </tr>
          </tfoot>
        </>
      ) : (
        <tbody>
          <tr>
            <td className="table-message" colSpan={columns.length}>
              There are no items that match your criteria.
            </td>
            {itemActions && <td />}
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default ItemTable;
