import React, { useCallback, useContext, useEffect, useState } from 'react';
import ItemTableHeaderCell from './ItemTableHeaderCell';
import ItemTableRow from './ItemTableRow';
import OrderedItemTableRowSelector from './OrderedItemTableRowSelector';
import { CmsContext } from '../../../context/definitions';
import { ReactSortable } from 'react-sortablejs';

const OrderedItemTable = (props) => {
  const {
    items,
    columns,
    itemActions,
    reorder,
    parentItemId,
    growlId,
    emptyListMessage,
    injectedRowCount = 0,
  } = props;
  const [displayItems, setDisplayItems] = useState(items);
  const { growlProvider } = useContext(CmsContext);
  const { error } = growlProvider;
  const reorderItems = useCallback(() => {
    try {
      const ids = displayItems.map(({ id }) => id);
      reorder(parentItemId, ids);
    } catch (e) {
      error(growlId, e.message);
      setDisplayItems(items);
    }
  }, [reorder, displayItems, parentItemId, error]);
  useEffect(() => {
    if (!displayItems.length) setDisplayItems(items);
  }, [items]);
  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th />
          {columns.map((column, index) => (
            <ItemTableHeaderCell key={index} column={column} />
          ))}
          {itemActions && <th />}
        </tr>
      </thead>
      {displayItems?.length ? (
        <ReactSortable
          tag="tbody"
          list={displayItems}
          draggable=".draggable"
          setList={setDisplayItems}
          onEnd={reorderItems}
        >
          {displayItems.map((item, itemIndex) => (
            <tr
              key={item.id}
              className={
                itemIndex >= injectedRowCount
                  ? 'draggable'
                  : itemIndex === injectedRowCount - 1
                  ? 'static-end'
                  : 'static'
              }
            >
              {itemIndex >= injectedRowCount ? <OrderedItemTableRowSelector /> : <td />}
              <ItemTableRow key={item.id} item={item} columns={columns} itemActions={itemActions} />
            </tr>
          ))}
        </ReactSortable>
      ) : (
        <tbody>
          <tr>
            <td />
            <td className="table-message" colSpan={columns.length}>
              {emptyListMessage}
            </td>
            {itemActions && <td />}
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default OrderedItemTable;
