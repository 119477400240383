import { useCallback, useState } from 'react';
import { EMPTY_SORT } from '../../itemTable/definitions';
export const useListLoader = (listLoader) => {
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [pagination, setPagination] = useState({
    pageCount: 1,
    currentPage: 1,
  });
  const [sort, setSort] = useState(EMPTY_SORT);
  const loadItems = useCallback(
    async (params) => {
      const { limit } = params;
      setLoading(true);

      try {
        const { records, offset, numRecords } = await listLoader(params);
        const pageCount = Math.ceil(numRecords / limit);
        setRecords(records);
        setPagination({
          pageCount,
          currentPage: offset / limit + 1,
        });
        setSort(params.sort);
      } catch (e) {
        setRecords([]);
        setPagination({
          pageCount: 1,
          currentPage: 1,
        });
        setSort(EMPTY_SORT);
        throw e;
      } finally {
        setLoading(false);
      }
    },
    [listLoader, setLoading, setRecords, setPagination, setSort]
  );
  return {
    loadItems,
    loading,
    records,
    pagination,
    sort,
  };
};
