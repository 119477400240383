import axios from 'axios';
const baseUrl = '/plugins/selfregistration/api';

async function purgeUserData(request) {
  try {
    const { data } = await axios.post(
      `${baseUrl}/purgeDeletedUserData`,
      { ...request },
      {
        timeout: 10000,
      }
    );
    const { appUserId } = data;
    return appUserId;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to purge user data.');
  }
}

export const privacyApi = {
  purgeUserData,
};
