import { useContext, useMemo } from 'react';
import { CmsContext } from '../../context/definitions';
import useConfirmPublish from '../../shared/cmsPage/hooks/useConfirmPublish';
import { MenuEditorConstants, MenuPublishPageConstants } from '../definitions';
import useArray from '../../shared/util/hooks/useArray';
export default function useMenuItemPublishActions(props) {
  const { selectedItems, item, publishItem, menuId, returnPageName, successGrowlId } = props;
  const { navigator, growlProvider, confirm } = useContext(CmsContext);
  const { success, error } = growlProvider;
  const confirmPublish = useConfirmPublish('menu');
  const publish = useMemo(
    () => ({
      label: `Publish Now`,
      onClick: async () => {
        const confirmation = await confirmPublish();

        if (confirmation) {
          try {
            await publishItem(menuId, selectedItems);
            navigator.navigateToPluginPage(MenuEditorConstants.pluginId, returnPageName, menuId);
            success(successGrowlId, `The menu has been successfully published!`);
          } catch (e) {
            error(MenuEditorConstants.defaultPageId, e.message);
          }
        }
      },
    }),
    [
      item,
      selectedItems,
      publishItem,
      confirmPublish,
      success,
      error,
      confirm,
      navigator,
      MenuEditorConstants,
      menuId,
      successGrowlId,
      returnPageName,
    ]
  );
  const cancel = useMemo(
    () => ({
      label: `Cancel`,
      onClick: () => {
        navigator.navigateToPluginPage(MenuPublishPageConstants.pluginId, returnPageName, menuId);
      },
    }),
    [navigator, MenuEditorConstants, menuId, returnPageName]
  );
  const toolbarActions = useArray(publish, cancel);
  return {
    toolbarActions,
  };
}
