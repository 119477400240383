import { useContext, useCallback } from 'react';
import { CmsContext } from '../../../context/definitions';

const useConfirmPublish = (typeName) => {
  const { confirm } = useContext(CmsContext);
  return useCallback(async () => {
    return await confirm({
      title: `Publish ${typeName}`,
      message: `Are you sure you want to publish this ${typeName}? It will be automatically pushed to users next time they visit the app.`,
    });
  }, [confirm, typeName]);
};

export default useConfirmPublish;
