import React from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useArray from '../../shared/util/hooks/useArray';
import { AchievementConstants, AchievementInstanceConstants } from '../definitions';
import useAchievementActions from '../hooks/useAchievementActions';
import useAchievementConfigurations from '../hooks/useAchievementConfigurations';

const AchievementsListPage = () => {
  const { defaultPageId, typeName } = AchievementConstants;
  const { add, archive, archiveCheckbox, duplicate, edit, includeArchived, manageInstances } =
    useAchievementActions(AchievementConstants, AchievementInstanceConstants);
  const { columns, api } = useAchievementConfigurations(includeArchived);
  const toolbarActions = useArray(archiveCheckbox, add);
  const itemActions = useArray(edit, manageInstances, duplicate, archive);
  return (
    <CmsPage title={'Achievements'}>
      <CmsItemListSection
        pageId={defaultPageId}
        typeName={typeName}
        toolbarActions={toolbarActions}
        itemActions={itemActions}
        columns={columns}
        itemApi={api}
      />
    </CmsPage>
  );
};

export default AchievementsListPage;
