import React from 'react';
import ActionButton from '../../actionButton/components/ActionButton';
import ActionSwitch from '../../actionButton/components/ActionSwitch';
import { checkFlagOrFunction } from '../../util/checkFlagOrFunction';

const ActionList = (props) => {
  const { actions, className } = props;
  return (
    <div className={`action-buttons flex-align ${className || ''}`}>
      {actions.map((action, index) => {
        const { disabled, visible } = action;
        const itemDisabled = checkFlagOrFunction(disabled);
        const itemVisible = typeof visible === 'undefined' || checkFlagOrFunction(visible);
        return (
          <div key={index}>
            {itemVisible && !action.useSwitch && (
              <ActionButton
                hiddenButtonRef={action.hiddenButtonRef}
                variant={action.variant}
                icon={action.icon}
                label={action.label}
                disabled={itemDisabled}
                onClick={() => action.onClick()}
              />
            )}
            {action.useSwitch && (
              <ActionSwitch
                hiddenButtonRef={action.hiddenButtonRef}
                variant={action.variant}
                icon={action.icon}
                label={action.label}
                disabled={itemDisabled}
                onClick={() => action.onClick()}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ActionList;
