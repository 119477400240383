import { EMPTY_COHORT } from '../definitions';
import axios from 'axios';
const baseUrl = '/plugins/cohorts/api';

const loadRecords = async (params) => {
  const { sort, ...otherParams } = params;
  const { sortField, order } = sort;
  const sortReverse = sortField ? order === 'DESC' : undefined;
  let result;

  try {
    const { data } = await axios.get(`${baseUrl}/getCohorts`, {
      params: { ...otherParams, sortField, sortReverse },
      timeout: 10000,
    });
    const { cohorts, count, offset } = data;
    result = {
      records: cohorts,
      numRecords: count,
      offset,
    };
  } catch (e) {
    console.error(e);
    throw new Error('Failed to load cohorts');
  }

  return result;
};

const loadRecord = async (id) => {
  if (id === 'new') {
    return EMPTY_COHORT;
  } else {
    try {
      const { data } = await axios.get(`${baseUrl}/getCohort`, {
        params: {
          id,
        },
        timeout: 10000,
      });
      return data.cohort;
    } catch (e) {
      console.error(e);
      throw new Error('Failed to load cohort.');
    }
  }
};

const saveRecord = async (item) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/saveCohort`,
      { ...item },
      {
        timeout: 10000,
      }
    );
    return data.cohort;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to save cohort.');
  }
};

const removeRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(
      `${baseUrl}/removeCohort`,
      {
        id,
      },
      {
        timeout: 10000,
      }
    );
  } catch (e) {
    console.error(e);
    throw new Error('Failed to remove cohort.');
  }
};

const cohortApi = {
  loadRecords,
  loadRecord,
  saveRecord,
  removeRecord,
};
export default cohortApi;
