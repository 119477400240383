import React, { useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import { resetLogic, setLocation, setShowModal } from '../../../store';
import { selectQueryBuilderFooterProps } from '../../../store/selectors';
import useLogicValidation from '../../hooks/useLogicValidation';

const QueryBuilderFooter = (props) => {
  const { onSave, fieldName } = props;
  const { buildQuery, existingAudienceValid, hasLogicChanged, requireNew } =
    selectQueryBuilderFooterProps(fieldName);
  const dispatch = useDispatch();
  const logicIsValid = useLogicValidation(fieldName) || !buildQuery;
  const canPreview = ((buildQuery || requireNew) && logicIsValid) || existingAudienceValid;
  const handleClear = useCallback(
    () =>
      void dispatch(
        resetLogic({
          fieldName,
        })
      ),
    [dispatch, fieldName]
  );
  const handleSaveAudience = useCallback(() => {
    dispatch(
      setLocation({
        location: 'modal.showSave',
        value: true,
        fieldName,
      })
    );
  }, [onSave, dispatch, fieldName]);
  const handleCancel = useCallback(() => {
    dispatch(
      setShowModal({
        showModal: false,
        fieldName,
      })
    );
  }, [dispatch, fieldName]);
  const handlePreview = useCallback(() => {
    dispatch(
      setLocation({
        location: 'modal.showPreview',
        value: true,
        fieldName,
      })
    );
  }, [dispatch, fieldName]);
  return (
    <div
      className="action-buttons flex-space"
      style={{
        flexGrow: 1,
        flexWrap: 'wrap',
      }}
    >
      <div className="form-button-section">
        <Button disabled={hasLogicChanged} variant="outline-secondary" onClick={handleClear}>
          Clear All
        </Button>
        <Button disabled={!logicIsValid} variant="-outline-primary" onClick={onSave}>
          Set Audience
        </Button>
        {Boolean(buildQuery && !requireNew) && (
          <Button disabled={!logicIsValid} variant="primary" onClick={handleSaveAudience}>
            Save and Set Audience
          </Button>
        )}
        <Button variant="cancel" onClick={handleCancel}>
          Cancel
        </Button>
      </div>
      <div
        style={{
          justifySelf: 'flex-end',
        }}
      >
        <Button
          className="slide-right"
          variant="secondary"
          disabled={!canPreview}
          onClick={handlePreview}
        >
          Preview
        </Button>
      </div>
    </div>
  );
};

export default QueryBuilderFooter;
