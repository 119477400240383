import React from 'react';
import classNames from 'classnames';
import FormFieldError from './FormFieldError';
import { validationIncludesRequired } from '../formUtils';

const FormInputInline = (props) => {
  const {
    label,
    fieldName,
    placeholder,
    formFieldOptions,
    formContext,
    fieldWidth,
    type: providedType,
    className: providedClassName,
    ...inputAttributes
  } = props;
  const { formState, register } = formContext;
  const { errors } = formState;
  const fieldError = errors[fieldName];
  const inputWidth = fieldWidth || 'normal';
  const isRequired = validationIncludesRequired(formFieldOptions);
  return (
    <div
      className={classNames('form-group', providedClassName, `input-${inputWidth}`, {
        'has-error': !!fieldError,
      })}
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginLeft: 15,
        marginTop: 8,
      }}
    >
      <label style={{ display: 'flex', marginRight: 10 }}>
        <span
          style={{
            // marginLeft: '8px',
            marginTop: '4px',
          }}
        >
          {label}
        </span>
        {isRequired && <span className="required-annotation">*</span>}
      </label>
      <input
        type={providedType || 'text'}
        className="form-control no-border"
        placeholder={placeholder}
        maxLength={255}
        {...inputAttributes}
        {...register(fieldName, formFieldOptions)}
      />
      <FormFieldError error={fieldError} />
    </div>
  );
};

export default FormInputInline;
