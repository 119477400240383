import createPageConstants from '../shared/util/createPageConstants';
const pluginId = 'achievements';
export const AchievementConstants = createPageConstants(pluginId, 'Achievement', {
  defaultDefault: 'achievementsListPage',
  editor: 'achievementEditorPage',
});
export const AchievementInstanceConstants = createPageConstants(pluginId, 'Achievement Instance', {
  default: 'achievementInstancesPage',
  editor: 'achievementInstanceEditorPage',
});
export const EMPTY_ACHIEVEMENT = {
  name: '',
  isArchived: false,
};
export const EMPTY_ACHIEVEMENT_INSTANCE = {
  name: '',
  issuers: null,
  recipients: null,
  included: {},
};
