import React, { useCallback } from 'react';
import Button from 'react-bootstrap/Button';

const ActionButton = (props) => {
  const { icon, label, disabled, onClick, variant, hiddenButtonRef } = props;
  const clickHandler = useCallback(() => {
    return onClick();
  }, [onClick]);
  const additionalProps = {
    ...(hiddenButtonRef && {
      ref: hiddenButtonRef,
      style: {
        display: 'none',
      },
    }),
  };
  return (
    <Button {...additionalProps} variant={variant} disabled={disabled} onClick={clickHandler}>
      {icon && <i className={icon}></i>}
      {label}
    </Button>
  );
};

export default ActionButton;
