import get from 'lodash/get';
import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import { useSubItemsLoader } from '../../shared/cms/hooks/useSubItemsLoader';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsSubItemListSection from '../../shared/cmsPage/components/CmsSubItemListSection';
import { useEditPageTitle } from '../../shared/cmsPage/hooks/useEditPageTitle';
import useSubItemListActions from '../../shared/cmsPage/hooks/useSubItemListActions';
import ItemPublishStatus from '../../shared/itemTable/components/ItemPublishStatus';
import ItemLinkDisplay from '../../shared/itemTable/components/ItemLinkDisplay';
import Timestamp from '../../shared/itemTable/components/Timestamp';
import useArray from '../../shared/util/hooks/useArray';
import menuApi from '../api/navbarApi';
import menuItemApi from '../api/navbarItemApi';
import { MenuEditorConstants } from '../definitions';
import useMenuEditorActions from '../hooks/useNavbarEditorActions';
import MenuItemTitle from './NavbarItemTitle';
import { CmsContext } from '../../context/definitions';

const MenuEditorPage = (props) => {
  const { menuId: propMenuId } = props;
  const [menuId, setMenuId] = useState(propMenuId || '');
  const context = useContext(CmsContext);
  const isDefaultSubtenant = get(context, 'selectedSubtenant.isDefault', true);
  useEffect(() => {
    if (!isDefaultSubtenant) {
      setMenuId('');
      return;
    }
    const getMenus = async () => {
      const { records } = await menuApi.loadRecords({ sort: {} });
      const navbar = records.find((m) => m.key === 'sideMenu');
      if (navbar) setMenuId(navbar.id);
    };
    if (propMenuId) setMenuId(propMenuId);
    else {
      getMenus();
    }
  }, [propMenuId, isDefaultSubtenant]);
  const MenuItemColumns = [
    {
      label: 'Title (Label)',
      valueField: 'label',
      renderer: (item) => <MenuItemTitle menuItem={item} menuId={menuId} />,
    },
    {
      label: 'Status',
      valueField: 'status',
      renderer: (item) => <ItemPublishStatus status={item.status} />,
    },
    {
      label: 'Type',
      valueField: 'objectReference',
      renderer: (item) => <div>{item?.objectDetail?.plugin || 'NA'}</div>,
    },
    {
      label: 'Link',
      valueField: 'objectReference',
      renderer: (item) => (
        <ItemLinkDisplay objectReference={item.objectReference} objectDetail={item.objectDetail} />
      ),
    },
    {
      label: 'Last Modified',
      valueField: 'lastUpdated',
      sortable: true,
      renderer: (item, field) => <Timestamp date={item[field]} />,
    },
  ];
  const [hasReordered, setHasReordered] = useState(false);
  const {
    add,
    edit,
    restore,
    requestPending: itemActionRequestPending,
  } = useSubItemListActions(menuItemApi, menuId, MenuEditorConstants);
  const {
    item,
    loadItem,
    requestPending: loadItemPending,
    reorderItems,
  } = useItemPersistence(menuApi);
  const reorderItemsWrapper = useCallback(
    async (parentId, itemIds) => {
      setHasReordered(true);
      await reorderItems(parentId, itemIds);
    },
    [setHasReordered, reorderItems]
  );
  useEffect(() => {
    if (menuId) {
      (async () => await loadItem(menuId))();
    }
  }, [menuId, loadItem]);
  const emptyListMessage = useMemo(
    () =>
      item
        ? `There are currently no menu items for '${item.name}.'`
        : `There are currently no menu items.`,
    [item]
  );
  const { loadRecords } = menuItemApi;
  const { loadItems, records } = useSubItemsLoader(loadRecords);
  const requestPending = itemActionRequestPending || loadItemPending;
  const { onItemChange } = useEditPageTitle(menuId, 'Menu', 'name');
  const pageTitle = 'Edit NavBar';
  useEffect(() => onItemChange(item), [item, onItemChange]);
  const hasItemsPendingPublish = records.some((r) => r && r.status && r.status !== 'published');
  const hasSettingsPendingPublish = item && item.status && item.status !== 'published';
  const publishDisabled = !hasSettingsPendingPublish && !hasItemsPendingPublish && !hasReordered;
  const alert = {
    condition: !publishDisabled,
    message: `You have unpublished changes to the NavBar which may include name, label and/or sort order. Select Publish Menu to begin the publish process.`,
    alertType: 'info',
  };
  const { publish, remove } = useMenuEditorActions({
    hasItemsPendingPublish,
    loadItem,
    loadItems,
    menuId,
    pageTitle,
    publishDisabled,
    setHasReordered,
  });
  const toolbarActions = useArray(publish, add);
  const itemActions = useArray(edit, remove, restore);
  const orientHandler = useCallback(
    async (e) => {
      const { value } = e.target;
      const updatedItem = { ...item, orientation: value };
      await menuApi.saveRecord(updatedItem);
      await menuApi.publishRecord(item.menu_id);
    },
    [item, menuApi]
  );
  return (
    <>
      <CmsPage title={pageTitle} requestPending={requestPending}>
        {isDefaultSubtenant && (
          <>
            <div>
              {item && (
                <label>
                  Select Orientation
                  <select defaultValue={item.orientation} onChange={orientHandler}>
                    <option value="horizontal">Top/Horizontal</option>
                    <option value="vertical">Side/Vertical</option>
                  </select>
                </label>
              )}
            </div>
            <CmsSubItemListSection
              parentItemId={menuId}
              parentPageTitle="Menus"
              className="nav-manager"
              toolbarActions={toolbarActions}
              itemActions={itemActions}
              reorder={reorderItemsWrapper}
              emptyListMessage={emptyListMessage}
              columns={MenuItemColumns}
              subItemApi={menuItemApi}
              pageConstants={MenuEditorConstants}
              alert={alert}
              priorityFilter={(i) => i.isHomePage}
              showBreadCrumb={false}
            />
          </>
        )}
        {!isDefaultSubtenant && (
          <div className="alert alert-info">
            To edit the NavBar, the default subtenant must be selected. To add items to the
            subtenant menu on the NavBar, select &ldquo;Menus&rdquo; from the menu on the left, and
            edit the Menu that shares a name with the subtenant.
          </div>
        )}
      </CmsPage>
    </>
  );
};

export default MenuEditorPage;
