import React, { useEffect, useState } from 'react';
import FormInput from '../../shared/form/components/FormInput';
import SelectSwitch from '../../shared/form/components/SelectSwitch';
import FormSwitchControlled from '../../shared/form/components/FormSwitchControlled';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';

const options = [
  {
    label: 'Grid View',
    value: 'grid',
  },
  {
    label: 'List View',
    value: 'list',
  },
];

const list = [
  {
    label: 'List View',
    value: 'list',
  },
];

const viewType = [
  {
    label: 'Standard',
    value: 'standard',
  },
  {
    label: 'Home Screen',
    value: 'home',
  },
  {
    label: 'Agenda',
    value: 'agenda',
  },
];

const presets = {
  home: {
    defaultView: 'grid',
    useImg: true,
    hidden: ['headerText', 'filterLive', 'filterPast', 'filterDate'],
    disabled: [
      'lockView',
      'lockImg',
      'search',
      'pastEvents',
      'dateFilter',
      'category',
      'liveEvent',
      'favs',
      'viewSelector',
    ],
    collectionTitle: 'Title',
    displayToolbar: false,
    toolSearch: true,
    toolFavorite: false,
    toolViewSelect: false,
    filterLive: false,
    filterCategory: false,
    filterDate: false,
    filterPast: false,
    titleDisplay: false,
  },
  standard: {
    defaultView: 'grid',
    useImg: true,
    hidden: ['headerText', 'filterLive', 'filterPast', 'filterDate'],
    disabled: ['pastEvents', 'dateFilter', 'liveEvent'],
    collectionTitle: 'Title',
    displayToolbar: false,
    toolSearch: false,
    toolFavorite: false,
    toolViewSelect: false,
    filterLive: false,
    filterCategory: false,
    filterDate: false,
    filterPast: false,
    titleDisplay: false,
  },
  agenda: {
    defaultView: 'list',
    useImg: true,
    hidden: [],
    disabled: [],
    collectionTitle: 'Title',
    displayToolbar: true,
    toolSearch: true,
    toolFavorite: false,
    toolViewSelect: true,
    filterLive: true,
    filterCategory: false,
    filterDate: true,
    filterPast: true,
    titleDisplay: false,
  },
};

const CollectionEditorForm = (props) => {
  const { form, actions, collection } = props;
  const { setValue, watch } = form;
  const [useImg, setUseImg] = useState(collection.useImage || false);
  const [titleDisplay, setTitleDisplay] = useState(collection.collectionTitleDisplay || false);

  const [headerTextHidden, setHeaderTextHidden] = useState(false);
  //COMMENTED LEFT IN CODE IN CASE LOGIC NEEDS CHANGING
  // const [filterLiveHidden, setFilterLiveHidden] = useState(false);
  // const [filterPastHidden, setFilterPastHidden] = useState(false);
  // const [filterDateHidden, setFilterDateHidden] = useState(false);
  const [displayToolbar, setDisplayToolbar] = useState(collection.displayToolbar || false);
  const [toolSearch, setToolSearch] = useState(collection.toolSearch || false);
  const [toolFavorite, setToolFavorite] = useState(collection.toolFavorite || false);
  const [toolViewSelect, setToolViewSelect] = useState(collection.toolViewSelect || false);
  const [filterLive, setFilterLive] = useState(collection.filterLive || false);
  const [filterCategory, setFilterCategory] = useState(collection.filterCategory || false);
  const [filterDate, setFilterDate] = useState(collection.filterDate || false);
  const [filterPast, setFilterPast] = useState(collection.filterPast || false);

  const [currentType, setCurrentType] = useState(collection.type || 'standard');
  const [disabledImg, setDisabledImg] = useState(false);
  const [disabledView, setDisabledView] = useState(false);
  const [disabledToolBar, setDisabledToolBar] = useState(false);
  const [disabledSearch, setDisabledSearch] = useState(false);
  const [disabledPastEvents, setDisabledPastEvents] = useState(false);
  const [disabledDateFilter, setDisabledDateFilter] = useState(false);
  const [disabledCategoryFilter, setDisabledCategoryFilter] = useState(false);
  const [disabledLiveEventFilter, setDisabledLiveEventFilter] = useState(false);
  const [disabledFavs, setDisabledFavs] = useState(false);
  const [disabledViewSelector, setDisabledViewSelector] = useState(false);

  const watchType = watch('type');
  const currentUseImage = watch('useImage');

  useEffect(() => {
    if (!collection.name) {
      setValue('type', currentType);
      presetChanged(currentType);
    }
  }, []);

  useEffect(() => {
    if (watchType == 'standard' || ('agenda' && currentUseImage === false)) {
      setValue('toolViewSelect', false, { shouldTouch: true });
      setToolViewSelect(false);
    }
  }, [currentUseImage]);

  const handler = (value) =>
    ({
      useImage: (e) => setUseImg(e),
      displayToolbar: (e) => setDisplayToolbar(e),
      toolSearch: (e) => setToolSearch(e),
      toolFavorite: (e) => setToolFavorite(e),
      toolViewSelect: (e) => setToolViewSelect(e),
      filterLive: (e) => setFilterLive(e),
      filterCategory: (e) => setFilterCategory(e),
      filterDate: (e) => setFilterDate(e),
      filterPast: (e) => setFilterPast(e),
      collectionTitleDisplay: (e) => setTitleDisplay(e),
    }[value] || (() => {}));

  const presetChanged = (e) => {
    handler('type')(e);
    const preset = presets[e];
    setUseImg(preset.useImg);
    setValue('useImage', preset.useImg, { shouldTouch: true });
    setTitleDisplay(preset.titleDisplay);
    setValue('collectionTitleDisplay', preset.titleDisplay, { shouldTouch: true });
    setValue('defaultView', preset.defaultView, { shouldTouch: true });
    setValue('label', preset.collectionTitle, { shouldTouch: true });
    setHeaderTextHidden(preset.hidden.includes('headerText'));
    setCurrentType(e);
    //COMMENTED LEFT IN CODE IN CASE LOGIC NEEDS CHANGING
    // setFilterLiveHidden(preset.hidden.includes('filterLive'));
    // setFilterPastHidden(preset.hidden.includes('filterPast'));
    // setFilterDateHidden(preset.hidden.includes('filterDate'));

    setDisabledImg(preset.disabled.includes('lockImg'));
    setDisabledView(preset.disabled.includes('lockView'));
    setDisabledToolBar(preset.disabled.includes('toolBar'));
    setDisabledSearch(preset.disabled.includes('search'));
    setDisabledPastEvents(preset.disabled.includes('pastEvents'));
    setDisabledDateFilter(preset.disabled.includes('dateFilter'));
    setDisabledCategoryFilter(preset.disabled.includes('category'));
    setDisabledLiveEventFilter(preset.disabled.includes('liveEvent'));
    setDisabledFavs(preset.disabled.includes('favs'));
    setDisabledViewSelector(preset.disabled.includes('viewSelector'));

    setValue('displayToolbar', preset.displayToolbar, { shouldTouch: true });
    setDisplayToolbar(preset.displayToolbar);
    setValue('toolSearch', preset.toolSearch, { shouldTouch: true });
    setToolSearch(preset.toolSearch);
    setValue('toolFavorite', preset.toolFavorite, { shouldTouch: true });
    setToolFavorite(preset.toolFavorite);
    setValue('toolViewSelect', preset.toolViewSelect, { shouldTouch: true });
    setToolViewSelect(preset.toolViewSelect);
    setValue('filterLive', preset.filterLive, { shouldTouch: true });
    setFilterLive(preset.filterLive);
    setValue('filterCategory', preset.filterCategory, { shouldTouch: true });
    setFilterCategory(preset.filterCategory);
    setValue('filterDate', preset.filterDate, { shouldTouch: true });
    setFilterDate(preset.filterDate);
    setValue('filterPast', preset.filterPast, { shouldTouch: true });
    setFilterPast(preset.filterPast);
  };

  return (
    <>
      <div className="row">
        <form role="form" className="no-left-padding">
          <div className="col col-sm-12">
            <div className="col col-sm-4">
              <FormInput
                fieldWidth="long"
                label="Name"
                fieldName="name"
                formContext={form}
                formFieldOptions={{
                  required: 'This field is required.',
                }}
              />
            </div>

            <div className="col col-sm-2">
              <SelectSwitch
                label="Type"
                options={viewType}
                fieldWidth="tiny"
                formContext={form}
                fieldName="type"
                updateView={presetChanged}
                formFieldOptions={{
                  required: 'This field is required.',
                }}
              />
            </div>
          </div>

          <div className="col col-sm-12 collection-settings-spacer">
            <p className="collection-settings-spacer-text">Display Settings</p>
          </div>

          <div className="col col-sm-12">
            <div
              className="col-sm-12 no-padding-left"
              style={{
                display: 'flex',
                alignItems: 'center',
                minHeight: 62,
              }}
            >
              <div style={{ display: 'inline-block', marginRight: 15 }}>
                <FormSwitchControlled
                  checked={titleDisplay}
                  switchLabel="Collection Title"
                  fontWeight={300}
                  fieldName="collectionTitleDisplay"
                  formContext={form}
                  updateParent={handler('collectionTitleDisplay')}
                />
              </div>

              <div className="col col-sm-3" style={{ display: 'inline-block' }}>
                <FormInput
                  fieldWidth="long"
                  label="Collection Display Screen Title"
                  fontWeight={300}
                  fieldName="label"
                  formContext={form}
                  disabled={!titleDisplay}
                  formFieldOptions={{
                    required: titleDisplay ? 'This field is required.' : false,
                  }}
                />
              </div>
            </div>

            <div className="col col-sm-12">
              <FormSwitchControlled
                checked={useImg}
                switchLabel="Use Images in Collection"
                fieldName="useImage"
                formContext={form}
                disabled={disabledImg}
                updateParent={handler('useImage')}
              />
            </div>

            <div className="col col-sm-2">
              <SelectSwitch
                label="Default View"
                fontWeight={300}
                options={useImg ? options : list}
                fieldWidth="tiny"
                formContext={form}
                fieldName="defaultView"
                disabled={disabledView || !useImg}
                updateView={handler('defaultView')}
              />
            </div>

            {!headerTextHidden && (
              <div className="col col-sm-4">
                <FormInput
                  fieldWidth="long"
                  label="Header Text for Untimed Elements"
                  fontWeight={300}
                  fieldName="untimedHeader"
                  formContext={form}
                  formFieldOptions={{
                    required: 'This field is required.',
                  }}
                />
              </div>
            )}
          </div>

          <div className="col col-sm-12 collection-settings-spacer">
            <p className="collection-settings-spacer-text">Toolbar Settings</p>
          </div>

          <div className="col col-sm-12">
            <div className="col col-sm-4 margin-bottom">
              <FormSwitchControlled
                checked={displayToolbar}
                switchLabel="Display Toolbar in Collection"
                fieldName="displayToolbar"
                formContext={form}
                disabled={disabledToolBar}
                updateParent={handler('displayToolbar')}
              />
            </div>

            <div className="margin-left-20">
              <div className="col col-sm-4">
                <FormSwitchControlled
                  checked={toolSearch}
                  switchLabel="Search"
                  fontWeight={300}
                  fieldName="toolSearch"
                  formContext={form}
                  disabled={disabledSearch}
                  updateParent={handler('toolSearch')}
                />
              </div>

              <div className="col col-sm-4">
                <FormSwitchControlled
                  checked={filterPast}
                  switchLabel="Past Event Filter"
                  fontWeight={300}
                  fieldName="filterPast"
                  formContext={form}
                  disabled={disabledPastEvents}
                  updateParent={handler('filterPast')}
                />
              </div>

              <div className="col col-sm-4">
                <FormSwitchControlled
                  checked={filterDate}
                  switchLabel="Date Filter"
                  fontWeight={300}
                  fieldName="filterDate"
                  formContext={form}
                  disabled={disabledDateFilter}
                  updateParent={handler('filterDate')}
                />
              </div>

              <div className="col col-sm-4">
                <FormSwitchControlled
                  checked={filterCategory}
                  switchLabel="Category Filter"
                  fontWeight={300}
                  fieldName="filterCategory"
                  formContext={form}
                  disabled={disabledCategoryFilter}
                  updateParent={handler('filterCategory')}
                />
              </div>

              <div className="col col-sm-4">
                <FormSwitchControlled
                  checked={filterLive}
                  switchLabel="Live Event Filter"
                  fontWeight={300}
                  fieldName="filterLive"
                  formContext={form}
                  disabled={disabledLiveEventFilter}
                  updateParent={handler('filterLive')}
                />
              </div>

              <div className="col col-sm-4">
                <FormSwitchControlled
                  checked={toolFavorite}
                  switchLabel="Favorites"
                  fontWeight={300}
                  fieldName="toolFavorite"
                  formContext={form}
                  disabled={disabledFavs}
                  updateParent={handler('toolFavorite')}
                />
              </div>

              <div className="col col-sm-4">
                <FormSwitchControlled
                  checked={toolViewSelect}
                  switchLabel="View Selector"
                  fontWeight={300}
                  fieldName="toolViewSelect"
                  formContext={form}
                  disabled={disabledViewSelector || !useImg}
                  updateParent={handler('toolViewSelect')}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="row module-footer no-margin-left top-margin-15">
        <CmsPageSectionFooter actions={actions} />
      </div>
    </>
  );
};

export default CollectionEditorForm;
