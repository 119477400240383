(function () {
  angular.module('edit-group-members', ['smart-table']).controller('EditGroupMembersCtrl', [
    '$scope',
    '$modal',
    '$log',
    '$state',
    '$location',
    '$timeout',
    'growl',
    'CMS_INSTANCES',
    'ASSET_FILTER_OPTIONS',
    'groupApiService',
    'userApiService',
    'confirmDialog',
    'configService',
    function (
      $scope,
      $modal,
      $log,
      $state,
      $location,
      $timeout,
      growl,
      CMS_INSTANCES,
      ASSET_FILTER_OPTIONS,
      groupApiService,
      userApiService,
      confirmDialog,
      configService
    ) {
      var urlParams = $location.search();
      $scope.groupName = urlParams.name;
      $scope.isLoading = true;
      $scope.modifiedUsers = [];
      $scope.showGroup = 'Show Members';

      function getLogic(name, id, inGroup) {
        let condition = inGroup ? '=' : '!=';
        return {
          operator: 'AND',
          rules: [
            {
              field: 'Group',
              data: [
                {
                  label: name,
                  id: id,
                  logicParams: {
                    condition,
                    fieldLogicType: 'memberStatus',
                  },
                  hasSetDefaultValue: true,
                },
              ],
              condition,
            },
          ],
        };
      }

      function getUsers(tableState) {
        var pagination = tableState.pagination;
        var start = pagination.start || 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
        var number = pagination.number || 10; // Number of entries showed per page.
        if ($scope.showGroup === 'Show Members') {
          let logic = getLogic(urlParams.name, urlParams.id, true);
          userApiService
            .getUsersFromLogic(logic, start, number, tableState, $scope.limitToPushEndpoints)
            .success(function (data) {
              $scope.allUsers = data.appUsers;
              $scope.checkedUsers = $scope.allUsers.map((i) => i.id);
              $scope.displayedUsers = [].concat($scope.allUsers);

              setNumberOfPages(data.count, start);

              $scope.isLoading = false;
            })
            .error(function (data, status, headers, config) {
              growl.error('Failed to load users.');
              $log.debug('Failed to get users from logic. Response: ', data);
            });
        } else {
          let logic = getLogic(urlParams.name, urlParams.id, false);
          userApiService
            .getUsersFromLogic(logic, start, number, tableState, $scope.limitToPushEndpoints)
            .success(function (data) {
              $scope.allUsers = data.appUsers;
              $scope.checkedUsers = [];
              $scope.displayedUsers = [].concat($scope.allUsers);

              setNumberOfPages(data.count, start);

              $scope.isLoading = false;
            })
            .error(function (data, status, headers, config) {
              growl.error('Failed to load users.');
              $log.debug('Failed to get users from logic. Response: ', data);
            });
        }
      }

      function setNumberOfPages(count, start) {
        $scope.userCount = count;

        var numberOfPages = (count - (count % 10)) / 10 + 1;
        if ($scope.tableState) {
          $scope.tableState.pagination.numberOfPages = numberOfPages || 1; //set the number of pages so the pagination can update
        }

        $timeout(function () {
          $scope.tableState.pagination.start = start <= count ? start : 0;
        });
      }

      $scope.callServer = function (tableState) {
        $scope.isLoading = true;
        $timeout(function () {
          $scope.allUsers = [];
          $scope.displayedGroups = [];
        });

        $scope.tableState = tableState;
        getUsers(tableState);
      };

      $scope.addOrRemoveMember = function (id) {
        let index = $scope.checkedUsers.indexOf(id);
        let action;

        if (index > -1) {
          // remove from checkedUsers
          $scope.checkedUsers.splice(index, 1);
          action = 'remove';
        } else {
          // add to checkedUsers
          $scope.checkedUsers.push(id);
          action = 'add';
        }

        groupApiService
          .updateAppUserGroups(urlParams.id, urlParams.key, id, action)
          .success(function (data, status, headers, config) {
            if (data.status === 'error') {
              growl.error(data.error);
            }
          })
          .error(function (err, status, headers, config) {
            growl.error(err.error || 'User upload failed.  Please try again later.');
          });
      };

      $scope.cancel = function () {
        $location.path('/main/manage-groups').search({});
      };

      $scope.updateList = function () {
        $scope.callServer($scope.tableState);
      };

      function getFileName() {
        let now = new Date();
        let datetime = `${now.getFullYear()}_${
          now.getMonth() + 1
        }_${now.getDate()}__${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}`;
        return `${$scope.groupName}-${datetime}.csv`;
      }

      $scope.exportClicked = function () {
        confirmDialog({
          title: 'Confirmation',
          body: `You are about to export this group. Do you want to continue`,
          confirmText: 'Export',
          cancelText: 'Cancel',
        }).result.then(function (result) {
          $scope.buildExportCsv();
        });
      };

      $scope.buildExportCsv = function () {
        //check for custom fields
        groupApiService.getUsersFromGroup(urlParams.id).then(function (result) {
          const customFieldHeaders = $scope.customFieldHeaders || [];
          var csv = '';
          var groupUsers = [].concat(result.data.data);
          var headers = ['First Name', 'Last Name', 'Email'].concat(customFieldHeaders);
          csv += headers.join(',') + '\n';

          groupUsers.forEach((user) => {
            var row = [
              user.firstName,
              user.lastName,
              user.email,
              ...customFieldHeaders.map(
                (field) => (user.customFields && user.customFields[field.toLowerCase()]) || ''
              ),
            ];

            csv += row.join(',') + '\n';
          });

          var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
          var csvUrl = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = csvUrl;
          a.download = getFileName();
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(csvUrl);
        });
      };
    },
  ]);
})();
