import produce from 'immer';
import get from 'lodash/get';

const createReducer = (reducers) => (originalState, action) =>
  produce(originalState, (draftState) => {
    const callback = get(reducers, action.type);

    if (callback && typeof callback === 'function') {
      callback(draftState, action.payload);
    }
  });

export default createReducer;
