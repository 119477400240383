import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

const GrowlItem = (props) => {
  const { growl, onDismiss } = props;
  const { type, canDismiss, message, expiration } = growl;
  const [remainingSeconds, setRemainingSeconds] = useState(undefined);
  useEffect(() => {
    if (growl.expiration) {
      setRemainingSeconds(Math.ceil((growl.expiration - Date.now()) / 1000));
      const interval = setInterval(() => {
        setRemainingSeconds(Math.ceil((growl.expiration - Date.now()) / 1000));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [growl]);
  const alertStyle = type === 'error' ? 'alert-danger' : `alert-${type}`;
  const showClose = canDismiss !== false;
  return (
    <div
      className={classNames('growl-item alert', alertStyle, {
        'alert-dismissable': showClose,
      })}
    >
      {showClose && (
        <button type="button" className="close" onClick={onDismiss}>
          ×
        </button>
      )}
      {expiration && (
        <button type="button" className="close" onClick={onDismiss}>
          {remainingSeconds}
        </button>
      )}
      <div className="growl-message">{message}</div>
    </div>
  );
};

export default GrowlItem;
