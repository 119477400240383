import React, { useContext } from 'react';
import ItemTable from '../../itemTable/components/ItemTable';
import { useListLoaderParams } from '../../cms/hooks/useListLoaderParams';
import { useListLoader } from '../../cms/hooks/useListLoader';
import CmsPageSection from './CmsPageSection';
import { CmsPageContext } from '../definitions';
import { useSpinner } from '../../spinner/hooks/useSpinner';
import GrowlContainer from '../../growl/components/GrowlContainer';
import useWrapPageActions from '../hooks/useWrapPageActions';
import useLoadListItems from '../hooks/useLoadListItems';
import CmsListSectionToolbar from './CmsListSectionToolbar';

const CmsItemListSection = (props) => {
  const {
    pageSize,
    pageId,
    columns,
    toolbarActions,
    itemActions,
    itemApi,
    className,
    backPageAction,
    showSearchBar,
  } = props;
  const { spinnerProvider } = useContext(CmsPageContext);
  const { loadRecords } = itemApi;
  const { loadItems, loading, records, pagination, sort } = useListLoader(loadRecords);
  useSpinner(spinnerProvider, loading);
  const { loaderParams, onSearchChange, onPageSelected, onSortChange } = useListLoaderParams(
    pageId,
    pageSize,
    columns
  );
  const reload = useLoadListItems(pageId, loadItems, loaderParams);
  const wrappedToolbarActions = useWrapPageActions(pageId, toolbarActions, reload, loaderParams);
  const wrappedItemActions = useWrapPageActions(pageId, itemActions, reload, loaderParams);
  return (
    <>
      <CmsPageSection className={className}>
        <div className="row">
          <CmsListSectionToolbar
            actions={wrappedToolbarActions}
            searchExpr={loaderParams.search}
            onSearchChange={onSearchChange}
            backPageAction={backPageAction}
            showSearchBar={showSearchBar}
          />
        </div>
        <GrowlContainer groupId={pageId} />
        <div className="row">
          <ItemTable
            columns={columns}
            items={records}
            itemActions={wrappedItemActions}
            pagination={pagination}
            sort={sort}
            onPageSelected={onPageSelected}
            onSortChange={onSortChange}
          />
        </div>
      </CmsPageSection>
    </>
  );
};

export default CmsItemListSection;
