import axios from 'axios';
const baseUrl = '/plugins/quizzes/api';
export const updateQuizOptions = async (id, quizOptions) => {
  try {
    await axios.post(`${baseUrl}/updateQuizOptions`, { ...quizOptions, id, timeout: 10000 });
  } catch (e) {
    console.error(e);
    throw new Error('Failed to remove quiz.');
  }
};

const loadRecordsWrapper =
  (includeArchived = false) =>
  async (params) => {
    const {
      sort: { sortField, order },
      ...otherParams
    } = params;
    let result;

    try {
      const {
        data: { quizzes, count, offset },
      } = await axios.get(`${baseUrl}/getQuizzes`, {
        params: {
          ...otherParams,
          includeArchived,
          sortField,
          sortReverse: sortField ? order === 'DESC' : undefined,
        },
        timeout: 10000,
      });
      result = {
        records: quizzes,
        numRecords: count,
        offset,
      };
    } catch (e) {
      console.error(e);
      throw new Error('Failed to load quizzes');
    }

    return result;
  };

const generateQuizzesApi = (includeArchived) => {
  const quizzesApi = {
    loadRecords: loadRecordsWrapper(includeArchived),
  };
  return quizzesApi;
};

export const saveInstance = async (quizId, name) => {
  try {
    const {
      data: { quizInstance },
    } = await axios.post(`${baseUrl}/saveInstance`, {
      params: {
        quizId,
        name,
      },
      timeout: 10000,
    });
    return quizInstance;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to load quiz.');
  }
};
export const getCmsQuizUrl = async (id, duplicate) => {
  try {
    const {
      data: { url },
    } = await axios.post(`${baseUrl}/getCmsQuizUrl`, {
      id,
      duplicate,
    });
    return url;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to get cms quiz url');
  }
};
export default generateQuizzesApi;
