import axios from 'axios';
import { EMPTY_MENU_ITEM_REVISION } from '../definitions';
const baseUrl = '/plugins/menu-manager/api';

const loadRecords = async (menuId) => {
  let result = [];

  try {
    if (menuId) {
      const { data } = await axios.get(`${baseUrl}/getMenuItems`, {
        params: {
          menuId,
        },
        timeout: 10000,
      });
      const { menuItems } = data;
      result = menuItems;
    }
  } catch (e) {
    console.error(e);
    throw new Error('Failed to load menu items');
  }

  return result;
};

const loadRecord = async (id, parentItemId) => {
  if (id === 'new') {
    return { ...EMPTY_MENU_ITEM_REVISION, menu_id: parentItemId };
  } else {
    try {
      const { data } = await axios.get(`${baseUrl}/getMenuItem`, {
        params: {
          id,
        },
        timeout: 10000,
      });
      return { ...data.menuItem, menu_id: parentItemId };
    } catch (e) {
      console.error(e);
      throw new Error('Failed to load menu item.');
    }
  }
};

const saveRecord = async (item) => {
  try {
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    if (!item.image_asset_id) {
      item.image_asset_id = 'N/A';
    }

    const { data } = await axios.post(
      `${baseUrl}/saveMenuItem`,
      { ...item, id: item.menu_item_id },
      {
        timeout: 10000,
      }
    );
    return data.menu;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to save menu item.');
  }
};

const removeRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(
      `${baseUrl}/removeMenuItem`,
      {
        id,
      },
      {
        timeout: 10000,
      }
    );
  } catch (e) {
    console.error(e);
    throw new Error('Failed to remove menu item.');
  }
};

const restoreRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(
      `${baseUrl}/restoreMenuItem`,
      {
        id,
      },
      {
        timeout: 10000,
      }
    );
  } catch (e) {
    console.error(e);
    throw new Error('Failed to restore menu.');
  }
};

const publishRecords = async (itemIds) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/publishMenuItems`,
      {
        itemIds,
      },
      {
        timeout: 10000,
      }
    );
    const { menuItems } = data;
    return menuItems;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to publish menu items.');
  }
};

const loadMenuIcons = async () => {
  try {
    const { data } = await axios.get(`${baseUrl}/getMenuIcons`, {
      timeout: 10000,
    });
    const { iconAssets } = data;
    return iconAssets;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to get menu icons.');
  }
};

const menuItemApi = {
  loadRecords,
  loadRecord,
  saveRecord,
  removeRecord,
  restoreRecord,
  publishRecords,
  loadMenuIcons,
};
export default menuItemApi;
