import React, { useCallback, useContext, useMemo } from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsPageSection from '../../shared/cmsPage/components/CmsPageSection';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { EMPTY_PURGE_USER_DATA_REQUEST } from '../definitions';
import FormInput from '../../shared/form/components/FormInput';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import { CmsContext } from '../../context/definitions';
import GrowlContainer from '../../shared/growl/components/GrowlContainer';
import { privacyApi } from '../api/privacyApi'; // eslint-disable-next-line @typescript-eslint/no-empty-interface

const PrivacyPage = (_props) => {
  const form = useCmsForm(EMPTY_PURGE_USER_DATA_REQUEST);
  const { formState, handleSubmit } = form;
  const { growlProvider, confirm } = useContext(CmsContext);
  const { success, error } = growlProvider;
  const { purgeUserData } = privacyApi;
  const submitPurgeRequest = useCallback(
    async (requestData) => {
      const result = await confirm({
        title: 'Purge User Data',
        message:
          'Are you sure you want to purge data for this user?  All information associated with this user will be either deleted or anonymized.  This operation cannot be undone.',
      });

      if (result) {
        try {
          const appUserId = await purgeUserData(requestData);
          success('purge-user', {
            message: `User data has been successfully purged.  User id is ${appUserId}.`,
          });
        } catch (e) {
          error(
            'purge-user',
            'Failed to purge user data.  Please double check the email address and try again.'
          );
        }
      }
    },
    [confirm, success, error, purgeUserData]
  );
  const actions = useMemo(() => {
    return [
      {
        label: 'Purge',
        onClick: handleSubmit(submitPurgeRequest),
        disabled: !formState.isValid,
      },
    ];
  }, [formState, handleSubmit]);
  return (
    <CmsPage title="Privacy">
      <CmsPageSection title="Purge Deleted User">
        <GrowlContainer groupId="purge-user" />
        <div className="row">
          <form role="form" className="no-left-padding">
            <FormInput
              label="Email"
              fieldName="email"
              formContext={form}
              formFieldOptions={{
                required: 'This field is required.',
              }}
            />
          </form>
        </div>
        <div className="row module-footer">
          <CmsPageSectionFooter actions={actions} />
        </div>
      </CmsPageSection>
    </CmsPage>
  );
};

export default PrivacyPage;
