import { EMPTY_SORT } from './definitions';
export const findDefaultSort = (columns) => {
  let result = EMPTY_SORT;

  if (columns.length > 0) {
    const defaultColumn = columns.find((c) => !!c.initialSort);

    if (defaultColumn) {
      result = {
        sortField: defaultColumn.valueField,
        order: defaultColumn.initialSort,
      };
    }
  }

  return result;
};
export const getColumnSortOrder = (column, sort) => {
  const { valueField } = column;
  const { sortField, order } = sort;
  return valueField === sortField ? order : 'NONE';
};
