angular
  .module('import-module', [])
  .service('importService', [
    '$q',
    '$log',
    '$http',
    '$rootScope',
    'configService',
    '$modal',
    'Upload',
    function ($q, $log, $http, $rootScope, configService, $modal, Upload) {
      function showImportDialog(config) {
        var deferred = $q.defer();

        var modalScope = $rootScope.$new();
        var options = {
          templateUrl: 'shared/importModal.html',
          controller: 'ImportCtrl',
          backdrop: 'static',
          animation: false,
          scope: modalScope,
        };

        modalScope.title = config.title || 'Import Records';
        modalScope.confirmText = 'Import';
        modalScope.cancelText = 'Cancel';
        modalScope.importType = config.importType;
        modalScope.importParams = config.importParams;

        var modalInstance = $modal.open(options);
        modalInstance.result
          .then(function (result) {
            deferred.resolve(result);
          })
          .catch(function (err) {
            deferred.reject(err);
          });

        return deferred.promise;
      }

      function importRecords(file, importType, importParams, progressListener) {
        var deferred = $q.defer();

        progressListener = progressListener || function () {};

        var url = '/import-api/importRecords';

        var promise = Upload.upload({
          url: url,
          method: 'POST',
          data: {
            importData: Upload.rename(file, 'importFile.csv'),
            data: Upload.json({
              importType: importType,
              importParams: importParams,
            }),
          },
        });

        promise
          .progress(function (evt) {
            var progress = parseInt((100.0 * evt.loaded) / evt.total);
            $log.debug('progress: ' + progress + '%');

            progressListener(progress);
          })
          .success(function (data, status, headers, config) {
            $log.debug('Data was imported successfully from .');

            deferred.resolve({ success: true, importStatus: data.importStatus });
          })
          .error(function (err, status, headers, config) {
            $log.debug('error : status = ' + status, err);

            // TODO: Set error to specific, actionable error based on response from server
            deferred.resolve({ success: false, error: 'Failed to import data.' });
          });

        return deferred.promise;
      }

      function getImportStatus(importId) {
        var deferred = $q.defer();

        $http
          .get('/import-api/getImportStatus?importId=' + importId)
          .success(function (data, status, headers, config) {
            deferred.resolve(data.importStatus);
          })
          .error(function (err, status, headers, config) {
            deferred.reject(err);
          });

        return deferred.promise;
      }

      function getErrorLog(importId) {
        var deferred = $q.defer();

        $http
          .get('/import-api/exportImportErrors?importId=' + importId)
          .success(function (data, status, headers, config) {
            deferred.resolve(data.errorsUrl);
          })
          .error(function (err, status, headers, config) {
            deferred.reject(err);
          });

        return deferred.promise;
      }

      return {
        showImportDialog: showImportDialog,
        importRecords: importRecords,
        getImportStatus: getImportStatus,
        getErrorLog: getErrorLog,
      };
    },
  ])
  .controller('ImportCtrl', [
    '$scope',
    '$q',
    '$timeout',
    'importService',
    'growl',
    function ($scope, $q, $timeout, importService, growl) {
      $scope.state = {
        importing: false,
        finished: false,
      };

      $scope.doImport = function () {
        $scope.state.importing = true;
        $scope.cancelWaitForImport = false;

        const file = $scope.state.files[0];

        importService
          .importRecords(file, $scope.importType, $scope.importParams, progressHandler)
          .then(function (result) {
            if (result.success) {
              $scope.importId = result.importStatus.id;
              waitForImport(result.importStatus.id);
            } else {
              $scope.state.importing = false;
              growl.error(result.error, { ttl: -1 });
            }
          });
      };

      $scope.allowImport = function () {
        return $scope.state.files && !$scope.state.importing;
      };

      $scope.close = function () {
        const state = $scope.state;
        const { finished, status } = state;

        $scope.cancelWaitForImport = true;

        $scope.$close({ finished, status });
      };

      $scope.downloadErrors = function () {
        return importService
          .getErrorLog($scope.importId)
          .then(function (errorsUrl) {
            window.open(errorsUrl, '_self');
          })
          .catch(function (err) {
            console.log(err);
            growl.error('Failed to download error log.');
          });
      };

      function waitForImport(importId) {
        $timeout(function () {
          importService
            .getImportStatus(importId)
            .then(function (importStatus) {
              if (importStatus.status !== 'pending') {
                handleImportResult(importStatus);
              } else {
                if (!$scope.cancelWaitForImport) {
                  waitForImport(importId);
                }
              }
            })
            .catch(function (err) {
              console.log(err);
              return { id: importId, status: 'status_check_failed' };
            });
        }, 1000);
      }

      function handleImportResult(importStatus) {
        $scope.state.importing = false;
        $scope.state.finished = true;
        $scope.state.status = importStatus.status;

        switch (importStatus.status) {
          case 'completed':
            growl.success('Import was successful.', { ttl: -1 });
            break;
          case 'completed_with_errors':
            growl.warning(
              'Import was successful, however some rows were invalid and not imported.',
              { ttl: -1 }
            );
            $scope.hasErrors = true;
            break;
          default:
          case 'failed':
            growl.error('Import failed.', { ttl: -1 });
            $scope.hasErrors = true;
            break;
        }
      }

      function progressHandler(progress) {
        console.log('Progress: ' + progress);
      }
    },
  ]);
