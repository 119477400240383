import _ from 'lodash';
export function conditionToText(condition, opts = {}) {
  //console.log('conditionToText', condition, opts);
  const result = condition ? rulesToString(condition.operator, condition.rules || [], opts, 0) : '';
  return result ? result : 'All Users';
}

function rulesToString(operator, rules, opts, spacerCount = 0) {
  if (!rules.length) return '';
  const stringsRaw = rules.map(ruleToString(opts, spacerCount));
  if (rules.length === 1) return `Users ${stringsRaw[0]}`;
  const strings = stringsRaw.filter((r) => /\S/.test(r));
  const spacer = '  '.repeat(spacerCount);
  const result =
    `${spacer}Users who meet ${operator.toLowerCase() === 'and' ? 'all' : 'any'} conditions:
  ` +
    strings.join(`
  `);
  return result;
}

function ruleToString(opts, spacerCount = 0) {
  return (rule) => {
    if (rule.logic)
      return rulesToString(rule.logic.operator, rule.logic.rules, opts, spacerCount + 1);

    const logicType = _.get(rule, `data[0].logicParams.fieldLogicType`);

    const order = _.get(opts, `${rule.field}.fieldsMap.${logicType}.textOrder`);

    if (!order) return '';
    const stringComponents = order.map((component) => {
      let op, textOp, textOps, cond, conditions, field, label;

      switch (component) {
        case 'op':
          op = rule.condition;
          textOps = _.get(opts, `${rule.field}.fieldsMap.${logicType}.conditions`, []);
          textOp = textOps.find((textOp) => textOp.value === op);
          return textOp ? textOp.text : '';

        case 'createType':
          textOp = _.get(rule, `data[0].logicParams.createType.text`, '');
          return textOp;

        case 'data[0].logicParams.condition':
          cond = _.get(rule, `data[0].logicParams.condition`, _.get(rule, `condition`, ''));
          conditions = _.get(opts, `${rule.field}.fieldsMap.${logicType}.conditions`, []);
          textOp = _.get(
            conditions.find((condition) => condition.value === cond),
            'text',
            ''
          );
          return textOp;

        case 'data[0].label':
          field = 'data[0].label';
          label = _.get(rule, field, '');
          if (!label) return '';
          return `"${label}"`;

        case 'instanceLabel':
          field = 'data[0].data[0].label';
          label = _.get(rule, field, '');
          if (!label) return '';
          return `[${label}]`;

        default:
          return _.get(rule, component, component);
      }
    });
    const spacer = '  '.repeat(spacerCount);
    return `${spacer}- ` + stringComponents.join(' ');
  };
}
