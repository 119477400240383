import { useMemo } from 'react';
import useLoadSubListItems from '../../shared/cmsPage/hooks/useLoadSubListItems';
import useSubItemListActions from '../../shared/cmsPage/hooks/useSubItemListActions';
import usePublishActionHandler from '../../shared/cmsPage/hooks/usePublishActionHandler';
import menuApi from '../api/navbarApi';
import menuItemApi from '../api/navbarItemApi';
import { MenuConstants, MenuEditorConstants } from '../definitions';
export default function useMenuEditorActions(props) {
  const {
    hasItemsPendingPublish,
    loadItem,
    loadItems,
    menuId,
    pageTitle,
    publishDisabled,
    setHasReordered,
  } = props;
  const { remove } = useSubItemListActions(menuItemApi, menuId, MenuEditorConstants);
  const reload = useLoadSubListItems(MenuEditorConstants.defaultPageId, menuId, loadItems);
  const publishHandler = usePublishActionHandler(
    MenuConstants.pluginId,
    MenuConstants.typeName,
    MenuConstants.publishPageName,
    MenuEditorConstants.defaultPageId,
    MenuEditorConstants.defaultPageName,
    pageTitle,
    MenuEditorConstants.defaultPageId,
    menuApi
  );
  const removeOverride = useMemo(
    () => ({
      ...remove,
      disabled: (item, ...rest) => item.isHomePage || remove.disabled(item, ...rest),
    }),
    []
  );
  const publish = useMemo(
    () => ({
      label: 'Publish',
      disabled: publishDisabled,
      onClick: async () => {
        await publishHandler(menuId, hasItemsPendingPublish, async () => {
          setHasReordered(false);
          await Promise.all([loadItem(menuId), reload()]);
        });
      },
    }),
    [
      hasItemsPendingPublish,
      publishDisabled,
      publishHandler,
      reload,
      loadItem,
      menuId,
      setHasReordered,
    ]
  );
  return {
    publish,
    remove: removeOverride,
  };
}
