import { useCallback, useMemo } from 'react';

const useWrapPageActions = (pageId, actions, reload, loaderParams) => {
  const wrapHandler = useCallback(
    (handler) => {
      return (...args) => {
        handler(...args, {
          loaderParams,
          reload: () => reload(loaderParams),
        });
      };
    },
    [pageId, reload, loaderParams]
  );
  return useMemo(() => {
    return actions.map((a) => ({ ...a, onClick: a.onClick ? wrapHandler(a.onClick) : undefined }));
  }, [actions, wrapHandler]);
};

export default useWrapPageActions;
