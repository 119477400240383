import axios from 'axios';
import { EMPTY_MEET } from '../definitions';
const baseUrl = '/plugins/meets/api';

const loadRecord = async (id) => {
  if (id === 'new') {
    return EMPTY_MEET;
  } else {
    try {
      const { data } = await axios.get(`${baseUrl}/getMeet`, {
        params: {
          id,
        },
        timeout: 10000,
      });
      return data.meet;
    } catch (e) {
      console.error(e);
      throw new Error('Failed to load menu.');
    }
  }
};

const removeRecord = async (item) => {
  try {
    const { id } = item;
    await axios.post(`${baseUrl}/removeMeet`, {
      id,
      timeout: 10000,
    });
  } catch (e) {
    console.error(e);
    throw new Error('Failed to remove meet.');
  }
};

const loadRecordsWrapper = (meetType) => async (params) => {
  const {
    sort: { sortField, order },
    ...otherParams
  } = params;
  let result;

  try {
    const { data } = await axios.get(`${baseUrl}/getMeets`, {
      params: {
        ...otherParams,
        type: meetType,
        sortField,
        sortReverse: sortField ? order === 'DESC' : undefined,
      },
      timeout: 10000,
    });
    const { meets, count, offset } = data;
    result = {
      records: meets,
      numRecords: count,
      offset,
    };
  } catch (e) {
    console.error(e);
    throw new Error('Failed to load meets');
  }

  return result;
};

const saveRecordWrapper = (meetType) => async (item) => {
  try {
    const { data } = await axios.post(`${baseUrl}/saveMeet`, {
      ...item,
      type: meetType,
      timeout: 10000,
    });
    return data.meet;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to save meet.');
  }
};

const generateMeetsApi = (meetType) => {
  const meetsApi = {
    loadRecords: loadRecordsWrapper(meetType),
    loadRecord,
    saveRecord: saveRecordWrapper(meetType),
    removeRecord,
  };
  return meetsApi;
};

export const loadGroups = async () => {
  let result = [];

  try {
    const { data } = await axios.get(`/user-api/getAllGroups`, {
      timeout: 10000,
    });
    result = data.groups;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to load groups');
  }

  return result;
};
export const getConfigUrl = async (id) => {
  try {
    const { data } = await axios.post(`${baseUrl}/getMeetSettingsUrl`, {
      id,
    });
    return data.url;
  } catch (e) {
    console.error(e);
    throw new Error('Failed to get configure url');
  }
};
export default generateMeetsApi;
